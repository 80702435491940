import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_ORDER } from "../Queries/order";
import { GetPriceString } from "../../../GlobalFunctions/helper";

const UseAddEditorder = (selectedorderID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        paymentTypeID: 0,
        shippingTypeID: 0,
        couponID: 0,
        orderNumber: "",
        orderDate: "",
        paymentTypePrice:0,
        shippingTypePrice:0,
        email: "",
        tel: "",
        name: "",
        surname: "",
        street: "",
        city: "",
        zip: "",
        countryID: 0,
        deliverName: "",
        deliverySurname: "",
        deliveryStreet: "",
        deliveryCity: "",
        deliveryZip: "",
        countryID: 0,
        company: "",
        ic: "",
        dic: "",
        icdph: "",
        lang: "",
        paymentType:null,
        shippingType:null,
        couponAmount: 0,
        couponInPercentage: 0,
        couponDiscountPrice:0,
        couponCode:""
    });

    var paymentHeaderData = [
        {
            value: t("ItemName"),
            className:"flex-1"
        },
        {
            value: "",
            className:"static text-center"
        }
        
    ];

    var paymentContent = [];

    if(formData.couponID != 0)
    {
        paymentContent.push({
            data:[
                {
                    value: <>
                        <div>{"SLEVA " + formData.couponAmount + (formData.couponInPercentage == 1 ? "%" : "")}</div>
                        <div>({formData.couponCode})</div>
                    </>,
                    className:"flex-1"
                },
                {
                    value: GetPriceString(-formData.couponDiscountPrice,formData.lang),
                    className:"flex-1 text-right"
                }
            ],
        })
    }

    if(formData.shippingType)
    {
        paymentContent.push({
            data:[
                {
                    value: formData.shippingType.title,
                    className:"flex-1"
                },
                {
                    value:(formData ? (formData.shippingTypePrice == 0 ? "ZDARMA" : GetPriceString(formData.shippingTypePrice,formData.lang)) : ""),
                    className:"flex-1 text-right"
                }
            ],
        })
    }

    if(formData.paymentType)
    {
        paymentContent.push({
            data:[
                {
                    value: "Způsob platby",
                    className:"flex-1"
                },
                {
                    value:formData.paymentType.title,
                    className:"flex-1 text-right"
                }
            ],
        })
    }


    /*
    const [AddEditorderMutation,{error,loading}] = useMutation(ADD_EDIT_ORDER,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditorder);

            const notifyText = (selectedorderID != 0 ? t("SuccessfullyUpdated") : t("NeworderIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditorder = () => {

        if(formData.name != "")
        {
            if(formData.shortcut != "")
            {
                let langs = [];
                for(const lang of formData.langs)
                {
                    langs.push({
                        deliveryTimeToCustomer: lang.deliveryTimeToCustomer,
                        lang:                   lang.lang
                    });
                }
                AddEditorderMutation({
                    variables:{
                        orderID:     selectedorderID,
                        name:               formData.name,
                        shortcut:           formData.shortcut,
                        deliveryDays:       parseInt(formData.deliveryDays),
                        langs:              langs
                    }
                })
            }
            else
                AddNotification(t("ShurtcutIsImportant"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
        
    } */

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        paymentHeaderData,
        paymentContent,
        //error: error,
        //loading: loading,
        formData,
        //AddEditorder,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddEditorder;