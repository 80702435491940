import { useQuery } from "@apollo/client";
import { GET_PRODUCT_REVIEW } from "../../Queries/products";
import { useState } from "react";

const UseGetProductReview = (selectedProductReviewID,CallBack) => {

    const [product,SetProduct] = useState(null);

    const {loading,error} = useQuery(GET_PRODUCT_REVIEW,{
        variables:{
            productReviewID:selectedProductReviewID,
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            if(selectedProductReviewID != 0)
                CallBack({
                    approved:d.ProductReview.approved,
                    pinned:d.ProductReview.pinned,
                    description:d.ProductReview.description,
                    rating:d.ProductReview.rating,
                    cons:d.ProductReview.cons,
                    pros:d.ProductReview.pros,
                    photos:d.ProductReview.photos,
                    email:d.ProductReview.email,
                    name:d.ProductReview.name,
                    city:d.ProductReview.city
                });
            
            SetProduct(d.ProductReview.product);
        }
    });

    return{
        loading: loading,
        error: error,
        product
    }

}

export default UseGetProductReview;