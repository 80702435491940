import { useApolloClient, useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_PRODUCT_VARIANT } from "../../Queries/products";
import dayjs from "dayjs";
import { GET_COVER_PHOTOS } from "../../Queries/covers";

const UseGetProductVariant = (
    selectedProductVariantID,
    productID,
    lang,
    excludedProductCovers,
    CallBack
) => {

    const client = useApolloClient();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [productSides, SetAllProductSides] = useState([]);
    const [coverTitles, SetAllCoverTitles] = useState([]);
    const [allProductCovers, SetAllProductCovers] = useState([]);
    const [parameterColors, SetParameterColors] = useState([]);
    const [coverPhotos, SetCoverPhotos] = useState({
        firstCoverPhotos:[],
        secondCoverPhotos:[]
    });

    const {loading,error} = useQuery(GET_PRODUCT_VARIANT,{
        variables:{
            productVariantID:selectedProductVariantID,
            productID:productID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:async (d) => {
            
            const emptyLang = {
                name:"",    
                internalNote:"",                  
                description:"",
                commonPrice:0,
                price:0,
                discount:0,
                isDiscountPercentage:false
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllProductSides(d.AllProductSides);
            SetAllCoverTitles(d.AllCoverTitles);
            SetAllProductCovers(d.AllProductCovers);

            var colors = [];
            for(let val of d.ProductParametersForWeb)
            {
                if(val.parameter && val.parameterValue && val.parameter.isColor == 1)
                    colors.push({
                        parameterValueID: val.parameterValueID,
                        value: val.parameterValue.value,
                        colorCode: val.parameterValue.colorCode
                    })
            }

            SetParameterColors(colors);

            if(selectedProductVariantID != 0)
            { 
                let langs = CompleteLangsArray(d.ProductVariantWithLangs.langs,d.AllLanguageMutations,emptyLang);

                if(d.ProductVariantWithLangs.firstCoverID != 0)
                {
                    var coverName = "";
                    for(let val of d.AllProductCovers)
                    {
                        if(val.coverID == d.ProductVariantWithLangs.firstCoverID)
                            coverName = val.name;
                    }
                    await SelectCover("firstCoverPhotos",d.ProductVariantWithLangs.firstCoverID,coverName);
                }
                    
                
                if(d.ProductVariantWithLangs.secondCoverID != 0)
                {
                    var coverName = "";
                    for(let val of d.AllProductCovers)
                    {
                        if(val.coverID == d.ProductVariantWithLangs.secondCoverID)
                            coverName = val.name;
                    }
                    await SelectCover("secondCoverPhotos",d.ProductVariantWithLangs.secondCoverID,coverName);
                }
                    

                CallBack({
                    selectedLang:                    lang, 
                    productCode:                     d.ProductVariantWithLangs.productCode,
                    showOnEshop:                     d.ProductVariantWithLangs.showOnEshop,
                    photoForSide:                    d.ProductVariantWithLangs.photoForSide,
                    productSideID:                   d.ProductVariantWithLangs.productSideID,
                    quantityInStock:                 d.ProductVariantWithLangs.quantityInStock,
                    quantityAtManufacturer:          d.ProductVariantWithLangs.quantityAtManufacturer,
                    quantityInStockRightSide:        d.ProductVariantWithLangs.quantityInStockRightSide,
                    quantityAtManufacturerRightSide: d.ProductVariantWithLangs.quantityAtManufacturerRightSide,
                    presetCovers:                    d.ProductVariantWithLangs.presetCovers,
                    showFirstCover:                  d.ProductVariantWithLangs.showFirstCover,
                    showSecondCover:                 d.ProductVariantWithLangs.showSecondCover,
                    firstCoverTitleID:               d.ProductVariantWithLangs.firstCoverTitleID,
                    secondCoverTitleID:              d.ProductVariantWithLangs.secondCoverTitleID,
                    firstCoverID:                    d.ProductVariantWithLangs.firstCoverID,
                    secondCoverID:                   d.ProductVariantWithLangs.secondCoverID,
                    firstCoverPhotoID:               d.ProductVariantWithLangs.firstCoverPhotoID,
                    secondCoverPhotoID:              d.ProductVariantWithLangs.secondCoverPhotoID,
                    firstParameterValueID:           d.ProductVariantWithLangs.firstParameterValueID,
                    secondParameterValueID:          d.ProductVariantWithLangs.secondParameterValueID,
                    mainPhoto:                       d.ProductVariantWithLangs.photo,
                    langs:                           langs,
                    selectedLabels:                  GetSelectedLabels(d.ProductVariantWithLangs.labels),
                    selectedExcludedCovers:          GetExcludedCovers(d.ProductVariantWithLangs.excludedCovers,excludedProductCovers),
                });

            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }
        }
    });

    const GetExcludedCovers = (covers,excludedProductCovers) => {
        var newExcludedCovers = [...excludedProductCovers];
        for(let val of covers)
        {
            if(newExcludedCovers.indexOf(val.coverID) == -1)
                newExcludedCovers.push(val.coverID)
        }

        return newExcludedCovers;
    }

    const GetSelectedLabels = (labels) => {

        var newLabels = [];
        for(let val of labels)
        {
            newLabels.push({
                labelID: val.labelID,
                useDates: val.useDates,
                dateFrom: (val.dateFrom == "0000-00-00 00:00:00" ? "" : dayjs(val.dateFrom).format("YYYY-MM-DD")),
                dateTo: (val.dateTo == "0000-00-00 00:00:00" ? "" : dayjs(val.dateTo).format("YYYY-MM-DD")),
                name: val.label.name
            })
        }

        return newLabels;
    }

    const SelectCover = async (param,coverID,coverName) => {
        var cPhotos = await client.query({
            query:GET_COVER_PHOTOS,
            variables:{
                coverID
            }
        });

        var allCoverPhotos = [...cPhotos.data.AllCoverPhotos];

        for(let i in allCoverPhotos)
        {
            allCoverPhotos[i] = {...allCoverPhotos[i],number: coverName + " " + allCoverPhotos[i].number};
        }

        SetCoverPhotos((prevValue) => {
            return {...prevValue,[param]:allCoverPhotos};
        })

    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        productSides,
        coverTitles,
        allProductCovers,
        coverPhotos,
        parameterColors,
        SelectCover
    }

}

export default UseGetProductVariant;