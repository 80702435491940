import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const UseEditCategories = (
    selectedCategories,
    SetBooleanFormDataSelectedItemsWithObj,
    SetFormDataSelectedItemsWithObj,
    hideMainColumin
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedCategories)
        {
            const contentData = GetContentData(selectedCategories);
            SetContent(contentData);
        }

    },[selectedCategories])

    var headerData = [
        {
            value: t("CategoryName"),
            className:"flex-1"
        }
    ];

    if(!hideMainColumin)
        headerData.unshift({
            value: t("Main"),
            className:"static smaller"
        })
    

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            if(val.deleted == undefined || val.deleted == false)
            {
                var cData = {
                    data:[
                        {
                            value:val.name,
                            className:"flex-1"
                        }
                    ],
                }

                if(!hideMainColumin)
                    cData.data.unshift({
                        elm:"radio",
                        param:"isMain",
                        name:"isMain",
                        OnChange: (e) => {
                            SetBooleanFormDataSelectedItemsWithObj("isMain","selectedCategories","linkID",val.linkID);
                            SetFormDataSelectedItemsWithObj("updated","selectedCategories","linkID",val.linkID,true);
                        },
                        className:"static smaller"
                    })


                cData["isMain"]     = val.isMain;
                cData.rowID         = val.linkID;
                cData.selected      = false;

                contentData.push(cData)
            }
        }
        return contentData;
    }

    return{
        headerData,
        content
    }

}

export default UseEditCategories;