import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditTickLabel from "./AddEditTickLabel";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import UseDeleteTickLabels from "../../Library/TickLabels/UseDeleteTickLabels";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import UseGetAllTickLabels from "../../Library/TickLabels/UseGetAllTickLabels";

const TickLabels = () => {

    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedTickLabelID, SetSelectedTickLabelID] = useState(null);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedTickLabelIDs,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllTickLabels(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteTickLabels
    } = UseDeleteTickLabels(selectedTickLabelIDs,(d) => UpdateListAfterDelete(d));

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("TickLabelList")}
                        OnAddButtonClick={() => SetSelectedTickLabelID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        OnDragListRowEnd = {(result) => OnDragEnd(result)}
                        options = {{
                            OnEditClick: (tickLabelID) => SetSelectedTickLabelID(tickLabelID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedTickLabelID || selectedTickLabelID == 0 ?
                <AddEditTickLabel
                    selectedTickLabelID = {selectedTickLabelID}
                    SetSelectedTickLabelID = {SetSelectedTickLabelID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedTickLabelID,d);
                        SetSelectedTickLabelID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteTickLabels")} 
                    CallBack={DeleteTickLabels} 
                />
            :null}

        </>
    )
}

export default TickLabels;