import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_PRODUCT_VARIANT } from "../../Queries/products";
import { useRef } from "react";

const UseAddEditProductVariant = (selectedProductVariantID,productID,lang,OnCompleted) => {

    var continueToCovers = useRef(false);
    var stayHere  = useRef(false);

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenImage,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        SetFormDataSelectedItemsPriority
    } = UseFormHandle({
        selectedLang:lang,
        productSideID:1,
        showOnEshop:1,
        photoForSide:1,
        productCode:"",
        photo:null,
        quantityInStock:0,
        quantityAtManufacturer:0,
        quantityInStockRightSide:0,
        quantityAtManufacturerRightSide:0,
        presetCovers:1,
        showFirstCover:1,
        showSecondCover:1,
        firstCoverTitleID:0,
        secondCoverTitleID:0,
        firstCoverID:0,
        secondCoverID:0,
        firstCoverPhotoID:0,
        secondCoverPhotoID:0,
        firstParameterValueID:0,
        secondParameterValueID:0,
        selectedExcludedCovers:[],
        selectedLabels:[],
        langs:[],
       
    });

    const [AddEditProductVariantMutation,{error,loading}] = useMutation(ADD_EDIT_PRODUCT_VARIANT,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditProductVariant,continueToCovers.current,stayHere.current);

            const notifyText = (selectedProductVariantID != 0 ? t("SuccessfullyUpdated") : t("NewProductVariantIsInTheWorld"));

            AddNotification(notifyText,true);

            continueToCovers.current = false;
            stayHere.current = false;

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditProductVariant = (conToCovers,sHere) => {

        continueToCovers.current = conToCovers;
        stayHere.current = sHere;

        const quantityInStock =                    parseInt(formData.quantityInStock) || 0;
        const quantityAtManufacturer =             parseInt(formData.quantityAtManufacturer) || 0;
        const quantityInStockRightSide =           parseInt(formData.quantityInStockRightSide) || 0;
        const quantityAtManufacturerRightSide =    parseInt(formData.quantityAtManufacturerRightSide) || 0;
              
        if(formData.productCode != "")
        {
            if(
                (
                    formData.productSideID == 1 && 
                    quantityInStock >= 0 && 
                    quantityAtManufacturer >= 0 && 
                    quantityInStockRightSide >= 0 && 
                    quantityAtManufacturerRightSide >= 0
                ) || 
                (
                    formData.productSideID != 1 && 
                    quantityInStock >= 0 && 
                    quantityAtManufacturer >= 0
                )
            )
            {
                if(formData.productSideID)
                {
                    if(formData.photo && selectedProductVariantID == 0 || selectedProductVariantID != 0)
                    {
                        var coverError = CheckCovers();
                        if(!coverError)
                        {
                            let langs = [];
                            const selectedLabels = GetSelectedLabels(formData.selectedLabels);
                            
                            for(const lang of formData.langs)
                            {
                                langs.push({
                                    name:                   lang.name, 
                                    internalNote:           lang.internalNote,
                                    price:                  parseFloat(lang.price) || 0, 
                                    commonPrice:            parseFloat(lang.commonPrice) || 0,
                                    discount:               parseFloat(lang.discount) || 0,
                                    isDiscountPercentage:   lang.isDiscountPercentage ? 1 : 0,        
                                    lang:                   lang.lang
                                });
                            }

                            AddEditProductVariantMutation({
                                variables:{
                                    productVariantID:                   selectedProductVariantID,
                                    productID:                          productID,
                                    showOnEshop:                        parseInt(formData.showOnEshop),
                                    productCode:                        formData.productCode,
                                    productSideID:                      formData.productSideID,
                                    photoForSide:                       parseInt(formData.photoForSide),
                                    photo:                              formData.photo,
                                    quantityInStock:                    quantityInStock,
                                    quantityAtManufacturer:             quantityAtManufacturer,
                                    quantityInStockRightSide:           quantityInStockRightSide,
                                    quantityAtManufacturerRightSide:    quantityAtManufacturerRightSide,
                                    presetCovers:                       parseInt(formData.presetCovers),
                                    showFirstCover:                     parseInt(formData.showFirstCover),
                                    showSecondCover:                    parseInt(formData.showSecondCover),
                                    firstCoverTitleID:                  formData.firstCoverTitleID,
                                    secondCoverTitleID:                 formData.secondCoverTitleID,
                                    firstCoverID:                       formData.firstCoverID,
                                    secondCoverID:                      formData.secondCoverID,
                                    firstCoverPhotoID:                  formData.firstCoverPhotoID,
                                    secondCoverPhotoID:                 formData.secondCoverPhotoID,
                                    firstParameterValueID:              formData.firstParameterValueID,
                                    secondParameterValueID:             formData.secondParameterValueID,
                                    selectedLabels:                     selectedLabels,
                                    selectedExcludedCovers:             formData.selectedExcludedCovers,
                                    langs:                              langs,
                                }
                            })

                        }
                        else
                            AddNotification(coverError,false);
                    }
                    else
                        AddNotification(t("PleaseAddSomePhoto"),false);
                }
                else
                    AddNotification(t("SideIsAlsoImportant"),false);
            }
            else
                AddNotification(t("ItemsInStockHasWrongValues"),false);
        }
        else
            AddNotification(t("ProductCodeIsVeryImportant"),false); 
        
    } 
    
    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const CheckCovers = () => {

        if(formData.presetCovers != 3)
        {
            if(formData.showFirstCover)
            {
                if(formData.firstCoverTitleID == 0) 
                    return t("CoverTitleIsNecesaryAtFirstCover")

                if(formData.firstCoverID == 0 && formData.presetCovers == 1) 
                    return t("CoverIsNecesaryAtFirstCover")

                if(formData.firstCoverPhotoID == 0 && formData.presetCovers == 1) 
                    return t("CoverPhotoIsNecesaryAtFirstCover")

                if(formData.firstParameterValueID == 0 && formData.presetCovers == 1) 
                    return t("ColorIsNecesaryAtFirstCover")
            }

            if(formData.showSecondCover)
            {
                if(formData.secondCoverTitleID == 0)
                    return t("CoverTitleIsNecesaryAtSecondCover")

                if(formData.secondCoverID == 0 && formData.presetCovers == 1)
                    return t("CoverIsNecesaryAtSecondCover")

                if(formData.secondCoverPhotoID == 0 && formData.presetCovers == 1)
                    return t("CoverPhotoIsNecesaryAtSecondCover")

                if(formData.secondParameterValueID == 0 && formData.presetCovers == 1) 
                    return t("ColorIsNecesaryAtSecondCover")
            }
        }
    }

    const GetSelectedLabels = (selectedLabels) => {

        var labels = [];
        for(let val of selectedLabels)
        {
            labels.push({
                labelID:  val.labelID,
                useDates: val.useDates ? 1 : 0,
                dateFrom: val.dateFrom,
                dateTo:   val.dateTo
            }) 
        }

        return labels;
    }

    const GeneratePriceAfterDiscount = () => {
        for(let l of formData.langs)
        {
            if(l.lang == formData.selectedLang)
            {
                if(l.isDiscountPercentage)
                    return l.price - (Math.round(l.price / 100 * l.discount))
                else
                    return l.price - l.discount
            }
        }
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditProductVariant,
        SetFormData,
        SetFormLangData,
        InitFormData,
        OpenImage,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        GeneratePriceAfterDiscount,
        SetFormDataSelectedItemsPriority
    }

}

export default UseAddEditProductVariant;