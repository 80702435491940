import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins } from "../../Library/functions";
import articlesIcon from '../../../../Media/Images/Icons/articles.webp';

const NewestArticles = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);
    
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "newestArticles"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={margins.styleClassName +  " " + data.className} style={margins.styles}>
                
                <div className="cm-start-info big">
                    <img src={articlesIcon} alt="" />
                    Zde se budou nacházet nejnovější články
                </div>
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default NewestArticles;