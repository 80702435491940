import { UseNotification } from "../Modules/Notification/Library/UseNotification";
import { useMutation } from "@apollo/client";
import { ValidateImageMimeType, ValidateSize } from "../GlobalFunctions/images";
import { useTranslation } from "react-i18next";
import { MAX_IMAGE_FILE_SIZE_TO_UPLOAD } from "../Config";

const UseUploadPhotos = (query,paramID,paramValue,OnSuccess) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [UploadImgs,{loading}] = useMutation(query,{
        onCompleted:(d) => {

            if(OnSuccess)
                OnSuccess(d);
            
        }
    });

    const UploadPhotos = async (files) => {

        var checkSize = true;
        var checkImage = true;

        for(let val of files)
        {
            var isImg = await ValidateImageMimeType(val);

            if(!isImg)
                checkImage = false;

            if(!ValidateSize(val))
                checkSize = false;
        }

        if(checkSize)
        {
            if(checkImage)
            {
                var variables = {
                    files
                }

                if(paramID)
                    variables[paramID] = paramValue;

                UploadImgs({
                    variables:variables
                })
            }else
                AddNotification(t("SomeFileIsNotImage"),false);
        }else
            AddNotification(t("MaxAllowedImageFileSizeIs") + " " + (MAX_IMAGE_FILE_SIZE_TO_UPLOAD / 1000000) + "MB",false);
    }

    return{
        loading,
        UploadPhotos
    }
}

export default UseUploadPhotos;