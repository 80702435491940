import gql from 'graphql-tag';

export const ADD_EDIT_BANNER = gql`
    mutation AddEditBanner(
        $bannerID:ID,
        $bannerTypeID:ID,
        $forLang:String,
        $seconds:Int,
        $active:Int,
        $langs:[BannerLangsInput]
    ){
        AddEditBanner(
            bannerID:$bannerID,
            bannerTypeID:$bannerTypeID,
            forLang:$forLang,
            seconds:$seconds,
            active: $active,
            langs:$langs
        )
    }
`;

export const GET_BANNER = gql`
    query BannerWithLangs($bannerID: ID,$lang: String){
        BannerWithLangs(bannerID:$bannerID){
            bannerID
            bannerTypeID
            forLang
            seconds
            active
            langs{
                lang
                name
                image
                description
                price
                buttonName
                url
                discount
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllBannerTypes(lang:$lang){
            bannerTypeID
            name
        }
    }
`;

export const GET_BANNERS = gql`
    query AllBanners($lang: String){
        AllBanners(lang:$lang){
            bannerID
            bannerTypeID
            forLang
            seconds
            active
            name
            image
            description
            price
            lang
            bannerType{
                name
            }
        }
    }
`;

export const DELETE_BANNERS = gql`
    mutation DeleteBanners($bannerIDs:ID) {
        DeleteBanners(bannerIDs:$bannerIDs)
    }
`;