import { UseContentManager } from "../../Library/UseContentManager";
import Column from "./Column";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins,GetColClasses,GetColumnsPadding } from "../../Library/functions";

const Columns = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);
    var className = GetColumnsPadding(data);

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "columns"
                    index = {lastIndexes} 
                    showAddColumn = {true} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={margins.styleClassName} style={margins.styles}>
                <div className={"cm-row " + className + " " + data.className} >

                    {data.cols.length > 0 && data.cols.map((item,index) => {

                        var colClasses = GetColClasses(item);
                        var margins = GetElementMargins(item);
                        
                        return(
                            <div 
                                key={index} 
                                className={"cm-col " + colClasses + " " +  margins.styleClassName}
                                style={margins.styles}
                            >
                                
                                <Column 
                                    colsLength = {data.cols.length} 
                                    data = {item} 
                                    index = {index} 
                                    columnsCount = {data.cols.length}
                                    lastIndexes = {lastIndexes} 
                                />
                                    
                            </div>
                        )
                    })}

                    {showHelpers ?
                        <div 
                            className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                            onClick={(e) => SelectElement(e,lastIndexes)}
                        ></div>
                    :null}
                    
                </div>
            </div>
        </div>
    )
}

export default Columns;