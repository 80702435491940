import Input from "../../../GlobalComponents/Input";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import Loading from "../../../GlobalComponents/Loading";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import Error from "../../../GlobalComponents/Error";
import UseEditWebSettings from "../Library/WebSettings/UseEditWebSettings";
import UseGetWebSettings from "../Library/WebSettings/UseGetWebSettings";
import InputCard from "../../../GlobalComponents/InputCard";
import contactIcon from '../../../Media/Images/Icons/contact.webp';
import paragraphIcon from '../../../Media/Images/Icons/paragraph.webp';
import { UseAuth } from "../../Auth/Library/UseAuth";
import TinyMCEEditor from "../../TinyMCEEditor/Components/TinyMCEEditor";
import { TINY_MCE_TOOLBAR_BASIC } from "../../../Config";

const WebSettings = () => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const {
        formData,
        loading:editLoading,
        GetFormLangValue,
        InitFormData,
        SetFormLangData,
        SetFormData,
        EditWebSettings
    } = UseEditWebSettings(user.lang,() => {

    });

    const {allLanguageMutations,loading,error} = UseGetWebSettings((d) => {
        InitFormData(d);
    });

    

    return(
        <>
            <ContentHeader
                title = {t("WebSettings")}
                OnSaveButtonClick = {() => EditWebSettings()}
                allLanguages = {allLanguageMutations}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />
            <ContentBody>

                {error ?
                    <Error text = {GetApolloErrorText(error)} />
                :
                    (loading || editLoading ?
                        <Loading />
                    :
                        <>
                            <InputCard
                                className={"form-group"}
                                title = {t("Contact")}
                                icon={contactIcon}
                            >
                                <div className="row">
                                    <div className="col-100">
                                        <label className="input-label">{t("Email")}</label>
                                        <div className="form-group">
                                            <Input 
                                                className="form-control" 
                                                type="text" 
                                                name="email"
                                                OnChange={(e) => SetFormLangData(e)}
                                                value={GetFormLangValue("email")}
                                            />
                                            
                                        </div>
                                    </div>
                                    <div className="col-50">

                                        <label className="input-label">{t("Tel")}</label>
                                        <div className="form-group">
                                            <Input 
                                                className="form-control" 
                                                type="text" 
                                                name="tel"
                                                OnChange={(e) => SetFormLangData(e)}
                                                value={GetFormLangValue("tel")}
                                            />
                                            
                                        </div>

                                    </div>
                                    <div className="col-50">

                                        <label className="input-label">{t("TelHours")}</label>
                                        <div className="form-group">
                                            <Input 
                                                className="form-control" 
                                                type="text" 
                                                name="telHours"
                                                OnChange={(e) => SetFormLangData(e)}
                                                value={GetFormLangValue("telHours")}
                                            />
                                            
                                        </div>

                                    </div>

                                </div>
                                
                            </InputCard>

                            <InputCard
                                title = {t("AssemblyAndYield")}
                                icon={paragraphIcon}
                                headerInfoContent={[t('AssemblyYieldTextInfo')]}
                            >
                                <div className="row">
                                    <div className="col-50 col-sm-100">
                                        <label className="input-label">{t("AssemblyText")}</label>
                                        <div className="form-group">
                                            
                                            <TinyMCEEditor 
                                                value = {GetFormLangValue("productDetailAssemblyText")}
                                                OnEditorChange={(content) => SetFormLangData("productDetailAssemblyText",content)}
                                                toolbar = {TINY_MCE_TOOLBAR_BASIC}
                                            />
                                            
                                        </div>
                                    </div>
                                    <div className="col-50 col-sm-100">

                                        <label className="input-label">{t("YieldText")}</label>
                                        <div className="form-group">

                                            <TinyMCEEditor 
                                                value = {GetFormLangValue("productDetailYieldText")}
                                                OnEditorChange={(content) => SetFormLangData("productDetailYieldText",content)}
                                                toolbar = {TINY_MCE_TOOLBAR_BASIC}
                                            />
                                            
                                        </div>

                                    </div>
                                    
                                </div>
                                
                            </InputCard>
                            
                        </>                                                                    
                    )
                }

            </ContentBody>
        </>
    )
}

export default WebSettings;