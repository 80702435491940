import { useEffect } from 'react';
import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseChooseCategories from '../../Library/Products/UseChooseCategories';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import Error from '../../../../GlobalComponents/Error';
import List from '../../../../GlobalComponents/List/List';

const ChooseCategories = ({
    formData,
    SetShowAddCategories,
    useDeletedAttribute,
    FillFormSelectedItemsWithObj, 
}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const {
        content,
        headerData,
        loading,
        error,
        SelectRow
    } = UseChooseCategories(user.lang,formData.selectedCategories,FillFormSelectedItemsWithObj,useDeletedAttribute);

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddCategories(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("ChooseCategories")}
                OnClose ={() => SetShowAddCategories(false)}
            />

            <div className="modal-body no-padding">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    <>
                        <List 
                            headerData = {headerData} 
                            contentData = {content}
                            isSortable = {false}
                            hideOptions = {true}
                            loading = {loading}
                            options = {{
                                OnSelect: (e,rowData) => {
                                    SelectRow(e,rowData);
                                }
                            }}
                        />
                    </>
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddCategories(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseCategories;