import yes from '../Media/Images/Icons/verified.webp';
import no from '../Media/Images/Icons/cancel.webp';

const YesNo = ({isYes}) => {

    return(
        <>
            {isYes ?
                <img className="verified" src={yes} />
                :
                <img className="cancel" src={no} />
            }
        </>
    )

}
export default YesNo;