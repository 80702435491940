import RadioButton from "../../../../GlobalComponents/RadioButton";
import Button from "../../../../GlobalComponents/Button";
import Checkbox from "../../../../GlobalComponents/Checkbox";
import NoItems from "../../../../GlobalComponents/NoItems";
import InputCard from "../../../../GlobalComponents/InputCard";
import photosIcon from '../../../../Media/Images/Icons/image.webp';
import FileInput from "../../../../GlobalComponents/FileInput";
import { useTranslation } from 'react-i18next';
import UseUploadPhotos from "../../../../GlobalHooks/UseUploadPhotos";
import UseDeletePhotos from "../../../../GlobalHooks/UseDeletePhotos";
import { COVER_IMAGE_URL, SERVER_URL } from "../../../../Config";
import Input from "../../../../GlobalComponents/Input";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import Loading from "../../../../GlobalComponents/Loading";
import { UPLOAD_COVER_PHOTOS, DELETE_COVER_PHOTOS } from "../../Queries/covers";
import { UseNotification } from "../../../Notification/Library/UseNotification";

const CoverPhotos = ({
    coverPhotos,
    selectedCoverID,
    SetFormData,
    SetFormDataSelectedItemsWithObj,
    SetBooleanFormDataSelectedItemsWithObj,
    RemoveFormDataSelectedItems
}) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const {loading,UploadPhotos} = UseUploadPhotos(UPLOAD_COVER_PHOTOS,"coverID",selectedCoverID,(d) => {
        var arr = [...coverPhotos,...d.UploadCoverPhotos];
        SetFormData("coverPhotos",arr);
    });

    const {
        selectedItems,
        showDeleteNotification, 
        SelectPhoto, 
        DeletePhotos,
        ShowDeleteNotification
    } = UseDeletePhotos(DELETE_COVER_PHOTOS,"coverPhotoIDs",(d) => {
        const IDs = d.DeleteCoverPhotos.split(",");
        RemoveFormDataSelectedItems("coverPhotos","coverPhotoID",IDs)
    });

    const RemovePhotos = () => {

        var checkIsMain = false;
        for(let si of selectedItems)
        {
            for(let cp of coverPhotos)
            {
                if(cp.isMain && si == cp.coverPhotoID)
                    checkIsMain = true;
            }
        }
        
        if(!checkIsMain)
            ShowDeleteNotification(true)
        else
            AddNotification(t("YouAreDeletingMainPhoto"),false);
    }
    

    return(
        <div>
            {selectedCoverID && selectedCoverID != 0 ?
                <InputCard
                    title = {t("Photos")}
                    icon = {photosIcon}
                    headerChildren = {
                        <>
                            <FileInput
                                showOnlyButton = {true}
                                OnChange = {(e) => UploadPhotos(e.target.files)}
                                buttonTitle = {t("UploadImages")}
                                btnClassName = "narrow"
                                inputClassName = "narrow"
                            />
                        
                            <Button
                                className="btn-brown narrow"
                                OnClick = {() => RemovePhotos()}
                            >
                            {t("DeleteSelected")}     
                            </Button>
                            
                        </>
                    }
                > 
                    {loading ?
                        <Loading />    
                    :
                        (coverPhotos && coverPhotos.length > 0 ?
                            <div className="row">
                                {coverPhotos.map((item) => {
                                    
                                    var check = false;
                                    for(let val of selectedItems)
                                    {
                                        if(val == item.coverPhotoID)
                                            check = true;   
                                    }

                                    return(
                                        <div key={item.coverPhotoID} className="col-25">
                                            <div className="cm-image-container form-group">
                                                <div className="select">
                                                    <Checkbox 
                                                        text = {""}
                                                        name = "selectedCoverPhoto"
                                                        checked = {check}
                                                        OnChange = {() => SelectPhoto(item.coverPhotoID)}
                                                    />
                                                </div>
                                                <img 
                                                    src = {SERVER_URL + "/" + COVER_IMAGE_URL + "/c-" + selectedCoverID + "/stredni_" + item.name} 
                                                    alt={item.name} 
                                                />
                                            </div>
                                            <div className="form-group">
                                                <RadioButton 
                                                    text = {t("MainPhoto")}
                                                    name = "mainPhoto"
                                                    id = {"mp - " + item.coverPhotoID} 
                                                    checked = {item.isMain}
                                                    OnChange = {() => {
                                                        SetBooleanFormDataSelectedItemsWithObj("isMain","coverPhotos","coverPhotoID",item.coverPhotoID)
                                                        SetFormDataSelectedItemsWithObj("isUpdated","coverPhotos","coverPhotoID",item.coverPhotoID,true);
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Input
                                                    placeholder = {t("Description")}
                                                    value = {item.description ? item.description : ""}
                                                    name = "description"
                                                    OnChange = {(e) => {
                                                        SetFormDataSelectedItemsWithObj(e,"coverPhotos","coverPhotoID",item.coverPhotoID);
                                                        SetFormDataSelectedItemsWithObj("isUpdated","coverPhotos","coverPhotoID",item.coverPhotoID,true);
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Input
                                                    placeholder = {t("Number")}
                                                    value = {item.number ? item.number : ""}
                                                    name = "number"
                                                    OnChange = {(e) => {
                                                        SetFormDataSelectedItemsWithObj(e,"coverPhotos","coverPhotoID",item.coverPhotoID);
                                                        SetFormDataSelectedItemsWithObj("isUpdated","coverPhotos","coverPhotoID",item.coverPhotoID,true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <NoItems text={t("NoImagesHereYet")} />
                        )
                    }
                </InputCard>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddPhotosSaveBasicInformationsFirst")} 
                />   
            }

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo = {true} 
                    text = {t("DoYouReallyWantToDeletePhotos")} 
                    CallBack = {DeletePhotos} 
                />
            :null}

        </div>
    )
}

export default CoverPhotos;