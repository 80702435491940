import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

const TinyMCEEditor = ({toolbar,value,OnEditorChange}) => {

    const editorRef = useRef(null);

    var init = {
        menubar: false,
        branding: false,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'wordcount'
        ],
        toolbar: toolbar,
        license_key: 'gpl',
        promotion: false,
        content_style: "p,li,div,td,a{ color: #5f5f5f} body { font-family: Barlow;font-weight:300 }"
    }

    return(
        <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            inline = {false}
            paste_as_text = {true}
            language = {"cs"}
            value = {value}
            onEditorChange={(content) => OnEditorChange(content)}
            init={init}
        />
    )
}

export default TinyMCEEditor;