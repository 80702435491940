const UseGetProductSeoData = (GetFormLangValue,allManufacturers,formData) => {

    const Strip = (html) => {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    var hManu = "";
    for(let val of allManufacturers)
    {
        if(val.manufacturerID == formData.manufacturerID)
            hManu = val.shortcut;
    }

    var dimensionsData = "";
    if(formData.productDimensionType)
        for(let val of formData.productDimensionType.sizePositions)
        {
            if(val.value)
            {
                if(dimensionsData)
                    dimensionsData += "x";
                dimensionsData += val.value; 
            }
        }
    if(dimensionsData)
        dimensionsData += "cm";

    var hName = GetFormLangValue("name");
    var hNameWithParameters = (hManu ? hManu + ", " : "") + GetFormLangValue("name") + (dimensionsData ? ", " + dimensionsData : "");
    var hManufacturer = hManu;
    var hDescription = Strip(GetFormLangValue("description"));
    var stripedHDescritpion = Strip(GetFormLangValue("hDescription"));

    if(hDescription != stripedHDescritpion && stripedHDescritpion != "")
        hDescription = stripedHDescritpion;

    if(hName != GetFormLangValue("hName") && GetFormLangValue("hName") != "")
        hName = GetFormLangValue("hName");

    if(
        (hManu ? hManu + ", " : "") + GetFormLangValue("name") + (dimensionsData ? ", " + dimensionsData : "") != GetFormLangValue("hNameWithParameters") &&
        GetFormLangValue("hNameWithParameters") != ""
    )
        hNameWithParameters = GetFormLangValue("hNameWithParameters");

    if(hManufacturer != formData.hManufacturer && formData.hManufacturer != "")
        hManufacturer = formData.hManufacturer;


    return{
        hName,
        hNameWithParameters,
        hManufacturer,
        hDescription
    }

}

export default UseGetProductSeoData;