import gql from 'graphql-tag';

export const ADD_EDIT_COVER_TITLE = gql`
    mutation AddEditCoverTitle(
        $coverTitleID:ID,
        $shortcut:String,
        $langs:[CoverTitleLangsInput]
    ){
        AddEditCoverTitle(
            coverTitleID:$coverTitleID,
            shortcut: $shortcut,
            langs:$langs
        ){
            coverTitleID
            name
            shortcut
        }
    }
`;

export const GET_COVER_TITLE = gql`
    query CoverTitleWithLangs($coverTitleID: ID){
        CoverTitleWithLangs(coverTitleID:$coverTitleID){
            coverTitleID
            shortcut
            langs{
                lang
                name
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_COVER_TITLES = gql`
    query AllCoverTitles($lang: String){
        AllCoverTitles(lang:$lang){
            coverTitleID
            name
            shortcut
        }
    }
`;

export const DELETE_COVER_TITLES = gql`
    mutation DeleteCoverTitles($coverTitleIDs:ID) {
        DeleteCoverTitles(coverTitleIDs:$coverTitleIDs)
    }
`;

export const UPDATE_COVER_TITLE_PRIORITY = gql`
    mutation UpdateCoverTitlePriority($coverTitleID:ID,$fromIndex:Int,$toIndex:Int) {
        UpdateCoverTitlePriority(coverTitleID:$coverTitleID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;