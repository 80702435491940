import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { CHANGE_PASSWORD } from "../Queries/auth";
import { useTranslation } from "react-i18next";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const INIT_FORM_DATA = {
    oldPassword:"",
    newPassword:"",
    checkNewPassword:""
}

const UseChangePassword = () => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(INIT_FORM_DATA);

    const [ChangePasswordMutation] = useMutation(CHANGE_PASSWORD,{
        variables:{
            oldPassword:formData.oldPassword,
            password:formData.newPassword
        },
        onCompleted:() => {
            AddNotification(t("ItIsSaved"),true);
            SetCompleteFormData(INIT_FORM_DATA);
        },
        onError:(err) => {

            const error = GetApolloErrorText(err);

            if(error === "WRONG_OLD_PASSWORD")
                AddNotification(t("WrongOldPassword"),false);
            else
                AddNotification(error,false);
        }
    })

    const ChangePassword = () => { 
    
        if(formData.oldPassword !== '')
        {
            if(formData.newPassword !== '' && formData.checkNewPassword !== '' && formData.newPassword === formData.checkNewPassword)
            {
                
                if(formData.newPassword !== formData.oldPassword)
                {   
                    ChangePasswordMutation({
                        variables:{
                            password:formData.newPassword,
                            oldPassword:formData.oldPassword
                        }
                    });
                }
                else
                    AddNotification(t("OldAndNewPasswordHasNotBeSame"),false);
            }
            else
                AddNotification(t("NewPasswordsAreNotSame"),false);
        }
        else
            AddNotification(t("InvalidOldPassword"),false);

    }

    return{
        formData,
        SetFormData,
        ChangePassword
    }
}

export default UseChangePassword;














