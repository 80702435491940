import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_RELATED_PRODUCT } from "../../Queries/relatedProducts";

const UseAddRelatedProduct = (productID,selectedProductIDs,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        onBothSides:0,
    });

    const [AddRelatedProductMutation,{error,loading}] = useMutation(ADD_RELATED_PRODUCT,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditManufacturer);

            const notifyText = t("NewRelatedProductsAreInTheWorld");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddRelatedProduct = () => {

        if(selectedProductIDs && selectedProductIDs.length > 0)
        {    
            AddRelatedProductMutation({
                variables:{
                    productID:     productID,
                    relProductID:  selectedProductIDs,
                    onBothSides:   parseInt(formData.onBothSides)
                }
            })
           
        }
        else
            AddNotification(t("YouHaveNotChoosenProducts"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddRelatedProduct,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddRelatedProduct;