import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const UseEditTickTickLabels = (selectedTickLabels,SetFormDataSelectedItemsWithObj) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedTickLabels)
        {
            const contentData = GetContentData(selectedTickLabels);
            SetContent(contentData);
        }

    },[selectedTickLabels])

    var headerData = [
        {
            value: t("TickLabelName"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID         = val.tickLabelID;
            cData.selected      = false;

            contentData.push(cData);
        }
        return contentData;
    }

    return{
        headerData,
        content
    }

}

export default UseEditTickTickLabels;