const NoItems = ({text, className}) => {
	
    return (
        <div className={"alert alert-warning" + (className ? " " + className : "")}>	
            {text}
        </div>
    );
}


export default NoItems;
