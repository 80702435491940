import React,{ useEffect, useState } from 'react';
import Notifications from '../Modules/Notification/Components/Notifications';
import Header from './Header';
import { UseAuth } from '../Modules/Auth/Library/UseAuth';
import { useNavigate, Outlet } from "react-router-dom";

const Layout = () => {

    const [isShrink,SetIsShrink] = useState(false);

	const {IsSignedIn,loading,user,GetAdminUserContext} = UseAuth();
	const navigate = useNavigate();
	
	useEffect(() => {
        if(!IsSignedIn() && !loading)
            navigate("/");
    },[IsSignedIn,loading])

    if(!user.adminUserID && IsSignedIn() && !loading)
        GetAdminUserContext();
        	
    return (
		<>
			{IsSignedIn() && !loading ?	   
               		       
                <> 
                    <Header isShrink = {isShrink} SetIsShrink = {SetIsShrink} />  
                    <div className={"whole-content" + (isShrink ? " shrink" : "")}>
                                                
                        <div className={"l-content"}>
                                                
                            <Outlet />	
                            
                        </div>
                        
                    </div>
                    <Notifications />
                 </>           
                
			:null}
   		</>
    );
  
}

export default Layout;
