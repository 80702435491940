import {useQuery} from '@apollo/client';
import { GET_RELATED_PRODUCTS } from '../../Queries/relatedProducts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { SERVER_URL,PRODUCT_IMAGE_URL } from '../../../../Config';

const UseGetAllRelatedProducts = (lang,productID) => {

    const [variables,SetVariables] = useState({
        lang,
        productID
    });

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedRelatedProductIDs,SetSelectedRelatedProductIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_RELATED_PRODUCTS,{
        variables,
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllAdminRelatedProducts);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("ProductName"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.mainPhoto ? SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + val.relProductID + "/mala_" + val.mainPhoto : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.relatedProductID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,relatedProductID) => {

        var checked = e.target.checked;
        var newSelectedRelatedProductIDs = [...selectedRelatedProductIDs];
        const newList = SelectRelatedProductID(content,relatedProductID,checked,newSelectedRelatedProductIDs)

        SetContent(newList);
        SetSelectedRelatedProductIDs(newSelectedRelatedProductIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedRelatedProductIDs = [];
        const newList = SelectRelatedProductID(content,"all",checked,newSelectedRelatedProductIDs)

        SetSelectedRelatedProductIDs(newSelectedRelatedProductIDs);
        SetContent(newList);
    }

    const SelectRelatedProductID = (list,relatedProductID,checked,selectedRelatedProductIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(relatedProductID == newList[i].rowID || relatedProductID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedRelatedProductIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedRelatedProductIDs.length; j++)
                    {
                        if(selectedRelatedProductIDs[j] == newList[i].rowID)
                            selectedRelatedProductIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectRelatedProductID(newList[i].subData,relatedProductID,checked,selectedRelatedProductIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedRelatedProductIDs,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllRelatedProducts;