import gql from 'graphql-tag';

export const ADD_EDIT_SOCIAL_NETWORK = gql`
    mutation AddEditSocialNetwork(
        $socialNetworks:[SocialNetworkInput],
    ){
        AddEditSocialNetwork(
            socialNetworks:$socialNetworks
        )
    }
`;

export const GET_SOCIAL_NETWORKS = gql`
    query AllSocialNetworks{
        AllSocialNetworks{
            socialNetworkID
            type
            url
            icon
        }
    }
`;