import ListRow from "./ListRow";
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

const ListSortableContent = ({
    data,
    options,
    level,
    isSortable,
    hideOptions,
    OnDragEnd,
    replaceDotsImg,
    ReplaceDotsOnClick
}) => {
    return(
        <DragDropContext onDragEnd={(result) => OnDragEnd(result)}>
            <Droppable droppableId={"droppable"}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {data && data.map((item,index) => {

                            return(
                                <Draggable 
                                    key={item.rowID + (item.parentID ? "-" + item.parentID : "")} 
                                    draggableId={item.rowID + (item.parentID ? "-" + item.parentID : "")} 
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div className="data-list-item" 
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            
                                        >
                                            <ListRow 
                                                key={index} 
                                                data = {item} 
                                                options = {options} 
                                                hideOptions = {hideOptions}
                                                isSortable = {isSortable}
                                                level = {level}
                                                provided = {provided}
                                                replaceDotsImg = {replaceDotsImg}
                                                ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                            />
                                            {item.subData ?
                                                <ListSortableContent 
                                                    data = {item.subData} 
                                                    options = {options} 
                                                    hideOptions = {hideOptions}
                                                    isSortable = {isSortable}
                                                    level = {level + 1}
                                                    OnDragEnd = {OnDragEnd}
                                                    replaceDotsImg = {replaceDotsImg}
                                                    ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                                />
                                            :null}
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default ListSortableContent;