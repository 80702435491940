import { useTranslation } from 'react-i18next';

const FileInput = ({name,btnClassName,inputClassName,showOnlyButton,buttonTitle,label,lang,OnChange}) => {

    const {t} = useTranslation();

    return(
        <div className={"input-group" + (showOnlyButton ? " only-button" : "")}>
            <input 
                key={lang}
                type = "file" 
                className = {inputClassName}
                name = {name} 
                multiple = {true}
                onChange = {(e) => OnChange(e)}
            />
            <div className="file-input-overlay">
                {!showOnlyButton ? (label || label == "" ? label : t("ChoosePhoto")) : ""}
                <div className = {"btn btn-primary" + (btnClassName ? " " + btnClassName : "") + (showOnlyButton ? " w-100 no-margin" : "")}>{buttonTitle ? buttonTitle : t("Choose")}</div>
            </div>
        </div>
    )
}

export default FileInput;