import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import downloadIcon from "../../../Media/Images/Icons/download.webp";
import InputCard from "../../../GlobalComponents/InputCard";
import Button from "../../../GlobalComponents/Button";
import { EXPORT_EMAILS } from "../Queries/newsletter";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const Newsletter = () => {

    const {t} = useTranslation();

    const [ExportEmail,{loading,error}] = useMutation(EXPORT_EMAILS,{
        onCompleted:(d) => {

            console.log(d);

            const link = document.createElement("a");
            link.href = d.ExportEmails;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    });
   

    return(
        <>
            <ContentHeader
                title = {t("Newsletter")}
            />
            <ContentBody>

                <InputCard
                    title = {t("Export")}
                    icon={downloadIcon}
                >
                    {loading ?
                        <Loading />
                    :
                        (error ?
                            <Error text = {GetApolloErrorText(error)} />
                        :
                            <div className="form-group">
                                <Button
                                    OnClick={() => ExportEmail()}
                                    className="btn-primary"
                                >{t("ExportEmails")}</Button>   
                            </div>
                        )
                    }
                    
                </InputCard>

            </ContentBody>
        </>
    )
}

export default Newsletter;