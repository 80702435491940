import { useEffect,useMemo } from "react";
import { useQuery } from "@apollo/client";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import BannerResponziveSettings from "./BannerResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { BANNER } from "../../../Library/elements";
import bannerIcon from '../../../../../Media/Images/Icons/banner.webp';
import { GET_BANNERS } from "../../../../Banner/Queries/banner";
import Loading from "../../../../../GlobalComponents/Loading";
import Select from "../../../../../GlobalComponents/Select";
import Error from "../../../../../GlobalComponents/Error";

const BannerSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData,SetArrayValue} = UseFormHandle(BANNER.banner); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    const {data,loading,error} = useQuery(GET_BANNERS,{
        fetchPolicy:'network-only',
        variables:{
            lang: "cz"
        }
    });

    const bigBanners = useMemo(() => {
        if(data)
            return data.AllBanners.filter((item) => item.bannerTypeID == 1)
        return []
    }, [data])

    const smallBanners = useMemo(() => {
        if(data)
            return data.AllBanners.filter((item) => item.bannerTypeID == 2)
        return []
    }, [data])

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"banner");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    useEffect(() => {
        SetSettings(showSettingsElement.index,"bigBannerIDs",formData.bigBannerIDs,"banner");
    },[formData.bigBannerIDs])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"banner");
    }

    const SetBigBanner = (e,index) => {
        SetArrayValue("bigBannerIDs",index,e.target.value);
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"banner");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <BannerResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <BannerResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <BannerResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <BannerResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <BannerResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <BannerResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="form-group">
                <InputCard
                    className = "form-group"
                    title = {t("Banners")}
                    icon = {bannerIcon}
                >   
                    {error ?
                        <Error text={error} />
                    :
                        (loading ?
                            <Loading />
                        :
                            <>
                                <div>
                                    <label>{t("BigBanners")}</label>
                                    {formData.bigBannerIDs.map((item,index) => (
                                        <div className="form-group" key = {index}>
                                            <Select
                                                name="bigBannerID"
                                                value={item}
                                                OnChange = {(e) => SetBigBanner(e,index)}
                                            >
                                                <option value={0}>{t("---choose---")}</option>
                                                {bigBanners.map((item) => (
                                                    <option 
                                                        key={"b-" + item.bannerID}
                                                        value={item.bannerID}
                                                    >{item.name}</option>
                                                ))}
                                            </Select>
                                        </div>
                                    ))}
                                    
                                    
                                </div>

                                <hr />

                                <div className="form-group">
                                    <label>{t("SmallTopBanner")}</label>
                                    <Select
                                        name="smallTopBannerID"
                                        value={formData.smallTopBannerID}
                                        OnChange = {(e) => SetS(e)}
                                    >
                                        <option value={0}>{t("---choose---")}</option>
                                        {smallBanners.map((item) => (
                                            <option 
                                                key={item.bannerID}
                                                value={item.bannerID}
                                            >{item.name}</option>
                                        ))}
                                    </Select>
                                    
                                </div>
                                <div className="form-group">
                                    <label>{t("SmallBottomBanner")}</label>
                                    <Select
                                        name="smallBottomBannerID"
                                        value={formData.smallBottomBannerID}
                                        OnChange = {(e) => SetS(e)}
                                    >
                                        <option value={0}>{t("---choose---")}</option>
                                        {smallBanners.map((item) => (
                                            <option 
                                                key={item.bannerID}
                                                value={item.bannerID}
                                            >{item.name}</option>
                                        ))}
                                    </Select>
                                    
                                </div>
                            </>
                        )
                    }
                
                </InputCard>
            </div>
            <div className="row">
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default BannerSettings;