import gql from 'graphql-tag';

export const ADD_EDIT_MANUFACTURER = gql`
    mutation AddEditManufacturer(
        $manufacturerID:ID,
        $name:String, 
        $shortcut:String,
        $deliveryDays:Int, 
        $langs:[ManufacturerLangsInput]
    ){
        AddEditManufacturer(
            manufacturerID:$manufacturerID,
            name: $name, 
            shortcut: $shortcut, 
            deliveryDays:$deliveryDays, 
            langs:$langs
        ){
            manufacturerID
            name
            shortcut
        }
    }
`;

export const GET_MANUFACTURER = gql`
    query ManufacturerWithLangs($manufacturerID: ID){
        ManufacturerWithLangs(manufacturerID:$manufacturerID){
            manufacturerID
            name
            shortcut
            deliveryDays
            langs{
                lang
                deliveryTimeToCustomer
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_MANUFACTURERS = gql`
    query AllManufacturers($lang: String,$searchText: String,$limit:Int,$offset:Int){
        AllManufacturers(lang:$lang,searchText:$searchText,limit:$limit,offset:$offset){
            manufacturerID
            name
            shortcut
        }
        AllManufacturersCount(lang:$lang,searchText:$searchText)
    }
`;

export const DELETE_MANUFACTURERS = gql`
    mutation DeleteManufacturers($manufacturerIDs:ID) {
        DeleteManufacturers(manufacturerIDs:$manufacturerIDs)
    }
`;