import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import packageIcon from '../../../Media/Images/Icons/package.webp';
import InputCard from "../../../GlobalComponents/InputCard";
import Button from "../../../GlobalComponents/Button";
import NoItems from '../../../GlobalComponents/NoItems';
import UseGetLinkProducts from '../Library/UseGetLinkProducts';
import AddEditProduct from '../../Eshop/Components/Products/AddEditProduct';
import AddMultipleProducts from './AddMultipleProducts';
import ContentFilterSidebar from '../../../GlobalComponents/ContentFilterSidebar';
import ProductFilter from '../../Eshop/Components/Products/ProductFilter';
import ModalFilterPanel from '../../../GlobalComponents/Modal/ModalFIlterPanel';

const LinkProducts = ({selectedLinkID,lang}) => {

    const {t} = useTranslation();
    const [showAddMultipleProducts, SetShowAddMultipleProducts] = useState(null);
    const [selectedProductID, SetSelectedProductID] = useState(null);
    const [showFilterSidebar,SetShowFilterSidebar] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        FetchMore,
        GoToPage,
        SetNewPriority,
        FilterProducts
    } = UseGetLinkProducts(lang,selectedLinkID);

    return(
        <div>
            {selectedLinkID && selectedLinkID != 0 ?
                <>
                    {error ?
                        <Error text={GetApolloErrorText(error)} />
                    :
                        <>
                            <InputCard
                                title = {t("Products")}
                                icon = {packageIcon}
                                noBodyPadding = {true}
                                headerChildren = {
                                    <div className="ml-auto d-flex">
                                        
                                            <Button
                                                className="btn-blue narrow"
                                                OnClick = {() => SetShowFilterSidebar(true)}
                                            >{t("Filter")}</Button>
                                       
                                            <Button
                                                className="btn-primary narrow"
                                                OnClick = {() => SetShowAddMultipleProducts(true)}
                                            >{t("Add")}</Button>
                                        
                                    </div>
                                }
                            > 
                                <List 
                                    headerClassName={"no-top-border"}
                                    headerData = {headerData} 
                                    contentData = {content}
                                    totalContentLength = {totalContentLength}
                                    paggingOffset = {paggingOffset}
                                    paggingLimit = {paggingLimit}
                                    FetchMore = {FetchMore}
                                    GoToPage = {GoToPage}
                                    isSortable = {false}
                                    loading = {loading}
                                    options = {{
                                        OnEditClick: (productID) => SetSelectedProductID(productID),
                                        OnPriorityClick : (productID,newPriority) => SetNewPriority(productID,newPriority)
                                    }}
                                />
                                {showFilterSidebar ?
                                    <ModalFilterPanel
                                        OnClose = {() => SetShowFilterSidebar(false)}
                                    >
                                        <ProductFilter
                                            OnFilter = {(filterData) => FilterProducts(filterData)}
                                        />
                                    </ModalFilterPanel>
                                :null}
                            </InputCard>

                            {selectedProductID || selectedProductID == 0 ?
                                <AddEditProduct
                                    selectedProductID = {selectedProductID}
                                    SetSelectedProductID = {SetSelectedProductID}
                                />
                            :null}

                            {showAddMultipleProducts ?
                                <AddMultipleProducts
                                    linkID = {selectedLinkID}
                                    SetShowAddMultipleProducts = {SetShowAddMultipleProducts}
                                />
                            :null}
                            
                        </>
                    }

                </>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToSeeProductsSaveBasicInformationsFirst")} 
                />   
            }

        </div>
    )
}

export default LinkProducts;