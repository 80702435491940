import gql from 'graphql-tag';

export const EDIT_WEB_SETTINGS = gql`
    mutation SaveWebSettings(
        $langs:[WebSettingsInput]
    ){
        SaveWebSettings(
            langs:$langs
        )
    }
`;

export const GET_WEB_SETTINGS = gql`
    query WebSettingsWithLang{
        WebSettingsWithLang{
            langs{
                lang
                email
                tel
                telHours
                productDetailAssemblyText
                productDetailYieldText
            }   
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;