import { useTranslation } from 'react-i18next';
import edit from '../../Media/Images/Icons/edit.webp';
import del from '../../Media/Images/Icons/delete.webp';
import copy from '../../Media/Images/Icons/copy.webp';
import content from '../../Media/Images/Icons/content_2.webp';
import move from '../../Media/Images/Icons/move.webp';
import Input from '../Input';
import Button from '../Button';
import { useState,useRef,useEffect  } from 'react';

const ListRowOptions = ({options,data,priority,SetShowOptions}) => {

    const ref = useRef();

    const {t} = useTranslation();
    const [newPriority, SetPriority] = useState("");
    const [optionPosition, SetOptionPosition] = useState("");

    useEffect(() => {

        if(ref.current)
        {
            var height = window.innerHeight;
            if(height - ref.current.getBoundingClientRect().y < 200){
                SetOptionPosition("top");
            }
        }

    },[ref.current])

    const {OnContentClick,OnEditClick,OnCopyClick,OnDeleteClick,OnPriorityClick} = options;

    return(
        <div 
            className = {"list-row-options " + optionPosition}
            ref = {ref}
        >
            {OnContentClick ?
                <div 
                    className="options-row" 
                    onClick={() => {SetShowOptions(false); OnContentClick(data.rowID)}}
                >
                    <img src={content} />
                    {t("Content")}
                </div>
            :null}
            {OnEditClick ?
                <div 
                    className="options-row" 
                    onClick={() => {SetShowOptions(false); OnEditClick(data.rowID)}}
                >
                    <img src={edit} />
                    {t("Edit")}
                </div>
            :null}
            {OnCopyClick ?
                <div 
                    className="options-row" 
                    onClick={() => {SetShowOptions(false); OnCopyClick(data.rowID)}}
                >
                    <img src={copy} />
                    {t("Copy")}
                </div>
            :null}
            {OnDeleteClick ?
                <div
                    className="options-row"  
                    onClick={() => {SetShowOptions(false); OnDeleteClick(data.rowID)}}
                >
                    <img src={del} />
                    {t("Delete")}
                </div>
            :null}
            {OnPriorityClick ?
                <div className="options-row no-hover">
                    <div>
                        {t("Priority")}
                        <div className="d-flex">

                            <Input 
                                value={newPriority} 
                                type="number" 
                                OnChange={(e) =>SetPriority(e.target.value)}
                            />
                            <Button 
                                OnClick={() => {SetShowOptions(false); OnPriorityClick(data.rowID,newPriority)}}
                                className="btn-primary"
                            >
                                <img src={move} />
                            </Button>
                        </div>
                    </div>
                </div>
            :null}
        </div>
    )
}

export default ListRowOptions;