import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

const TinyMCEEditor = ({toolbar,value,forcedRootBlock,OnEditorChange,OnFormatApply,OnFormatRemove}) => {

    const editorRef = useRef(null);

    var init = {
        menubar: false,
        branding: false,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'wordcount'
        ],
        toolbar: toolbar,
        license_key: 'gpl',
        promotion: false,
        formats: {
            // Changes the alignment buttons to add a class to each of the matching selector elements
            alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'text-left' },
            aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'text-center' },
            alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'text-right' },
            alignjustify: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'text-justify' }
        },
        init_instance_callback: (editor) => {
            editor.on('FormatApply', (e) => {
                if(OnFormatApply)
                    OnFormatApply(e);
            });
            editor.on('FormatRemove', (e) => {
                if(OnFormatRemove)
                    OnFormatRemove(e);
            });
        }
    }

    if(forcedRootBlock || forcedRootBlock == "")
        init.forced_root_block = forcedRootBlock

    return(
        <Editor
            tinymceScriptSrc='/tinymce/tinymce.min.js'
            onInit={(evt, editor) => editorRef.current = editor}
            inline = {true}
            paste_as_text = {true}
            language = {"cs"}
            value = {value}
            onEditorChange={(content) => OnEditorChange(content)}
            init={init}
        />
    )
}

export default TinyMCEEditor;