import { useEffect, useState } from "react";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

var timer = null;

const SearchInput = ({placeholder,className,initValue,OnChange}) => {

    const [value,SetValue] = useState((initValue ? initValue : ""));

    useEffect(() => {
        SetValue(initValue);
    },[initValue])

    const ChangeText = (e) => {

        clearTimeout(timer);
        SetValue(e.target.value);
        timer = setTimeout(() => OnChange(e.target.value),WAIT_INTERVAL);
    }

    const HandleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(timer);
            OnChange(e.target.value);
        }
    }
	
    return (
        <div className="input-group">
            <input 
                type        = "text" 
                className   = {className} 
                placeholder = {placeholder} 
                value       = {value} 
                onChange    = {(e) => ChangeText(e)}
                onKeyDown   = {(e) => HandleKeyDown(e)}
            />
        </div>
    );
}


export default SearchInput;
