import Button from "./Button";
import { useTranslation } from 'react-i18next';
import Select from "./Select";

const ContentHeader = (props) => {

    const {
        title,
        allLanguages,
        selectedLang,
        OnLangSelect,
        OnAddButtonClick,
        OnDeleteButtonClick,
        OnFilterClick,
        OnSaveButtonClick
    } = props;

    const {t} = useTranslation();

    return(
        <div className="content-header">

            <h1>{title}</h1>

            <div className="buttons">
                {allLanguages && allLanguages.length > 0 ? 
                    <Select 
                        name="selectedLang"
                        value={selectedLang}
                        OnChange={(e) => OnLangSelect(e)}
                    >
                        {allLanguages.map((item) => (
                            <option key={item.languageID} value = {item.suffix}>{item.suffix}</option>
                        ))}
                    </Select>
                :null}
                {OnFilterClick ?
                    <Button 
                        className="btn-blue"
                        OnClick={() => OnFilterClick()} 
                    >
                        {t("Filter")}
                    </Button>
                :null}
                {OnAddButtonClick ?
                    <Button 
                        OnClick={() => OnAddButtonClick()} 
                        className="btn-primary"
                    >
                        {t("Add")}
                    </Button>
                :null}
                {OnDeleteButtonClick ?
                    <Button 
                        OnClick={() => OnDeleteButtonClick()} 
                        className="btn-brown"
                    >
                        {t("Delete")}
                    </Button>
                :null}
                {OnSaveButtonClick ?
                    <Button 
                        OnClick={() => OnSaveButtonClick()} 
                        className="btn-primary"
                    >
                        {t("Save")}
                    </Button>
                :null}
            </div>
        </div>
    )
}

export default ContentHeader;