import { useEffect,useState } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import RecommendedProductsResponziveSettings from "./RecommendedProductsResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { RECOMMENDED_PRODUCTS } from "../../../Library/elements";
import ChooseProducts from "../../../../Eshop/Components/Products/ChooseProducts";
import productIcon from '../../../../../Media/Images/Icons/package.webp';
import cancelIcon from '../../../../../Media/Images/Icons/cancel.webp';
import UseEditRecomendedProducts from "../../../Library/UseEditRecomendedProducts";
import List from "../../../../../GlobalComponents/List/List";
import Error from "../../../../../GlobalComponents/Error";

const RecommendedProductsSettings = () => {

    const [showAddProducts, SetShowAddProducts] = useState(false);

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData,FillFormSelectedItemsWithObj,RemoveFormDataSelectedItems} = UseFormHandle(RECOMMENDED_PRODUCTS.recommendedProducts); 
    const {
        formData:cmFormData,
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    const {
        headerData,
        content,
        loading,
        error,
        GetSelectedProducts
    } = UseEditRecomendedProducts(cmFormData.selectedLang,formData.selectedProducts);

    useEffect(() => {

        const GetSelProducts = async () => {
            const settings = GetSettings(showSettingsElement.index,"recommendedProducts");
            settings.selectedProducts = await GetSelectedProducts(settings.selectedProducts);
            SetCompleteFormData(settings);
        }

        if(showSettingsElement)
            GetSelProducts();

    },[showSettingsElement])

    useEffect(() => {

        var selectedProducts = [];
        for(let val of formData.selectedProducts)
        {
            selectedProducts.push(val.productID);
        }

        SetSettings(showSettingsElement.index,"selectedProducts",selectedProducts,"recommendedProducts");

    },[formData.selectedProducts])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"recommendedProducts");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"recommendedProducts");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <RecommendedProductsResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <RecommendedProductsResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <RecommendedProductsResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <RecommendedProductsResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <RecommendedProductsResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <RecommendedProductsResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="form-group">
                <InputCard
                    className = "form-group"
                    title = {t("Products")}
                    icon = {productIcon}
                    noBodyPadding = {true}
                    headerChildren = {
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddProducts(true)
                            }}
                            className="btn-primary narrow"
                        >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                    }
                >   
                    {error ? 
                        <Error text={error} />
                    :
                        <List 
                            headerData = {headerData} 
                            headerClassName = {"no-top-border"}
                            contentData = {content}
                            isSortable = {false}
                            loading = {loading}
                            replaceDotsImg = {cancelIcon}
                            ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedProducts","productID",[data.rowID])}
                        />
                    }
                
                </InputCard>
                {showAddProducts ? 
                    <ChooseProducts 
                        formData = {formData}
                        SetShowAddProducts = {SetShowAddProducts}
                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                    />
                :null}
            </div>
           
            <div className="form-group">
                <label>{t("ClassName")}</label>
                <Input 
                    type = "text"
                    name = "className"
                    value = {formData.className}
                    OnChange = {(e) => SetS(e)}
                />
            </div>
                
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default RecommendedProductsSettings;