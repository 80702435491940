import Button from "../../../GlobalComponents/Button";

const Sidebar = ({isOpen,children,headerTitle,headerIcon,contentClassName,OnCloseSidebar}) => {

    var className  = "cm-sidebar"
    if(isOpen)
        className += " open";

    return(
        <>
            <div className={className}>
                {headerTitle ? 
                    <div className="cm-sidebar-header">
                        {headerIcon ?
                            <img alt="" src={headerIcon} />
                        :null}
                        <h1>{headerTitle}</h1>
                        <Button 
                            className={"btn-brown ml-auto"}
                            OnClick={() => OnCloseSidebar()}
                        >X</Button>
                    </div>
                :null}
                <div 
                    className={"cm-sidebar-content" + (contentClassName ? " " + contentClassName : "")}
                >
                    {children}
                </div>
            </div>

            {isOpen ?
                <div onClick={() => OnCloseSidebar()} className="cm-all-overlay"></div>
            :null}
        </>
    )
}

export default Sidebar;