const isDev = () => {
    try {
        return process.env.NODE_ENV === "development";
    } catch {
        return false;
    }
};


let graphqlServerUrl     = "https://server.sedacky-nabytek.cz/graphql";
let serverUrl            = "https://server.sedacky-nabytek.cz";
let webUrl               = "https://www.sedacky-nabytek.cz";

if (isDev()) {
    graphqlServerUrl     = "http://localhost:8900/graphql";
    serverUrl            = "http://localhost:8900";
    webUrl               = "http://localhost:8901";
}

export const GRAPHQL_SERVER_URL             = graphqlServerUrl;
export const SERVER_URL                     = serverUrl;
export const WEB_URL                        = webUrl;
export const NOTIFI_TIME_VISIBLE            = 6000;
export const MAX_IMAGE_FILE_SIZE_TO_UPLOAD  = 10000000;
export const MAX_VIDEO_FILE_SIZE_TO_UPLOAD  = 10000000;
export const TINY_MCE_API_KEY               = 'jz3p06tpgpxdb8xvx6lr5c3anw7gg9y1rdnmhl2i74mir8pe';
export const TINY_MCE_TOOLBAR_BASIC         = "bold italic forecolor fontSize link bullist numlist alignleft aligncenter alignright alignjustify removeformat code";

export const TINYMCE_IMAGE_URL                  = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL                   = "Public/Files/TinyMCE";
export const MARKETING_IMAGE_URL                = "Public/Images/Marketing";
export const ARTICLE_IMAGE_URL                  = "Public/Images/Article";
export const CONTENT_MANAGER_IMAGE_URL          = "Public/Images/ContentManager";
export const CONTENT_MANAGER_VIDEO_URL          = "Public/Videos/ContentManager";
export const CONTENT_MANAGER_VIDEO_POSTER_URL   = "Public/Videos/ContentManager/Posters";
export const NEWSLETTER_IMAGE_PATH              = "Public/Images/Newsletter";
export const REVIEW_IMAGE_URL                   = "Public/Images/Review";
export const SERVICE_IMAGE_URL                  = "Public/Images/Service";
export const LINK_IMAGE_URL                     = "Public/Images/Link";
export const COVER_IMAGE_URL                    = "Public/Images/Cover";
export const PRODUCT_IMAGE_URL                  = "Public/Images/Product";
export const PRODUCT_REVIEW_IMAGE_URL           = "Public/Images/ProductReview";
export const PRODUCT_DIMENSION_TYPE_IMAGE_URL   = "Public/Images/ProductDimensionType";
export const BANNER_IMAGE_URL                   = "Public/Images/Banner";
export const FB_PHOTO_IMAGE_URL                 = "Public/Images/FbPhoto";


