import { useState, useEffect } from "react";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from "react-i18next";

const UseEditManufacturers = (
    selectedManufacturers,
    SetFormDataSelectedItemsWithObj,
    hidePercentageIncrease
) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedManufacturers)
        {
            const contentData = GetContentData(selectedManufacturers);
            SetContent(contentData);
        }

    },[selectedManufacturers])

    var headerData = [
        {
            value: t("ManufacturerName"),
            className:"flex-1"
        }
    ];

    if(!hidePercentageIncrease)
        headerData.push({
            value: t("PercentageIncrease"),
            className:"text-center static "
        })

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            if(!hidePercentageIncrease)
                cData.data.push({
                    elm:"input",
                    type:"number",
                    param:"percentage",
                    name:"percentage",
                    append:"%",
                    OnChange: (e) => SetFormDataSelectedItemsWithObj(e,"selectedManufacturers","manufacturerID",val.manufacturerID),
                    className:"static text-center"   
                });

            cData["percentage"] = val.percentage;
            cData.rowID         = val.manufacturerID;
            cData.selected      = false;

            contentData.push(cData)
        }
        return contentData;
    }

    return{
        headerData,
        content
    }

}

export default UseEditManufacturers;