import Checkbox from "../Checkbox";
import { useTranslation } from 'react-i18next';

const ListHeader = ({data,headerClassName,hideOptions,isSortable,OnSelectAll}) => {

    const {t} = useTranslation();

    return(
        <div className={"list-header" + (isSortable ? " sortable" : "") + (headerClassName ? " " + headerClassName : "")}>
            {OnSelectAll ?
                <Checkbox 
                    name="select-all"
                    OnChange={(e) => OnSelectAll(e)}
                />  
            :null}
            {data && data.map((item,index) => {
                return(
                    <div key={index} className={item.className}>
                        {item.value}
                    </div>
                )
            })}
            {!hideOptions ?
                <div className="static smaller text-center">
                    {t("Options")}
                </div>
            :null}
        </div>
    )
}

export default ListHeader;