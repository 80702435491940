import plus from '../../../../Media/Images/Icons/plus.webp';
import { UseContentManager } from '../../Library/UseContentManager';

const SectionHelpers = ({index,addTitle}) => {

    const {AddSection} = UseContentManager();

    return(
        <>
            <div className="cm-hr"></div>
            <div 
                onClick = {() => AddSection(index)} 
                title={addTitle} 
                className="cm-add-section"
            >
                <img src={plus} />
            </div>
        </>
    )
}

export default SectionHelpers;