import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import UseGetAllProducts from "../../Library/Products/UseGetAllProducts";
import { useState } from "react";
import AddEditProduct from "./AddEditProduct";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { useApolloClient } from "@apollo/client";
import UseDeleteProducts from "../../Library/Products/UseDeleteProducts";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import ContentFilterSidebar from "../../../../GlobalComponents/ContentFilterSidebar";
import ProductFilter from "./ProductFilter";

const Products = () => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedProductID, SetSelectedProductID] = useState(null);
    const [showFilterSidebar,SetShowFilterSidebar] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedProductIDs,
        allLinks,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        FilterProducts
    } = UseGetAllProducts(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteProducts
    } = UseDeleteProducts(selectedProductIDs,async (d) => {
        await client.refetchQueries({
            include: ["AllProducts"],
        });
    });
    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ProductList")}
                        OnFilterClick = {() => SetShowFilterSidebar(true)}
                        OnAddButtonClick = {() => SetSelectedProductID(0)}
                        OnDeleteButtonClick = {() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (productID) => SetSelectedProductID(productID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                    
                    <ContentFilterSidebar
                        isOpen = {showFilterSidebar}
                        OnClose = {() => SetShowFilterSidebar(false)}
                    >
                        <ProductFilter
                            allLinks = {allLinks}
                            OnFilter = {(filterData) => FilterProducts(filterData)}
                        />
                    </ContentFilterSidebar>
                    
                </>
            }

            {selectedProductID || selectedProductID == 0 ?
                <AddEditProduct
                    selectedProductID = {selectedProductID}
                    SetSelectedProductID = {SetSelectedProductID}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteProducts")} 
                    CallBack={DeleteProducts} 
                />
            :null}
        </>
    )

}

export default Products;