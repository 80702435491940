const SelectBoxItems = ({items,nameParam,subParam,photoParam,photoUrl,OnSelect}) => {
    return(
        <ul>
            {items && items.map((item,index) => (
                <li key = {index}>
                    
                    <span onClick={() => OnSelect(item)}>
                        {photoParam && photoUrl ?
                            <img src = {photoUrl + item[photoParam]} />
                        :null}
                        {item[nameParam]}
                    </span>

                    {item[subParam] ?
                        <SelectBoxItems 
                            items = {item[subParam]} 
                            nameParam = {nameParam} 
                            subParam  = {subParam}
                            OnSelect = {OnSelect} 
                            photoParam = {photoParam}
                            photoUrl = {photoUrl}
                        />
                    :null}
                </li>
            ))}
        </ul>
    )
}

export default SelectBoxItems;