const ListPagging = ({
    GoToPage,
    totalContentLength, 
    paggingOffset,
    paggingLimit,
    dataLength
}) => {

    var lastNumber = Math.ceil(totalContentLength / paggingLimit);
    var currentNumber = Math.ceil(paggingOffset / paggingLimit) + 1;
    var loadMorePages = Math.ceil(dataLength / paggingLimit);

    var selectedNumbers = [];
    var middleNumbers = [];
    var startNumber = 1;

    if(currentNumber >= 3)
    {
        if(lastNumber - currentNumber < 3)
        {
            if(lastNumber - 4 > 0)
                startNumber = lastNumber - 4
        }else
            startNumber = currentNumber - 2
    }

    const endNumber = (lastNumber > startNumber + 4 ? startNumber + 4 : lastNumber);

    for(let i = startNumber; i <= endNumber; i++)
    {
        middleNumbers.push(i);
    }

    if(loadMorePages > 1)
    {
        for(let i = currentNumber; i < currentNumber + loadMorePages; i++)
        {
            selectedNumbers.push(i)
        }
    }
    else 
        selectedNumbers.push(currentNumber)

    return(
        <div className="pagging">

            {middleNumbers[0] > 1 ?
                <>
                    <div 
                        className = {selectedNumbers.indexOf(1) != -1 ? "selected" : ""} 
                        onClick = {() => GoToPage(1)}
                    >1</div>
                    <div className="cursor-default">...</div>
                </>
            :null}

            {middleNumbers.length > 1 && middleNumbers.map((item,index) => (
                <div 
                    key={index}
                    className = {selectedNumbers.indexOf(item) != -1 ? "selected" : ""} 
                    onClick = {() => GoToPage(item)}
                >{item}</div>
            ))}
            
            {middleNumbers[middleNumbers.length - 1] != lastNumber ?
                <>
                    <div className="cursor-default">...</div>
                    <div 
                        className = {selectedNumbers.indexOf(lastNumber) != -1 ? "selected" : ""} 
                        onClick = {() => GoToPage(lastNumber)}
                    >{lastNumber}</div>
                </>
            :null}

        </div>
    )
}

export default ListPagging;