import { useQuery } from "@apollo/client";

const UseGetPhotos = (query,paramID,paramIDValue) => {

    var variables = {};
    if(paramID && paramIDValue)
        variables[paramID] = paramIDValue;

    const {data,loading,error} = useQuery(query,{
        variables,
        fetchPolicy:"network-only"
    })

    return{
        data,
        loading,
        error
    }
}

export default UseGetPhotos;