import { MAX_IMAGE_FILE_SIZE_TO_UPLOAD } from "../Config";

const IMAGE_MIMES = [
    {
      mime: 'image/png',
      pattern: [0x89, 0x50, 0x4e, 0x47]
    },
    {
      mime: 'image/jpeg',
      pattern: [0xff, 0xd8, 0xff]
    },
    {
      mime: 'image/gif',
      pattern: [0x47, 0x49, 0x46, 0x38]
    },
    {
      mime: 'image/webp',
      pattern: [0x52, 0x49, 0x46, 0x46, undefined, undefined, undefined, undefined, 0x57, 0x45, 0x42, 0x50, 0x56, 0x50],
    }
];

export const IsMime = (bytes, mime) => {
    return mime.pattern.every((p, i) => !p || bytes[i] === p);
}
export const ValidateImageMimeType = (file) => {

    return new Promise((resolve,rejected) => {

        const numBytesNeeded = Math.max(...IMAGE_MIMES.map(m => m.pattern.length));
        const blob = file.slice(0, numBytesNeeded); // Read the needed bytes of the file
    
        const fileReader = new FileReader();
    
        fileReader.onloadend = e => {
            if (!e || !fileReader.result) return;
        
            const bytes = new Uint8Array(fileReader.result);
        
            const valid = IMAGE_MIMES.some(mime => IsMime(bytes, mime));
        
            resolve(valid);
        };
    
        fileReader.readAsArrayBuffer(blob);

    });
}

export const ValidateSize = (file) => {
  return file.size <= MAX_IMAGE_FILE_SIZE_TO_UPLOAD;
}

