import gql from 'graphql-tag';

export const UPLOAD_IMAGES = gql`
    mutation UploadContentManagerImages(
        $files:[Upload],
    ){
        UploadContentManagerImages(
            files:$files
        ){
            contentManagerImageID
            name
        }
    }
`;

export const GET_ALL_IMAGES = gql`
    query AllContentManagerImages{
        AllContentManagerImages{
            contentManagerImageID  
            name
        }
    }
`;

export const DELETE_IMAGES = gql`
    mutation RemoveContentManagerImages($contentManagerImageIDs:[ID]) {
        RemoveContentManagerImages(contentManagerImageIDs:$contentManagerImageIDs)
    }
`;



export const GET_ALL_VIDEOS = gql`
    query AllContentManagerVideos{
        AllContentManagerVideos{
            contentManagerVideoID  
            name
            poster
        }
    }
`;

export const DELETE_VIDEOS = gql`
    mutation RemoveContentManagerVideos($contentManagerVideoIDs:[ID]) {
        RemoveContentManagerVideos(contentManagerVideoIDs:$contentManagerVideoIDs)
    }
`;

export const UPLOAD_VIDEO = gql`
    mutation UploadContentManagerVideo(
        $videoFile:Upload,
        $posterFile:Upload
    ){
        UploadContentManagerVideo(
            videoFile:$videoFile,
            posterFile:$posterFile
        ){
            contentManagerVideoID
            name
            poster
        }
    }
`;