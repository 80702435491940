import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import Input from '../../../../GlobalComponents/Input';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import category from '../../../../Media/Images/Icons/category.webp';
import UseAddEditParameter from '../../Library/Parameters/UseAddEditParameter';
import UseGetParameter from '../../Library/Parameters/UseGetParameter';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import InputCard from '../../../../GlobalComponents/InputCard';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import { useApolloClient } from '@apollo/client';
import NoItems from '../../../../GlobalComponents/NoItems';
import cancelIcon from '../../../../Media/Images/Icons/cancel.webp';
import RadioButton from '../../../../GlobalComponents/RadioButton';
import ColorInput from '../../../../GlobalComponents/ColorInput';
import Select from '../../../../GlobalComponents/Select';

Modal.setAppElement('#root');

const AddEditParameter = ({selectedParameterID,SetSelectedParameterID,OnAddEditSuccess}) => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();
    const {
        loading,
        error,
        formData,
        AddEditParameter,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        AddValue,
        SetValue,
        RemoveValue
    } = UseAddEditParameter(selectedParameterID,user.lang,(d) => OnAddEditSuccess(d));

    const {
        allLanguageMutations,
        allFilterOperationTypes,
        loading:getLoading,
        error:getError
    } = UseGetParameter(selectedParameterID,user.lang,(d) => {
        InitFormData(d);
    });

    const notDeletedValues = formData.values.filter((item) => !item.isDeleted);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedParameterID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedParameterID === 0 ? t("AddParameter") : t("EditParameter"))}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedParameterID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                {err ?
                    <Error className = "no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                <div className="row">
                                    <div className="col-33">
                                                
                                        <div className="form-group">
                                            <label>*{t("ParameterName")} ({formData.selectedLang})</label>
                                            <Input
                                                name="name"
                                                value={GetFormLangValue("name")}
                                                OnChange={(e) => SetFormLangData(e)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-33">
                                        
                                        <div className="form-group">
                                            <label>{t("Unit")} ({formData.selectedLang})</label>
                                            <Input
                                                name="unit"
                                                value={GetFormLangValue("unit")}
                                                OnChange={(e) => SetFormLangData(e)}
                                            />
                                        </div>
                                    </div>
    
                                    <div className="col-33">
                                        <div className="form-group">
                                            
                                            <label>{t("IsColor")}</label>
                                            <div className="radio-container">
                                                <RadioButton
                                                    text="Ano"
                                                    name="isColor"
                                                    id="isColor-1"
                                                    value={1}
                                                    checked = {formData.isColor === 1 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                                <RadioButton
                                                    text="Ne"
                                                    name="isColor"
                                                    id="isColor-2"
                                                    value={0}
                                                    checked = {formData.isColor === 0 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {notDeletedValues && notDeletedValues.length > 0 ?
                                        <div className="col-50">
                                            <div className="form-group">
                                                <label>{t("FilterOperationType")}</label>
                                                <Select
                                                    name="filterOperationTypeID"
                                                    value={formData.filterOperationTypeID}
                                                    OnChange={(e) => SetFormData(e)}
                                                >
                                                    {allFilterOperationTypes && allFilterOperationTypes.map((item) => (
                                                        <option 
                                                            key = {item.filterOperationTypeID}
                                                            value = {item.filterOperationTypeID}
                                                        >{item.name}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    :null}
                                </div>

                                <InputCard
                                    title = {t("Values")}
                                    icon = {category}
                                    headerInfoContent = {[
                                        t("ParameterValueInfo1"),t("ParameterValueInfo2")     
                                    ]}
                                    headerChildren = {
                                        <Button 
                                            OnClick = {() => AddValue(allLanguageMutations)}
                                            className="btn-primary narrow"
                                        >
                                            {t("AddValue")}
                                        </Button>
                                    }
                                >   
                                    {notDeletedValues && notDeletedValues.length > 0 ?
                                        <div className="row">
                                            {notDeletedValues.map((item,index) => {
                                                
                                                var value = "";
                                                for(let val of item.langs)
                                                {
                                                    if(val.lang == formData.selectedLang)
                                                    {
                                                        value = val.value;
                                                    }
                                                }

                                                return (

                                                    <div key = {index} className="col-33">
                                                        {formData.isColor ?
                                                            <div className="form-group">
                                                                <label>{t("Color")} ({formData.selectedLang})</label>
                                                                <ColorInput
                                                                    name="color"
                                                                    defaultColor = "#5fd733"
                                                                    value={value}
                                                                    colorCode={item.colorCode}
                                                                    OnChange={(value,color) => SetValue(value,color,index)}
                                                                    append = {
                                                                        <img 
                                                                            onClick = {() => RemoveValue(index)}
                                                                            className = "cursor-pointer" 
                                                                            src={cancelIcon} 
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        :
                                                            <div className="form-group">
                                                                <label>{t("Value")} ({formData.selectedLang})</label>
                                                                <Input
                                                                    name="value"
                                                                    value={value}
                                                                    OnChange={(e) => SetValue(e.target.value,"",index)}
                                                                    append = {
                                                                        <img 
                                                                            onClick = {() => RemoveValue(index)}
                                                                            className = "cursor-pointer" 
                                                                            src={cancelIcon} 
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    :
                                        <NoItems text = {t("NoValuesYet")} />
                                    }
  
                                </InputCard>
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditParameter()}
                >
                    {(selectedParameterID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditParameter;