import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditProduct from '../../Library/Products/UseAddEditProduct';
import UseGetProduct from '../../Library/Products/UseGetProduct';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import BasicInformations from './BasicInformations';
import ProductPhotos from './ProductPhotos';
import SearchTags from './SearchTags';
import RelatedProducts from './RelatedProducts';
import ExcludedCovers from './ExcludedCovers';
import Variants from './Variants';

Modal.setAppElement('#root');

const AddEditProduct = ({selectedProductID,SetSelectedProductID}) => {

    const [selectedTab, SetSelectedTab] = useState(1);

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();
    const {
        loading,
        error,
        formData,
        AddEditProduct,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        GeneratePriceAfterDiscount,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsPriority
    } = UseAddEditProduct(selectedProductID,user.lang,(d,continueToPhotos,stayHere) => OnAddEditSuccess(d,continueToPhotos,stayHere));

    const {
        allLanguageMutations,
        allManufacturers, 
        allVats,
        loading:getLoading,
        error:getError,
    } = UseGetProduct(selectedProductID,user.lang,(d) => {
        InitFormData(d)
    });

    const OnAddEditSuccess = async (d,continueToPhotos,stayHere) => {

        if(continueToPhotos)
        {
            SetSelectedProductID(d.productID);
            SetSelectedTab(2);
        }
        else if(!stayHere)
            SetSelectedProductID(null);
        else if(stayHere)
        {

            var newCat = [];
            for(let val of formData.selectedCategories)
            {
                if(!val.deleted)
                    newCat.push(val); 
            }
            
            SetFormData("selectedCategories",newCat);
            
        }

        await client.refetchQueries({
            include: ["AllProducts"],
        });
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedProductID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedProductID === 0 ? t("AddProduct") : t("EditProduct"))}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedProductID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                <ul className="tabs">
                    <li 
                        className={(selectedTab == 1 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(1)}
                    >{t("BasicInformations")}</li>
                    <li 
                        className={(selectedTab == 2 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(2)}
                    >{t("Photos")}</li>
                    <li 
                        className={(selectedTab == 3 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(3)}
                    >{t("Variants")}</li>
                    <li 
                        className={(selectedTab == 4 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(4)}
                    >{t("Covers")}</li>
                    <li 
                        className={(selectedTab == 5 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(5)}
                    >{t("RelatedProducts")}</li>
                    <li 
                        className={(selectedTab == 6 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(6)}
                    >{t("Sarch")}</li>
                </ul>

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                {selectedTab == 1 ?
                                    <BasicInformations
                                        allManufacturers = {allManufacturers}
                                        selectedProductID = {selectedProductID}
                                        allVats = {allVats}
                                        formData = {formData}
                                        SetFormLangData = {SetFormLangData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormData = {SetFormData}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                        GeneratePriceAfterDiscount = {GeneratePriceAfterDiscount}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                        SetBooleanFormDataSelectedItemsWithObj = {SetBooleanFormDataSelectedItemsWithObj}
                                    />
                                :null}

                                {selectedTab == 2 ?
                                    <ProductPhotos
                                        productPhotos = {formData.productPhotos}
                                        selectedProductID = {selectedProductID}
                                        SetFormData = {SetFormData}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                        SetBooleanFormDataSelectedItemsWithObj = {SetBooleanFormDataSelectedItemsWithObj}
                                        SetFormDataSelectedItemsPriority = {SetFormDataSelectedItemsPriority}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                    />
                                :null}

                                {selectedTab == 3 ?
                                    <Variants
                                        lang = {user.lang}
                                        selectedProductID = {selectedProductID}
                                        excludedProductCovers = {formData.selectedExcludedCovers}
                                    />
                                :null}

                                {selectedTab == 4 ?
                                    <ExcludedCovers
                                        formData = {formData}
                                        lang = {user.lang}
                                        selectedProductID = {selectedProductID}
                                        SetFormData = {SetFormData}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                    />
                                :null}

                                {selectedTab == 5 ?
                                    <RelatedProducts
                                        lang = {user.lang}
                                        selectedProductID = {selectedProductID}
                                    />
                                :null}

                                {selectedTab == 6 ?
                                    <SearchTags
                                        lang = {formData.selectedLang}
                                        selectedProductID = {selectedProductID}
                                    />
                                :null}
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                
                {selectedProductID === 0 ?
                    <>
                        {selectedTab == 1 ?
                            <div className="row">
                                <div className="col-50">
                                    <Button
                                        className={"btn-primary w-100"}
                                        OnClick={() => AddEditProduct(false,false)}
                                    >
                                        {t("Add")}
                                    </Button>
                                </div>
                                <div className="col-50">
                                    <Button
                                        className={"btn-primary w-100"}
                                        OnClick={() => AddEditProduct(true,false)}
                                    >
                                        {t("AddAndContinueToPhotos")}
                                    </Button>
                                </div>
                            </div>
                        :null}
                    </>
                :
                <div className="row">
                    <div className="col-50">
                        <Button
                            className={"btn-primary w-100"}
                            OnClick={() => AddEditProduct(false,false)}
                        >
                            {t("Edit")}
                        </Button>
                    </div>
                    <div className="col-50">
                        <Button
                            className={"btn-primary w-100"}
                            OnClick={() => AddEditProduct(false,true)}
                        >
                            {t("EditAndStay")}
                        </Button>
                    </div>
                </div>
                }
                
            </div>

        </Modal>
    )
}

export default AddEditProduct;