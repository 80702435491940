import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_COVER } from "../../Queries/covers";
import { useRef } from "react";

const UseAddEditCover = (selectedCoverID,lang,OnCompleted) => {

    var continueToPhotos = useRef(false);
    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    } = UseFormHandle({
        selectedLang:lang,
        coverMaterialTypeID:0,
        privateName:"",
        shortcut:"",
        langs:[],
        selectedManufacturers:[],
        coverPhotos: [],
        selectedExcludedProducts:[]
    });

    const [AddEditCoverMutation,{error,loading}] = useMutation(ADD_EDIT_COVER,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditCover,continueToPhotos.current);

            const notifyText = (selectedCoverID != 0 ? t("SuccessfullyUpdated") : t("NewCoverIsInTheWorld"));

            AddNotification(notifyText,true);

            continueToPhotos.current = false;

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditCover = (conToPhotos) => {

        continueToPhotos.current = conToPhotos;

        var check = true;
        for(let val of formData.langs)
        {
            if(val.name === "" && val.lang === lang)
                check = false;
        }

        var checkMainPhoto = false;
        if(formData.coverPhotos.length == 0)
            checkMainPhoto = true;
        else
            for(let cp of formData.coverPhotos)
            {
                if(cp.isMain)
                    checkMainPhoto = true;
            }

        if(check)
        {
            if(formData.shortcut != "")
            {
                if(formData.selectedManufacturers.length > 0)
                {
                    if(checkMainPhoto)
                    {
                        let langs = [];
                        for(const lang of formData.langs)
                        {
                            langs.push({
                                name:               lang.name, 
                                description:        lang.description,             
                                lang:               lang.lang
                            });
                        }

                        for(let val of formData.selectedManufacturers)
                        {
                            val.percentage = parseFloat(val.percentage);
                            delete(val.name);
                        }

                        for(let val of formData.coverPhotos)
                        {
                            val.isMain = val.isMain ? 1 : 0;
                            delete(val.name);
                            delete(val.__typename);
                        }

                        AddEditCoverMutation({
                            variables:{
                                coverID:                selectedCoverID,
                                coverMaterialTypeID:    formData.coverMaterialTypeID,
                                privateName:            formData.privateName,
                                shortcut:               formData.shortcut,
                                langs:                  langs,
                                selectedManufacturers:  formData.selectedManufacturers,
                                coverPhotos:            formData.coverPhotos,
                                excludedProducts:       formData.selectedExcludedProducts
                            }
                        })
                    }
                    else
                        AddNotification(t("ItIsNecessaryToChooseMainPhoto"),false);
                }
                else
                    AddNotification(t("ItIsNecessaryToChooseManufacturer"),false);
            }
            else
                AddNotification(t("ShurtcutIsImportant"),false);
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditCover,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    }

}

export default UseAddEditCover;