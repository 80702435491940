export const GetApolloErrorText = (error) => {

    var newError = JSON.stringify(error);
    newError = JSON.parse(newError);
    
    if(newError.graphQLErrors && newError.graphQLErrors[0])
        return newError.graphQLErrors[0].message;
    if(newError.networkError && newError.networkError.result.errors)
        return newError.networkError.result.errors[0].message;
    else
        return error.message;

}

export const ValidateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const OpenImage = (e,CallBack) => {
    var photo = e.target.files[0];        
    var reader = new FileReader();
    reader.onload = () => {
        var dataURL = reader.result;
        CallBack(photo,dataURL);
    };
    reader.readAsDataURL(photo);
}

export const CompleteLangsArray = (langsFromDb,allActiveLanguages,emptyData,uploadParamName,uploadParamNameUrl) => {

    let langs = [...langsFromDb];
    for(const l of allActiveLanguages)
    {
        let langSet = false;
        for(let i = 0; i < langs.length;i++)
        {
            if(l.suffix == langs[i].lang)
            {
                if(uploadParamName && emptyData[uploadParamName] === null)
                {
                    var upn = langs[i][uploadParamName];
                    langs[i] = {...langs[i],[uploadParamName] : null,[uploadParamName + "Show"] : (upn ? uploadParamNameUrl + upn : "")};
                }

                langSet = true;
                break;
            }
        }

        if(!langSet)
        {
            var dataToPush = {...emptyData};
            dataToPush.lang = l.suffix;
            langs.push(dataToPush);
        }
    }
    
    return langs;
}

export const GetResponsiveDimensions = (size) => {

    var dimensions = "";
    switch(size)
    {
        case "XL" : dimensions = "1400px a větší"; break;
        case "LG" : dimensions = "1200px - 1399px"; break;
        case "MD" : dimensions = "992px - 1199px"; break;
        case "SM" : dimensions = "768px - 991px"; break;
        case "XS" : dimensions = "576px - 767px"; break;
        case "XXS" : dimensions = "menší než 576px"; break;
    }

    return dimensions;
}

export const GetPriceString = (price,lang,fraction = 0) => {

    switch(lang)
    {
        case "cz": return new Intl.NumberFormat('cz', { style: 'currency', currency: 'CZK', maximumFractionDigits : fraction }).format(price);
        case "sk": return new Intl.NumberFormat('sk', { style: 'currency', currency: 'EUR', maximumFractionDigits : fraction }).format(price);
    }
}