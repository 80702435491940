import Modal from 'react-modal';
import { UseContentManager } from '../../Library/UseContentManager';
import ImagesList from './ImagesList';
import VideosList from './VideosList';

Modal.setAppElement('#root');

const FileManager = () => {

    const {openFileManagerData,CloseFileManager} = UseContentManager();

    return(
        <Modal
            isOpen={openFileManagerData.type !== 0}
            onRequestClose={() => CloseFileManager()}
            overlayClassName={"modal-overlay"}
            className={"modal-content lg cm-file-manager"}
        >
            {openFileManagerData.type === 1 ?
                <ImagesList />
            :null}

            {openFileManagerData.type === 2 ?
                <VideosList />
            :null}
            

        </Modal>
    )
}

export default FileManager;