import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import Input from '../../../../GlobalComponents/Input';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddRelatedProduct from '../../Library/Products/UseAddRelatedProduct';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import List from '../../../../GlobalComponents/List/List';
import UseGetAllProducts from '../../Library/Products/UseGetAllProducts';
import ProductFilter from './ProductFilter';
import { useState } from 'react';
import ModalFilterPanel from '../../../../GlobalComponents/Modal/ModalFIlterPanel';
import RadioButton from '../../../../GlobalComponents/RadioButton';
import { useApolloClient } from '@apollo/client';

Modal.setAppElement('#root');

const AddRelatedProduct = ({productID,ShowAddRelatedProducts}) => {
    
    const {user} = UseAuth();
    const {t} = useTranslation();
    const client = useApolloClient();

    const [showFilter,SetShowFilter] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading: getLoading,
        error:getError,
        headerData,
        selectedProductIDs,
        allLinks,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        FilterProducts
    } = UseGetAllProducts(user.lang);

    const {
        loading,
        error,
        formData,
        AddRelatedProduct,
        SetFormData
    } = UseAddRelatedProduct(productID,selectedProductIDs,async (d) => {
        await client.refetchQueries({
            include: ["AllAdminRelatedProducts"],
        });
        ShowAddRelatedProducts(false);
    });

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => ShowAddRelatedProducts(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("AddRelatedProducts")}
                OnClose ={() => ShowAddRelatedProducts(false)}
                children={
                    <Button 
                        OnClick = {() => SetShowFilter(!showFilter)}
                        className="btn-blue"
                    >{t("Filter")}</Button>
                }
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {showFilter ?
                            <ModalFilterPanel
                                OnClose = {() => SetShowFilter(false)}
                            >
                                <ProductFilter
                                    allLinks = {allLinks}
                                    OnFilter = {(filterData) => FilterProducts(filterData)}
                                />
                            </ModalFilterPanel>
                        :null}

                        <div className="form-group">
                            
                            <label>{t("AddOnBothSides")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text="Ano"
                                    name="onBothSides"
                                    id="onBothSides-1"
                                    value={1}
                                    checked = {formData.onBothSides === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text="Ne"
                                    name="onBothSides"
                                    id="onBothSides-2"
                                    value={0}
                                    checked = {formData.onBothSides === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>

                        <div className="modal-body-no-side-padding">
                            <List 
                                headerData = {headerData} 
                                contentData = {content}
                                totalContentLength = {totalContentLength}
                                paggingOffset = {paggingOffset}
                                paggingLimit = {paggingLimit}
                                FetchMore = {FetchMore}
                                GoToPage = {GoToPage}
                                isSortable = {false}
                                loading = {loading || getLoading}
                                hideOptions = {true}
                                OnSelectAll = {(e) => SelectAll(e)}
                                options = {{
                                    OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                }}
                            />
                        </div> 
                        
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddRelatedProduct()}
                >
                    {t("Add")}
                </Button>
            </div>

        </Modal>
    )
}

export default AddRelatedProduct;