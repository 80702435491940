import { useTranslation } from 'react-i18next';
import Button from '../Button';

const ModalFilterPanel = ({children,OnClose}) => {

    const {t} = useTranslation();

    return(
        <div className="modal-filter-panel">
            <div className="modal-filter-panel-header">
                {t("Filter")}
                <div className="ml-auto">
                    <Button 
                        className={"btn-brown narrow"}
                        OnClick={() => OnClose()}
                    >X</Button>
                </div>

            </div>
            <div className="modal-filter-panel-body">
                {children}
            </div> 
        </div>
    )
}

export default ModalFilterPanel;