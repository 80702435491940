import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_PRODUCT_SEACRH_TAG } from "../../Queries/products";

const UseAddEditProductSearchTag = (selectedProductSearchTagID,lang,productID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData} = UseFormHandle({
        name:""
    });

    const [AddEditProductSearchTagMutation,{error,loading}] = useMutation(ADD_EDIT_PRODUCT_SEACRH_TAG,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditProductSearchTag);

            const notifyText = (selectedProductSearchTagID != 0 ? t("SuccessfullyUpdated") : t("NewProductSearchTagIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditProductSearchTag = () => {

        if(formData.name != "")
        {
            AddEditProductSearchTagMutation({
                variables:{
                    productID:productID,
                    lang:lang,
                    name: formData.name,
                }
            })
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditProductSearchTag,
        SetFormData, 
        InitFormData
    }
}

export default UseAddEditProductSearchTag;