import Modal from 'react-modal';
import { useQuery } from '@apollo/client';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import Checkbox from '../../../../GlobalComponents/Checkbox';
import { useTranslation } from 'react-i18next';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import Loading from '../../../../GlobalComponents/Loading';
import { GET_LABELS } from '../../Queries/labels';
import { UseAuth } from '../../../Auth/Library/UseAuth';

const ChooseLabels = ({
    formData,
    SetShowAddLabels,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    var lang = user ? user.lang : "";

    const {data,loading,error} = useQuery(GET_LABELS,{
        variables:{
            lang: lang,
            limit:1000000,
            offset:0
        },
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddLabels(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("ChooseLabels")}
                OnClose ={() => SetShowAddLabels(false)}
            />

            <div className="modal-body">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        <>
                            {data.AllLabels && data.AllLabels.length > 0 ?
                                
                                <div className='row'>

                                    {data.AllLabels.map((item) => {

                                        var check = false;
                                        for(let val of formData.selectedLabels)
                                        {
                                            if(val.labelID == item.labelID)
                                                check = true;
                                        }

                                        return(
                                            <div key={item.labelID} className="col-20">
                                                <div className="form-group">
                                                    <Checkbox 
                                                        name = "labels"
                                                        id = {"l-" + item.labelID}
                                                        checked = {check}
                                                        OnChange={() => FillFormSelectedItemsWithObj("selectedLabels","labelID",{
                                                            name:item.name,
                                                            labelID:item.labelID,
                                                            useDates:0,
                                                            dateFrom:"",
                                                            dateTo:""
                                                        })}
                                                        text = {item.name}
                                                    /> 
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                </div> 
                            :null}
                        </>
                    )
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddLabels(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseLabels;