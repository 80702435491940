import RadioButton from "../../../../GlobalComponents/RadioButton";
import Button from "../../../../GlobalComponents/Button";
import Checkbox from "../../../../GlobalComponents/Checkbox";
import NoItems from "../../../../GlobalComponents/NoItems";
import InputCard from "../../../../GlobalComponents/InputCard";
import photosIcon from '../../../../Media/Images/Icons/image.webp';
import FileInput from "../../../../GlobalComponents/FileInput";
import { useTranslation } from 'react-i18next';
import UseUploadPhotos from "../../../../GlobalHooks/UseUploadPhotos";
import UseDeletePhotos from "../../../../GlobalHooks/UseDeletePhotos";
import { PRODUCT_IMAGE_URL, SERVER_URL } from "../../../../Config";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import Loading from "../../../../GlobalComponents/Loading";
import { UPLOAD_PRODUCT_PHOTOS, DELETE_PRODUCT_PHOTOS } from "../../Queries/products";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import Select from "../../../../GlobalComponents/Select";

const ProductPhotos = ({
    productPhotos,
    selectedProductID,
    SetFormData,
    SetFormDataSelectedItemsWithObj,
    SetBooleanFormDataSelectedItemsWithObj,
    RemoveFormDataSelectedItems,
    SetFormDataSelectedItemsPriority
}) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const {loading,UploadPhotos} = UseUploadPhotos(UPLOAD_PRODUCT_PHOTOS,"productID",selectedProductID,(d) => {
        var arr = [...productPhotos,...d.UploadProductPhotos];
        SetFormData("productPhotos",arr);
    });

    const {
        selectedItems,
        showDeleteNotification, 
        SelectPhoto, 
        DeletePhotos,
        ShowDeleteNotification
    } = UseDeletePhotos(DELETE_PRODUCT_PHOTOS,"productPhotoIDs",(d) => {
        const IDs = d.DeleteProductPhotos.split(",");
        RemoveFormDataSelectedItems("productPhotos","productPhotoID",IDs)
    });

    const RemovePhotos = () => {

        var checkIsMain = false;
        for(let si of selectedItems)
        {
            for(let cp of productPhotos)
            {
                if(cp.isMain && si == cp.productPhotoID)
                    checkIsMain = true;
            }
        }
        
        if(!checkIsMain)
            ShowDeleteNotification(true)
        else
            AddNotification(t("YouAreDeletingMainPhoto"),false);
    }
    
    var priorityNumbers = [];
    for(let i in productPhotos) 
        priorityNumbers.push(parseInt(i)+1);

    return(
        <div>
            {selectedProductID && selectedProductID != 0 ?
                <InputCard
                    title = {t("Photos") + " - doporučený rozměr: 1425 x 950 px"}
                    icon = {photosIcon}
                    headerChildren = {
                        <>
                            <FileInput
                                showOnlyButton = {true}
                                OnChange = {(e) => UploadPhotos(e.target.files)}
                                buttonTitle = {t("UploadImages")}
                                btnClassName = "narrow"
                                inputClassName = "narrow"
                            />
                        
                            <Button
                                className="btn-brown narrow"
                                OnClick = {() => RemovePhotos()}
                            >
                            {t("DeleteSelected")}     
                            </Button>
                            
                        </>
                    }
                > 
                    {loading ?
                        <Loading />    
                    :
                        (productPhotos && productPhotos.length > 0 ?
                            <div className="row">
                                {productPhotos.map((item,index) => {
                                    
                                    var check = false;
                                    for(let val of selectedItems)
                                    {
                                        if(val == item.productPhotoID)
                                            check = true;   
                                    }

                                    return(
                                        <div key={item.productPhotoID} className="col-25">
                                            <div className="cm-image-container form-group">
                                                <div className="select">
                                                    <Select 
                                                        value={item.priority}
                                                        name = "priority"
                                                        OnChange = {(e) => {
                                                            SetFormDataSelectedItemsPriority("productPhotos",index,parseInt(e.target.value) - 1);
                                                            SetFormDataSelectedItemsWithObj("isUpdated","productPhotos","productPhotoID",item.productPhotoID,true);
                                                        }}
                                                    >
                                                        {priorityNumbers.map((item,index) => (
                                                           <option key={index}>{item}</option> 
                                                        ))}
                                                    </Select>
                                                    <Checkbox 
                                                        text = {""}
                                                        name = "selectedProductPhoto"
                                                        checked = {check}
                                                        OnChange = {() => SelectPhoto(item.productPhotoID)}
                                                    />
                                                </div>
                                                <img 
                                                    src = {SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + selectedProductID + "/stredni_" + item.name} 
                                                    alt={item.name} 
                                                />
                                            </div>
                                            <div className="form-group">
                                                <RadioButton 
                                                    text = {t("MainPhoto")}
                                                    name = "mainPhoto"
                                                    id = {"mp - " + item.productPhotoID} 
                                                    checked = {item.isMain}
                                                    OnChange = {() => {
                                                        SetBooleanFormDataSelectedItemsWithObj("isMain","productPhotos","productPhotoID",item.productPhotoID);
                                                        SetFormDataSelectedItemsWithObj("isUpdated","productPhotos","productPhotoID",item.productPhotoID,true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <NoItems text={t("NoImagesHereYet")} />
                        )
                    }
                </InputCard>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddPhotosSaveBasicInformationsFirst")} 
                />   
            }

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo = {true} 
                    text = {t("DoYouReallyWantToDeletePhotos")} 
                    CallBack = {DeletePhotos} 
                />
            :null}

        </div>
    )
}

export default ProductPhotos;