import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import Select from "../../../../../GlobalComponents/Select";
import InputCard from "../../../../../GlobalComponents/InputCard";
import { BUTTON } from "../../../Library/elements";
import ButtonResponziveSettings from "./ButtonResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";

const ButtonSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(BUTTON.button); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"button");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"button");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"button");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <ButtonResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <ButtonResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <ButtonResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <ButtonResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <ButtonResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <ButtonResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="row">
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("Url")}</label>
                        <Input 
                            type = "text"
                            name = "url"
                            value = {formData.url}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Align")}</label>
                        <Select
                            name = {"buttonAlign"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.buttonAlign}
                        >
                            <option value={"start"}>{t("Left")}</option>
                            <option value={"center"}>{t("Center")}</option>
                            <option value={"end"}>{t("Right")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Color")}</label>
                        <Select
                            name = {"color"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.color}
                        >
                            <option value={"btn-primary"}>{t("Basic")}</option>
                            <option value={"btn-green"}>{t("Green")}</option>
                            <option value={"btn-white"}>{t("White")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Size")}</label>
                        <Select
                            name = {"size"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.size}
                        >
                            <option value={""}>{t("Small")}</option>
                            <option value={"middle"}>{t("Middle")}</option>
                            <option value={"bigger"}>{t("Big")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("DividingLine")}</label>
                        <Select
                            name = {"dividingLine"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.dividingLine}
                        >
                            <option value={"1"}>{t("Yes")}</option>
                            <option value={"0"}>{t("No")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default ButtonSettings;