import { useQuery } from "@apollo/client";
import { GET_PARAMETER } from "../../Queries/parameters";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";

const UseGetParameter = (selectedParameterID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allFilterOperationTypes, SetAllFilterOperationTypes] = useState([]);

    const {loading,error} = useQuery(GET_PARAMETER,{
        variables:{
            parameterID:selectedParameterID,
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",                    
                unit:"",                    
            }

            if(selectedParameterID != 0)
            {
                let langs = CompleteLangsArray(d.ParameterWithLangs.langs,d.AllLanguageMutations,emptyLang);
                var values = [];
                
                for(let val of d.ParameterWithLangs.values)
                {
                    var valueLangs = [];
                    for(let l of val.langs)
                    {
                        valueLangs.push({
                            lang: l.lang,
                            value: l.value,
                            parameterValueLangID: l.parameterValueLangID
                        })
                    }

                    values.push({
                        parameterValueID: val.parameterValueID,
                        colorCode: val.colorCode,
                        langs:valueLangs
                    })
                }

                CallBack({
                    selectedLang:           lang,
                    filterOperationTypeID:  d.ParameterWithLangs.filterOperationTypeID,
                    isColor:                d.ParameterWithLangs.isColor,
                    langs:                  langs,
                    values:                 values
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllFilterOperationTypes(d.AllFilterOperationTypes);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allFilterOperationTypes
    }

}

export default UseGetParameter;