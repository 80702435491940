import {useQuery} from '@apollo/client';
import { GET_PRODUCTS } from '../../Queries/products';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,PRODUCT_IMAGE_URL } from '../../../../Config';

const UseGetAllProducts = (lang) => {

    const [variables,SetVariables] = useState({
        lang,
        limit:40,
        offset:0,
        searchText:"",
        linkID:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedProductIDs,SetSelectedProductIDs] = useState([]);
    const [allLinks, SetAllLinks] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_PRODUCTS,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllProducts: [...prev.AllProducts, ...fetchMoreResult.AllProducts]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllProducts);
            SetContent(contentData);

            const allLinks = [{linkID:"0",name:t("---choose---")},...data.AllLinks]
            SetAllLinks(allLinks);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("ProductName"),
            className:"flex-1"
        },
        {
            value: t("Categories"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {
            var allLinks = "";
            if(val.links && val.links.length > 0)
            {
                for(let l of val.links)
                {
                    if(allLinks)
                        allLinks += ", ";
                    allLinks += (l.link ? l.link.name : "")
                }
            }

            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.mainPhoto ? SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + val.productID + "/mala_" + val.mainPhoto : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value:allLinks,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.productID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const FilterProducts = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
        var linkID = 0;

        if(filterData.linkID != 0)
            linkID = filterData.linkID;

        if(filterData.subLinkID != 0)
            linkID = filterData.subLinkID;

        if(filterData.subSubLinkID != 0)
            linkID = filterData.subSubLinkID;

        if(linkID != 0 || (linkID == 0 && newVariables.linkID != 0))
        {
            newVariables.linkID = linkID;
            updated = true;
        }

        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    const SelectRow = (e,productID) => {

        var checked = e.target.checked;
        var newSelectedProductIDs = [...selectedProductIDs];
        const newList = SelectProductID(content,productID,checked,newSelectedProductIDs)

        SetContent(newList);
        SetSelectedProductIDs(newSelectedProductIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedProductIDs = [];
        const newList = SelectProductID(content,"all",checked,newSelectedProductIDs)

        SetSelectedProductIDs(newSelectedProductIDs);
        SetContent(newList);
    }

    const SelectProductID = (list,productID,checked,selectedProductIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(productID == newList[i].rowID || productID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedProductIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedProductIDs.length; j++)
                    {
                        if(selectedProductIDs[j] == newList[i].rowID)
                            selectedProductIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectProductID(newList[i].subData,productID,checked,selectedProductIDs)}
        }

        return newList;
    }

    return{
        content,
        totalContentLength: data && data.AllProductsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedProductIDs,
        allLinks,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        FilterProducts
    }

}

export default UseGetAllProducts;