import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import { useState } from "react";
import AddRelatedProduct from "./AddRelatedProduct";
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import UseDeleteRelatedProducts from "../../Library/Products/UseDeleteRelatedProducts";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import UseGetAllRelatedProducts from "../../Library/Products/UseGetAllRelatedProducts";
import relationIcon from '../../../../Media/Images/Icons/relation.webp';
import InputCard from "../../../../GlobalComponents/InputCard";
import Button from "../../../../GlobalComponents/Button";
import NoItems from '../../../../GlobalComponents/NoItems';
import { useApolloClient } from '@apollo/client';

const RelatedProducts = ({selectedProductID,lang}) => {

    const {t} = useTranslation();
    const client = useApolloClient();
    const [showAddRelatedProducts, ShowAddRelatedProducts] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedRelatedProductIDs,
        SelectRow,
        SelectAll
    } = UseGetAllRelatedProducts(lang,selectedProductID);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteRelatedProducts
    } = UseDeleteRelatedProducts(selectedRelatedProductIDs,async (d) => {
        await client.refetchQueries({
            include: ["AllAdminRelatedProducts"],
        });
        ShowAddRelatedProducts(false);
    });

    return(
        <div>
            {selectedProductID && selectedProductID != 0 ?
                <>
                    {error ?
                        <Error text={GetApolloErrorText(error)} />
                    :
                        <>
                            <InputCard
                                title = {t("RelatedProducts")}
                                icon = {relationIcon}
                                noBodyPadding = {true}
                                headerChildren = {
                                    <>
                                        <Button
                                            className="btn-primary narrow"
                                            OnClick = {() => ShowAddRelatedProducts(true)}
                                        >
                                        {t("Add")}     
                                        </Button>
                                    
                                        <Button
                                            className="btn-brown narrow"
                                            OnClick = {() => ShowDeleteNotification(true)}
                                        >
                                        {t("Delete")}     
                                        </Button>
                                    </>
                                }
                            > 
                                <List 
                                    headerData = {headerData} 
                                    contentData = {content}
                                    headerClassName = {"no-top-border"}
                                    isSortable = {false}
                                    loading = {loading}
                                    OnSelectAll = {(e) => SelectAll(e)}
                                    hideOptions = {true}
                                    options = {{
                                        OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                    }}
                                />
                            </InputCard>
                        </>
                    }

                    {showAddRelatedProducts ?
                        <AddRelatedProduct
                            productID = {selectedProductID}
                            ShowAddRelatedProducts = {ShowAddRelatedProducts}
                        />
                    :null}

                    {showDeleteNotification ?
                        <ModalNotification 
                            yesNo={true} 
                            text={t("DoYouReallyWantToDeleteRelatedProducts")} 
                            CallBack={DeleteRelatedProducts} 
                        />
                    :null}

                </>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddRelatedProductSaveBasicInformationsFirst")} 
                />   
            }

        </div>
    )
}

export default RelatedProducts;