import { Fragment } from "react";
import ListRow from "./ListRow";
import Button from "../Button";
import ListPagging from "./ListPagging";
import { useTranslation } from "react-i18next";

const ListContent = ({
    data,
    options,
    hideOptions,
    level,
    totalContentLength, 
    paggingOffset,
    paggingLimit,
    FetchMore, 
    GoToPage,
    replaceDotsImg,
    ReplaceDotsOnClick
}) => {

    const {t} = useTranslation();

    return(
        <>
            {data && data.map((item,index) => {

                return(
                    <Fragment key={index} >
                        <ListRow 
                            data = {item}
                            options = {options} 
                            hideOptions = {hideOptions}
                            level = {level}
                            replaceDotsImg = {replaceDotsImg}
                            ReplaceDotsOnClick = {ReplaceDotsOnClick}
                            paggingOffset = {paggingOffset}
                            index = {index}
                        />
                        {item.subData ?
                            <ListContent 
                                data = {item.subData} 
                                options = {options} 
                                hideOptions = {hideOptions}
                                level = {level + 1}
                                replaceDotsImg = {replaceDotsImg}
                                ReplaceDotsOnClick = {ReplaceDotsOnClick}
                            />
                        :null}
                    </Fragment>       
                )
            })}
            
            {GoToPage || FetchMore ?
                <div className="list-fetch-more">
                    {GoToPage ?
                        <ListPagging 
                            GoToPage = {GoToPage} 
                            paggingOffset = {paggingOffset}
                            paggingLimit = {paggingLimit}
                            totalContentLength = {totalContentLength}
                            dataLength = {data.length}
                        />
                    :null}
                    {FetchMore && totalContentLength && totalContentLength > paggingOffset + data.length ?
                        <Button 
                            className = "btn-primary narrow"
                            OnClick = {() => FetchMore(paggingOffset + data.length)}
                        >{t("LoadMore")}</Button>
                    :null}
                </div>
            :null}
        </>                
    )
}

export default ListContent;