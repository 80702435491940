import { useTranslation } from "react-i18next";
import Button from "./Button";

const ContentFilterSidebar = ({isOpen, children,OnClose}) => {

    const {t} = useTranslation();

    return(
        <>
            <div className = {"content-filter-sidebar" + (isOpen ? " open" : "")}>
                <div className="content-filter-sidebar-header">
                    {t("Filter")}
                    <div className="ml-auto">
                        <Button 
                            className={"btn-brown narrow"}
                            OnClick={() => OnClose()}
                        >X</Button>
                    </div>

                </div>
                <div className="content-filter-sidebar-body">
                    {children}
                </div>
            </div>
            {isOpen ?
                <div 
                    onClick={() => OnClose()}
                    className = "content-filter-sidebar-overlay"
                ></div>
            :null}
        </>

    )
}

export default ContentFilterSidebar;