import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditManufacturer from "./AddEditManufacturer";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import UseDeleteManufacturers from "../../Library/Manufacturers/UseDeleteManufacturers";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import UseGetAllManufacturers from "../../Library/Manufacturers/UseGetAllManufacturers";
import ContentFilterSidebar from "../../../../GlobalComponents/ContentFilterSidebar";
import ManufacturerFilter from "./ManufacturerFilter";

const Manufacturers = () => {

    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedManufacturerID, SetSelectedManufacturerID] = useState(null);
    const [showFilterSidebar,SetShowFilterSidebar] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedManufacturerIDs,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete,
        Filter
    } = UseGetAllManufacturers(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteManufacturers
    } = UseDeleteManufacturers(selectedManufacturerIDs,(d) => UpdateListAfterDelete(d));

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ManufacturerList")}
                        OnFilterClick = {() => SetShowFilterSidebar(true)}
                        OnAddButtonClick={() => SetSelectedManufacturerID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        OnDragListRowEnd = {(result) => OnDragEnd(result)}
                        options = {{
                            OnEditClick: (manufacturerID) => SetSelectedManufacturerID(manufacturerID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />

                    <ContentFilterSidebar
                        isOpen = {showFilterSidebar}
                        OnClose = {() => SetShowFilterSidebar(false)}
                    >
                        <ManufacturerFilter
                            OnFilter = {(filterData) => Filter(filterData)}
                        />
                    </ContentFilterSidebar>
                </>
            }

            {selectedManufacturerID || selectedManufacturerID == 0 ?
                <AddEditManufacturer
                    selectedManufacturerID = {selectedManufacturerID}
                    SetSelectedManufacturerID = {SetSelectedManufacturerID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedManufacturerID,d);
                        SetSelectedManufacturerID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteManufacturers")} 
                    CallBack={DeleteManufacturers} 
                />
            :null}

        </>
    )
}

export default Manufacturers;