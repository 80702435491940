import LoadingIcon from './LoadingIcon';

const Button = ({OnClick,className,children,loading,disabled}) => {

    return(
        <button disabled = {disabled || loading} className = {"btn" + (className ? " " + className : "")} onClick = {(e) => OnClick(e)}>
            {loading ?
                <LoadingIcon />
            :
                children
            }
        </button>
    )

}

export default Button;