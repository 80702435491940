import { useEffect } from "react";
import Button from "../../../GlobalComponents/Button";
import Checkbox from "../../../GlobalComponents/Checkbox";
import NoItems from "../../../GlobalComponents/NoItems";
import InputCard from "../../../GlobalComponents/InputCard";
import Input from "../../../GlobalComponents/Input";
import photosIcon from '../../../Media/Images/Icons/image.webp';
import FileInput from "../../../GlobalComponents/FileInput";
import { useTranslation } from 'react-i18next';
import UseUploadPhotos from "../../../GlobalHooks/UseUploadPhotos";
import UseDeletePhotos from "../../../GlobalHooks/UseDeletePhotos";
import { FB_PHOTO_IMAGE_URL, SERVER_URL } from "../../../Config";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Loading from "../../../GlobalComponents/Loading";
import { UPLOAD_FB_PHOTOS, DELETE_FB_PHOTOS,GET_FB_PHOTOS } from "../Queries/fbPhotos";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import UseGetPhotos from "../../../GlobalHooks/UseGetPhotos";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import UseEditFbPhotos from "../Library/EditFbPhotos";

const FbPhotos = () => {

    const {t} = useTranslation();
    const {
        error: editError,
        loading: editLoading,
        formData,
        SetFormData,
        RemoveFormDataSelectedItems,
        SetFormDataSelectedItemsWithObj,
        EditFbPhotos
    } = UseEditFbPhotos();

    const {data,loading:getLoading,error} = UseGetPhotos(GET_FB_PHOTOS,null,null);
    const {loading,UploadPhotos} = UseUploadPhotos(UPLOAD_FB_PHOTOS,null,null,(d) => {

        var arr = [...formData.fbPhotos,...d.UploadFbPhotos];
        SetFormData("fbPhotos",arr);       

    });

    const {
        selectedItems,
        showDeleteNotification, 
        SelectPhoto, 
        DeletePhotos,
        ShowDeleteNotification
    } = UseDeletePhotos(DELETE_FB_PHOTOS,"fbPhotoIDs",(d) => {

        const IDs = d.DeleteFbPhotos.split(",");
        RemoveFormDataSelectedItems("fbPhotos","fbPhotoID",IDs)
 
    });  
    
    useEffect(() => {

        if(data)
        {
            SetFormData("fbPhotos", data.AllFbPhotos);
        }

    },[data])

    return(
        <>
            <ContentHeader
                title = {t("Facebook")}
                OnSaveButtonClick = {() => EditFbPhotos()}
            />
            <ContentBody>
                
                <InputCard
                    title = {t("Photos")}
                    icon = {photosIcon}
                    headerChildren = {
                        (!error && !editError ?
                            <div className="row">
                                <div className="col-50">
                                    <FileInput
                                        showOnlyButton = {true}
                                        OnChange = {(e) => UploadPhotos(e.target.files)}
                                        buttonTitle = {t("UploadImages")}
                                        btnClassName = "narrow"
                                        inputClassName = "narrow"
                                    />
                                </div>
                                <div className="col-50">
                                    <Button
                                        className="btn-brown narrow"
                                        OnClick = {() => ShowDeleteNotification(true)}
                                    >
                                    {t("DeleteSelected")}     
                                    </Button>
                                </div>
                            </div>
                        :null)
                    }
                > 
                    {error || editError ?
                        <Error text={GetApolloErrorText(error || editError)} />
                    :
                        (loading || getLoading || editLoading ?
                            <Loading />    
                        :
                            (formData.fbPhotos && formData.fbPhotos.length > 0 ?
                                <div className="row">
                                    {formData.fbPhotos.map((item) => {
                                        
                                        var check = false;
                                        for(let val of selectedItems)
                                        {
                                            if(val == item.fbPhotoID)
                                                check = true;   
                                        }

                                        return(
                                            <div key={item.fbPhotoID} className="col-16 col-lg-25 col-md-33 col-sm-50 col-xs-100">
                                                <div className="cm-image-container form-group">
                                                    <div className="select">
                                                        <Checkbox 
                                                            text = {""}
                                                            name = "selectedFbPhotoPhoto"
                                                            checked = {check}
                                                            OnChange = {() => SelectPhoto(item.fbPhotoID)}
                                                        />
                                                    </div>
                                                    <img 
                                                        src = {SERVER_URL + "/" + FB_PHOTO_IMAGE_URL + "/stredni_" + item.name} 
                                                        alt={item.name} 
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Input
                                                        placeholder = {t("Url")}
                                                        value = {item.url ? item.url : ""}
                                                        name = "url"
                                                        OnChange = {(e) => {
                                                            SetFormDataSelectedItemsWithObj(e,"fbPhotos","fbPhotoID",item.fbPhotoID);
                                                            SetFormDataSelectedItemsWithObj("isUpdated","fbPhotos","fbPhotoID",item.fbPhotoID,true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            :
                                <NoItems text={t("NoImagesHereYet")} />
                            )
                        )
                    }
                </InputCard>

                {showDeleteNotification ?
                    <ModalNotification 
                        yesNo = {true} 
                        text = {t("DoYouReallyWantToDeletePhotos")} 
                        CallBack = {DeletePhotos} 
                    />
                :null}

            </ContentBody>
        </>
    )
}

export default FbPhotos;