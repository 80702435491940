import {useQuery} from '@apollo/client';
import { GET_BANNERS } from '../Queries/banner';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { BANNER_IMAGE_URL, SERVER_URL } from '../../../Config';

const UseGetAllBanners = (lang) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedBannerIDs,SetSelectedBannerIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_BANNERS,{
        variables:{
            lang
        },
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllBanners);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("BannerName"),
            className:"static"
        },
        {
            value: t("BannerType"),
            className:"static text-center"
        },
        {
            value: t("Description"),
            className:"flex-1"
        },
        {
            value: t("Price"),
            className:"static text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.image ? SERVER_URL + "/" + BANNER_IMAGE_URL + "/mala_" + val.image : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"static"
                    },
                    {
                        value:val.bannerType.name,
                        className:"static text-center"
                    },
                    {
                        value:val.description,
                        className:"flex-1"
                    },
                    {
                        value:val.price,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.bannerID;
            cData.selected = false;

            if(val.subBanner)
                cData.subData = GetContentData(val.subBanner);

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,bannerID) => {

        var checked = e.target.checked;
        var newSelectedBannerIDs = [...selectedBannerIDs];
        const newList = SelectBannerID(content,bannerID,checked,newSelectedBannerIDs)

        SetContent(newList);
        SetSelectedBannerIDs(newSelectedBannerIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedBannerIDs = [];
        const newList = SelectBannerID(content,"all",checked,newSelectedBannerIDs)

        SetSelectedBannerIDs(newSelectedBannerIDs);
        SetContent(newList);
    }

    const SelectBannerID = (list,bannerID,checked,selectedBannerIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(bannerID == newList[i].rowID || bannerID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedBannerIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedBannerIDs.length; j++)
                    {
                        if(selectedBannerIDs[j] == newList[i].rowID)
                            selectedBannerIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectBannerID(newList[i].subData,bannerID,checked,selectedBannerIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedBannerIDs,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllBanners;