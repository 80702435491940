import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import UseGetAllProductReviews from "../../Library/Products/UseGetAllProductReviews";
import { useState } from "react";
import AddEditProductReview from "./AddEditProductReview";
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";

const ProductReviews = () => {

    const {t}    = useTranslation();

    const [selectedProductReviewID, SetSelectedProductReviewID] = useState(null);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        FetchMore,
        GoToPage,
        UpdateListAfterAddEdit
    } = UseGetAllProductReviews();
    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ProductReviews")}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        options = {{
                            OnEditClick: (parameterID) => SetSelectedProductReviewID(parameterID)
                        }}
                    />
                </>
            }

            {selectedProductReviewID || selectedProductReviewID == 0 ?
                <AddEditProductReview
                    selectedProductReviewID = {selectedProductReviewID}
                    SetSelectedProductReviewID = {SetSelectedProductReviewID}
                />
            :null}

        </>
    )

}

export default ProductReviews;