import Modal from 'react-modal';
import { useQuery } from '@apollo/client';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import Checkbox from '../../../../GlobalComponents/Checkbox';
import { useTranslation } from 'react-i18next';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import Loading from '../../../../GlobalComponents/Loading';
import { GET_DIMENSION_TYPES } from '../../Queries/products';
import { SERVER_URL,PRODUCT_DIMENSION_TYPE_IMAGE_URL } from '../../../../Config';
import Input from '../../../../GlobalComponents/Input';

const ChooseDimensionType = ({
    formData,
    SetShowAddDimensionType,
    SetFormData
}) => {

    const {t} = useTranslation();

    const {data,loading,error} = useQuery(GET_DIMENSION_TYPES,{
        fetchPolicy:"network-only"
    })

    const SelectDimension = (item) => {

        if(!formData.productDimensionType || 
            formData.productDimensionType.productDimensionTypeID != item.productDimensionTypeID
        )
            SetFormData("productDimensionType",{
                photo:                  item.photo,
                productDimensionTypeID: item.productDimensionTypeID,
                name:                   item.name,
                sizePositions:          item.sizePositions
            })
        else
            SetFormData("productDimensionType",null);

        SetShowAddDimensionType(false);
    }

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddDimensionType(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("ChooseParameters")}
                OnClose ={() => SetShowAddDimensionType(false)}
            />

            <div className="modal-body">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        <>
                            {data.AllProductDimensionTypes && data.AllProductDimensionTypes.length > 0 ?
                                
                                <div className='row'>

                                    {data.AllProductDimensionTypes.map((item) => {

                                        var check = false;
                                        if(
                                            formData.productDimensionType && 
                                            formData.productDimensionType.productDimensionTypeID == item.productDimensionTypeID
                                        )
                                            check = true;
                                        
                                        return(
                                            <div key={item.productDimensionTypeID} className="col-50">
                                                <div className="form-group">
                                                    <div 
                                                        onClick = {() => SelectDimension(item)}
                                                        className = {"cm-image-container cm-image-container-padding cursor-pointer" + (check ? " cm-image-container-selected" : "")}
                                                    >
                                                        <img 
                                                            src={SERVER_URL + "/" + PRODUCT_DIMENSION_TYPE_IMAGE_URL + "/" + item.photo} 
                                                        />
                                                    </div>
                                                    <div className="form-text text-center">{item.name}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                </div> 
                            :null}
                        </>
                    )
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddDimensionType(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseDimensionType;