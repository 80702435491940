/**
 * hlavní sekce
 */

const SECTION = {
    backgroundColorClass:"",
    backgroundImage:"",
    sectionName:"",
    fullWidth:0,
    fullHeight:0,
    elements:[],
    className:"",
    imageFill:"cover",
    imageAlign:"left",
    contentAlign:"top",
    fadeWithBackground:0,
    ptXL:50,
    pbXL:50,
    prXL:140,
    plXL:140,
    ptLG:50,
    pbLG:50,
    prLG:50,
    plLG:50,
    ptMD:50,
    pbMD:50,
    prMD:15,
    plMD:15,
    ptSM:40,
    pbSM:40,
    prSM:15,
    plSM:15,
    ptXS:40,
    pbXS:40,
    prXS:15,
    plXS:15,
    ptXXS:40,
    pbXXS:40,
    prXXS:15,
    plXXS:15,
}

/**
 * definice elementu seřazeného sloupce
 */

const COLUMN = {
    className:"",
    contentAlign:"start",
    hasBorder:0,
    borderRadius:0,
    colsXL:"25",
    colsLG:"25",
    colsMD:"25",
    colsSM:"25",
    colsXS:"25",
    colsXXS:"25",
    mtXL:0,
    mbXL:0,
    mtLG:0,
    mbLG:0,
    mtMD:0,
    mbMD:0,
    mtSM:0,
    mbSM:0,
    mtXS:0,
    mbXS:0,
    mtXXS:0,
    mbXXS:0,
    ptXL:0,
    pbXL:0,
    prXL:0,
    plXL:0,
    ptLG:0,
    pbLG:0,
    prLG:0,
    plLG:0,
    ptMD:0,
    pbMD:0,
    prMD:0,
    plMD:0,
    ptSM:0,
    pbSM:0,
    prSM:0,
    plSM:0,
    ptXS:0,
    pbXS:0,
    prXS:0,
    plXS:0,
    ptXXS:0,
    pbXXS:0,
    prXXS:0,
    plXXS:0,
    elements:[],
}

/**
 * definice elementu kontejneru pro seřazeného sloupce
 */

const COLUMNS = {
    columns:{
        cols:[COLUMN],
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        colsPaddingXL: 20,
        colsPaddingLG: 20,
        colsPaddingMD: 15,
        colsPaddingSM: 15,
        colsPaddingXS: 15,
        colsPaddingXXS: 15,
        wrapColumnsXL: "cm-wrap",
        wrapColumnsLG: "cm-wrap",
        wrapColumnsMD: "cm-wrap",
        wrapColumnsSM: "cm-wrap",
        wrapColumnsXS: "cm-wrap",
        wrapColumnsXXS: "cm-wrap"
    }
}

/**
 * Definice elementu nadpis
 */

const HEADLINE = { 

    headline: {    
        text:"Text nadpisu",
        subText:"Nějaký text pod nadpisem, který lze schovat",
        hasSubText:1,
        showGlow:1,
        className:"",
        selectedTag:"h1",
        subHfsXL:"26",
        subHfsLG:"24",
        subHfsMD:"22",
        subHfsSM:"20",
        subHfsXS:"18",
        subHfsXXS:"16",

        h1mtXL:0,
        h1mbXL:60,
        h1mtLG:0,
        h1mbLG:60,
        h1mtMD:0,
        h1mbMD:40,
        h1mtSM:0,
        h1mbSM:30,
        h1mtXS:0,
        h1mbXS:30,
        h1mtXXS:0,
        h1mbXXS:30,
        h1fsXL:"40",
        h1fsLG:"35",
        h1fsMD:"30",
        h1fsSM:"28",
        h1fsXS:"26",
        h1fsXXS:"26",
        
        h2mtXL:40,
        h2mbXL:20,
        h2mtLG:40,
        h2mbLG:20,
        h2mtMD:30,
        h2mbMD:15,
        h2mtSM:30,
        h2mbSM:15,
        h2mtXS:30,
        h2mbXS:15,
        h2mtXXS:30,
        h2mbXXS:15,
        h2fsXL:"20",
        h2fsLG:"20",
        h2fsMD:"20",
        h2fsSM:"20",
        h2fsXS:"20",
        h2fsXXS:"20",
        
        h3mtXL:10,
        h3mbXL:30,
        h3mtLG:10,
        h3mbLG:30,
        h3mtMD:10,
        h3mbMD:30,
        h3mtSM:10,
        h3mbSM:25,
        h3mtXS:10,
        h3mbXS:20,
        h3mtXXS:10,
        h3mbXXS:20,
        h3fsXL:"20",
        h3fsLG:"20",
        h3fsMD:"20",
        h3fsSM:"20",
        h3fsXS:"20",
        h3fsXXS:"20",
        
        h4mtXL:10,
        h4mbXL:30,
        h4mtLG:10,
        h4mbLG:30,
        h4mtMD:10,
        h4mbMD:30,
        h4mtSM:10,
        h4mbSM:25,
        h4mtXS:10,
        h4mbXS:20,
        h4mtXXS:10,
        h4mbXXS:20,
        h4fsXL:"16",
        h4fsLG:"16",
        h4fsMD:"16",
        h4fsSM:"16",
        h4fsXS:"16",
        h4fsXXS:"16",

        h5mtXL:10,
        h5mbXL:30,
        h5mtLG:10,
        h5mbLG:30,
        h5mtMD:10,
        h5mbMD:30,
        h5mtSM:10,
        h5mbSM:25,
        h5mtXS:10,
        h5mbXS:20,
        h5mtXXS:10,
        h5mbXXS:20,
        h5fsXL:"16",
        h5fsLG:"16",
        h5fsMD:"16",
        h5fsSM:"16",
        h5fsXS:"16",
        h5fsXXS:"16",

        h6mtXL:10,
        h6mbXL:30,
        h6mtLG:10,
        h6mbLG:30,
        h6mtMD:10,
        h6mbMD:30,
        h6mtSM:10,
        h6mbSM:25,
        h6mtXS:10,
        h6mbXS:20,
        h6mtXXS:10,
        h6mbXXS:20,
        h6fsXL:"16",
        h6fsLG:"16",
        h6fsMD:"16",
        h6fsSM:"16",
        h6fsXS:"16",
        h6fsXXS:"16",
    }
}

const TEXT = { 

    text: {
        text:"<p>Obsah textu</p>",
        className:"",
        opacity:"10",
        mtXL:20,
        mbXL:20,
        mtLG:20,
        mbLG:20,
        mtMD:15,
        mbMD:15,
        mtSM:15,
        mbSM:15,
        mtXS:15,
        mbXS:15,
        mtXXS:15,
        mbXXS:15,
        fsXL:"16",
        fsLG:"16",
        fsMD:"15",
        fsSM:"15",
        fsXS:"14",
        fsXXS:"14"
    }
    
}

const IMAGE = { 

    image: {
        fileName:"",
        imageAlign:"center",
        borderRadius:0,
        useDimensions:"0",
        imageSize:"stredni",
        linkTarget:"_blank",
        link:"",
        className:"",
        useOverlayByText:0,
        overlayText:"Nadpis",
        overlayColor:"black",
        overlayOpacity: 7,
        overlayHorizontalAlign:"start",
        overlayVerticalAlign:"end",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        widthXL:300,
        widthLG:300,
        widthMD:300,
        widthSM:300,
        widthXS:300,
        widthXXS:300,
        heightXL:300,
        heightLG:300,
        heightMD:300,
        heightSM:300,
        heightXS:300,
        heightXXS:300,
        overlayfsXL:"30",
        overlayfsLG:"26",
        overlayfsMD:"22",
        overlayfsSM:"18",
        overlayfsXS:"16",
        overlayfsXXS:"16"
    }  
}

const IMAGE_GALLERY = { 

    imageGallery: {
        images:[],
        borderRadius:0,
        imageSize:"stredni",
        colsXL:"25",
        colsLG:"25",
        colsMD:"25",
        colsSM:"25",
        colsXS:"25",
        colsXXS:"25",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        ptXL:20,
        pbXL:20,
        prXL:20,
        plXL:20,
        ptLG:20,
        pbLG:20,
        prLG:20,
        plLG:20,
        ptMD:15,
        pbMD:15,
        prMD:15,
        plMD:15,
        ptSM:15,
        pbSM:15,
        prSM:15,
        plSM:15,
        ptXS:15,
        pbXS:15,
        prXS:15,
        plXS:15,
        ptXXS:15,
        pbXXS:15,
        prXXS:15,
        plXXS:15
    }  
}

const BUTTON = { 

    button: {
        text:"Název odkazu",
        buttonAlign:"center",
        url:"",
        color:"btn-primary",
        size:"small",
        dividingLine:"0",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
    }
}

const VIDEO = { 

    video: {
        url:"",
        type:"youtube",
        mp4File:"",
        mp4FilePoster:"",
        mp4Align:"center",
        mp4Width:300,
        mp4Height:300,
        controls:"0",
        useDimensions:0,
        borderRadius:0,
        changeVideo:false,
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
    }
}

const NEWEST_ARTICLES = { 
    newestArticles: {
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }
}

const NEWSLETTER = { 
    newsletter: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const HTML = { 

    html: {
        code:"code",
        type:"html",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    } 
}

const RETINO = { 

    retino: {
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    } 
}

const FAQ_ELM_QUESTION = (langs) => { 

    var text  = {};
    var title = {};

    for(let i in langs){
        text[langs[i].suffix]  = "Text pro odpověď " + langs[i].suffix;
        title[langs[i].suffix] = "Otázka " + langs[i].suffix;
    }

    return { 
        text:text,
        title:title,  
    }
}

const FAQ_ELM = (langs) => { 

    var q = [];
    q.push(FAQ_ELM_QUESTION(langs));

    return {
        faq: {
            showMenu:true,
            showSettings:false,
            className:"",
            questions:q
        }
    }
}


const FORM_ELM = (langs) => { 

    var text = {};

    for(let i in langs){
        text[langs[i].suffix] = "Odeslat " + langs[i].suffix;
    }

    return {
        form: {
            showMenu:true,
            showSettings:false,
            className:"",
            formID:0,
            color:"",
            size:"",
            text
        }
    }
    
}

const SERVICE_ELM = (langs) => { 

    return {
        service: {
            showMenu:true,
            showSettings:false,
            className:"",
            serviceID:0,
            color:""
        }
    }
    
}

const ALERT = { 

    alert: {
        text:"Nějaký text pro upozornění.",
        type:"success",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        fsXL:"16",
        fsLG:"16",
        fsMD:"15",
        fsSM:"15",
        fsXS:"14",
        fsXXS:"14"
    }
    
}

const SLIDER = { 
    slider: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const SEPARATOR = { 
    separator: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const RECOMMENDED_PRODUCTS = { 

    recommendedProducts: {
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        selectedProducts:[]
    }  
}

const TOP_CATEGORIES = { 

    topCategories: {
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        selectedCategories:[]
    }  
}

const BANNER = { 

    banner: {
        className:"",
        smallTopBannerID:0,
        smallBottomBannerID:0,
        bigBannerIDs:[0,0,0],
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }  
}

const FORM = { 

    form: {
        className:"",
        formID:0,
        color:"",
        size:"",
        text:"Odeslat",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    } 
}

export {
    SECTION,
    COLUMNS,
    COLUMN,
    HEADLINE,
    TEXT,
    IMAGE,
    IMAGE_GALLERY,
    BUTTON,
    VIDEO,
    NEWEST_ARTICLES,
    NEWSLETTER,
    HTML, 
    FAQ_ELM, 
    FAQ_ELM_QUESTION,
    FORM_ELM,
    SERVICE_ELM,
    ALERT,
    SLIDER,
    SEPARATOR,
    RECOMMENDED_PRODUCTS,
    TOP_CATEGORIES,
    BANNER,
    RETINO,
    FORM
};