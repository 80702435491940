import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import UseGetAllParameterTemplates from "../../Library/ParameterTemplates/UseGetAllParameterTemplates";
import { useState } from "react";
import AddEditParameterTemplate from "./AddEditParameterTemplate";
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import UseDeleteParameterTemplates from "../../Library/ParameterTemplates/UseDeleteParameterTemplates";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";

const ParameterTemplates = () => {

    const {t} = useTranslation();

    const [selectedParameterTemplateID, SetSelectedParameterTemplateID] = useState(null);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedParameterTemplateIDs,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllParameterTemplates();

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteParameterTemplates
    } = UseDeleteParameterTemplates(selectedParameterTemplateIDs,(d) => UpdateListAfterDelete(d));

    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ParameterTemplateList")}
                        OnAddButtonClick={() => SetSelectedParameterTemplateID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (parameterID) => SetSelectedParameterTemplateID(parameterID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedParameterTemplateID || selectedParameterTemplateID == 0 ?
                <AddEditParameterTemplate
                    selectedParameterTemplateID = {selectedParameterTemplateID}
                    SetSelectedParameterTemplateID = {SetSelectedParameterTemplateID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedParameterTemplateID,d);
                        SetSelectedParameterTemplateID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteParameterTemplates")} 
                    CallBack={DeleteParameterTemplates} 
                />
            :null}

        </>
    )

}

export default ParameterTemplates;