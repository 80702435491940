import gql from 'graphql-tag';

export const ADD_EDIT_COUPON = gql`
    mutation AddEditCoupon(
        $couponID:ID,
        $vatID:ID,
        $forLangID:ID,
        $isLimitedQuantity:Int,
        $canBeAppliedToDiscountedProducts:Int,
        $byManufacturer:Int,
        $byLink:Int,
        $showInProductDetail:Int,
        $quantity:Int,
        $code:String,
        $dateFrom:String
        $dateTo:String,
        $internalDescription: String,
        $selectedLinks:[ID]
        $selectedManufacturers:[ID]
        $langs:[CouponLangsInput]
        
    ){
        AddEditCoupon(
            couponID:$couponID,
            vatID:$vatID,
            forLangID:$forLangID,
            isLimitedQuantity:$isLimitedQuantity,
            canBeAppliedToDiscountedProducts:$canBeAppliedToDiscountedProducts,
            byManufacturer:$byManufacturer,
            byLink:$byLink,
            showInProductDetail:$showInProductDetail,
            quantity:$quantity,
            code:$code,
            dateFrom:$dateFrom,
            dateTo:$dateTo,
            internalDescription: $internalDescription,
            selectedLinks:$selectedLinks
            selectedManufacturers:$selectedManufacturers
            langs:$langs
        ){
            couponID
            code
        }
    }
`;

export const GET_COUPON = gql`
    query CouponWithLangs($couponID: ID,$lang:String){
        CouponWithLangs(couponID:$couponID){
            couponID
            vatID
            forLangID
            isLimitedQuantity
            canBeAppliedToDiscountedProducts
            byManufacturer
            byLink
            showInProductDetail
            quantity
            code
            dateFrom
            dateTo
            internalDescription
            forLang{
                suffix
            }
            links{
                linkID
                link(lang:$lang){
                    name
                }
            }
            manufacturers{
                manufacturerID
                manufacturer{
                    name
                }
            }
            langs{
                lang
                amount
                inPercentage
                minimumOrderPrice
            }   

        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllVats{
            vatID
            value
        }
    }
`;

export const GET_COUPONS = gql`
    query AllCoupons($lang: String,$limit:Int,$offset:Int){
        AllCoupons(lang:$lang,limit:$limit,offset:$offset){
            couponID
            forLangID
            amount
            inPercentage
            isLimitedQuantity
            canBeAppliedToDiscountedProducts
            byManufacturer
            byLink
            showInProductDetail
            quantity
            code
            dateFrom
            dateTo
            internalDescription
            forLang{
                suffix
            }
        }
        AllCouponsCount(lang:$lang)
    }
`;

export const DELETE_COUPONS = gql`
    mutation DeleteCoupons($ouponsIDs:ID) {
        DeleteCoupons(ouponsIDs:$ouponsIDs)
    }
`;