import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_TICKLABEL } from "../../Queries/tickLabels";

const UseAddEditTickLabel = (selectedTickLabelID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        selectedLang:lang,
        active:1,
        langs:[]
    });

    const [AddEditTickLabelMutation,{error,loading}] = useMutation(ADD_EDIT_TICKLABEL,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditTickLabel);

            const notifyText = (selectedTickLabelID != 0 ? t("SuccessfullyUpdated") : t("NewTickLabelIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditTickLabel = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(val.name === "" && val.lang === lang)
                check = false;
        }

        if(check)
        { 
            let langs = [];
            for(const lang of formData.langs)
            {
                langs.push({
                    name: lang.name,
                    lang: lang.lang
                });
            }
            AddEditTickLabelMutation({
                variables:{
                    tickLabelID: selectedTickLabelID,
                    active:  formData.active,
                    langs:   langs
                }
            })
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditTickLabel,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddEditTickLabel;