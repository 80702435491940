import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_MANUFACTURER } from "../../Queries/manufacturers";

const UseAddEditManufacturer = (selectedManufacturerID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        selectedLang:lang,
        name:"",
        shortcut:"",
        deliveryDays:0,
        langs:[]
    });

    const [AddEditManufacturerMutation,{error,loading}] = useMutation(ADD_EDIT_MANUFACTURER,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditManufacturer);

            const notifyText = (selectedManufacturerID != 0 ? t("SuccessfullyUpdated") : t("NewManufacturerIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditManufacturer = () => {

        if(formData.name != "")
        {
            if(formData.shortcut != "")
            {
                let langs = [];
                for(const lang of formData.langs)
                {
                    langs.push({
                        deliveryTimeToCustomer: lang.deliveryTimeToCustomer,
                        lang:                   lang.lang
                    });
                }
                AddEditManufacturerMutation({
                    variables:{
                        manufacturerID:     selectedManufacturerID,
                        name:               formData.name,
                        shortcut:           formData.shortcut,
                        deliveryDays:       parseInt(formData.deliveryDays),
                        langs:              langs
                    }
                })
            }
            else
                AddNotification(t("ShurtcutIsImportant"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditManufacturer,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddEditManufacturer;