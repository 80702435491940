import mobileIcon from "../../../Media/Images/Icons/mobile.webp";
import monitorIcon from "../../../Media/Images/Icons/monitor.webp";
import laptopIcon from "../../../Media/Images/Icons/laptop.webp";
import ipadIcon from "../../../Media/Images/Icons/ipad.webp";
import tabletWideIcon from "../../../Media/Images/Icons/tablet_wide.webp";
import tabletIcon from "../../../Media/Images/Icons/tablet.webp";
import { SERVER_URL,CONTENT_MANAGER_IMAGE_URL } from "../../../Config";

export const GetSectionClassName = (section,sectionLength) => {

    var sectionClassName = section.backgroundColorClass + " " + section.className;
    if(sectionLength == 1 && section.elements.length == 0) 
        sectionClassName += " cm-min-height";

    if(section.fullHeight == 1) 
        sectionClassName += " full-height";

    if(section.contentAlign == "center")
        sectionClassName += " cm-justify-content-center";

    if(section.contentAlign == "bottom")
        sectionClassName += " cm-ustify-content-end";

    return sectionClassName;

}

export const GetContainerStyles = (data) => {

    var styles = {};  
    var styleClassName = "";

    styles["--cm-padding"]  =  "0px " + data.prXL + "px 0px " + data.plXL + "px";
    styleClassName += "cm-columns-padding";
    
    if((data.prLG != data.prXL || data.plLG != data.plXL))
    {
        styles["--cm-padding-lg"]  =  "0px " + data.prLG + "px 0px " + data.plLG + "px";
        styleClassName += " cm-columns-padding-lg";
    }

    if((data.prMD != data.prLG || data.plMD != data.plLG))
    {
        styles["--cm-padding-md"]  = "0px " + data.prMD + "px 0px " + data.plMD + "px";
        styleClassName += " cm-columns-padding-md";
    }

    if((data.prSM != data.prMD || data.plSM != data.plMD))
    {
        styles["--cm-padding-sm"]  =  "0px " + data.prSM + "px 0px " + data.plSM + "px";
        styleClassName += " cm-columns-padding-sm";
    }

    if((data.prXS != data.prSM || data.plXS != data.plSM))
    {
        styles["--cm-padding-xs"]  =  "0px " + data.prXS + "px 0px " + data.plXS + "px";
        styleClassName += " cm-columns-padding-xs";
    }

    if((data.prXXS != data.prXS || data.plXXS != data.plXS))
    {
        styles["--cm-padding-xxs"]  =  "0px " + data.prXXS + "px 0px " + data.plXXS + "px";
        styleClassName += " cm-columns-padding-xxs";
    }

    return {styles,styleClassName};

}

export const GetSectionStyles = (data) => {

    var styles = {};  
    var styleClassName = "";

    styles["--cm-padding"]  =  data.ptXL + "px 0px " + data.pbXL + "px 0px";
    styleClassName += "cm-columns-padding";
    
    if((data.ptLG != data.ptXL || data.pbLG != data.pbXL))
    {
        styles["--cm-padding-lg"]  =  data.ptLG + "px 0px " + data.pbLG + "px 0px";
        styleClassName += " cm-columns-padding-lg";
    }

    if((data.ptMD != data.ptLG || data.pbMD != data.pbLG))
    {
        styles["--cm-padding-md"]  =  data.ptMD + "px 0px " + data.pbMD + "px 0px";
        styleClassName += " cm-columns-padding-md";
    }

    if((data.ptSM != data.ptMD || data.pbSM != data.pbMD))
    {
        styles["--cm-padding-sm"]  =  data.ptSM + "px 0px " + data.pbSM + "px 0px";
        styleClassName += " cm-columns-padding-sm";
    }

    if((data.ptXS != data.ptSM || data.pbXS != data.pbSM))
    {
        styles["--cm-padding-xs"]  =  data.ptXS + "px 0px " + data.pbXS + "px 0px";
        styleClassName += " cm-columns-padding-xs";
    }

    if((data.ptXXS != data.ptXS || data.pbXXS != data.pbXS))
    {
        styles["--cm-padding-xxs"]  =  data.ptXXS + "px 0px " + data.pbXXS + "px 0px";
        styleClassName += " cm-columns-padding-xxs";
    }    

    if(data.backgroundImage)
        styles.backgroundImage = "url('" + SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/maxi_" + data.backgroundImage + "')";

    if(data.imageAlign)
        styles.backgroundPosition = data.imageAlign + " center";

    if(data.imageFill)
        styles.backgroundSize = data.imageFill;

    return {styles,styleClassName}

}

export const GetColumnsPadding = (data) => {

    var className = "";  

    if(data["colsPaddingXL"])
        className = "cols-padding-" + data["colsPaddingXL"] + " " + data["wrapColumnsXL"];

    // LG

    if(data["colsPaddingLG"] && data["colsPaddingLG"] != data["colsPaddingXL"])
        className += " cols-padding-lg-" + data["colsPaddingLG"];

    if(data["wrapColumnsLG"] != data["wrapColumnsXL"])
        className += " " + data["wrapColumnsLG"] + "-lg";

    // MD
     
    if(data["colsPaddingMD"] && data["colsPaddingMD"] != data["colsPaddingLG"])
        className += " cols-padding-md-" + data["colsPaddingMD"];

    if(data["wrapColumnsMD"] != data["wrapColumnsLG"])
        className += " " + data["wrapColumnsMD"] + "-md";

    // SM

    if(data["colsPaddingSM"] && data["colsPaddingSM"] != data["colsPaddingMD"])
        className += " cols-padding-sm-" + data["colsPaddingSM"];

    if(data["wrapColumnsSM"] != data["wrapColumnsMD"])
        className += " " + data["wrapColumnsSM"] + "-sm";    

    // XS

    if(data["colsPaddingXS"] && data["colsPaddingXS"] != data["colsPaddingSM"])
        className += " cols-padding-xs-" + data["colsPaddingXS"];

    if(data["wrapColumnsXS"] != data["wrapColumnsSM"])
        className += " " + data["wrapColumnsXS"] + "-xs"; 

    // XXS

    if(data["colsPaddingXXS"] && data["colsPaddingXXS"] != data["colsPaddingXS"])
        className += " cols-padding-xxs-" + data["colsPaddingXXS"];

    if(data["wrapColumnsXXS"] != data["wrapColumnsXS"])
        className += " " + data["wrapColumnsXXS"] + "-xxs"; 

    return className;

}

export const GetColumnStyles = (data) => {

    var styles = {};  
    var styleClassName = "";

    if((data.ptXL != 0 || data.prXL != 0 || data.pbXL != 0 || data.plXL != 0))
    {
        styles["--cm-padding"]  =  data.ptXL + "px " + data.prXL + "px " + data.pbXL + "px " + data.plXL + "px";
        styleClassName += "cm-columns-padding";
    }

    if((data.ptLG != 0 || data.prLG != 0 || data.pbLG != 0 || data.plLG != 0))
    {
        styles["--cm-padding-lg"]  =  data.ptLG + "px " + data.prLG + "px " + data.pbLG + "px " + data.plLG + "px";
        styleClassName += " cm-columns-padding-lg";
    }

    if((data.ptMD != 0 || data.prMD != 0 || data.pbMD != 0 || data.plMD != 0))
    {
        styles["--cm-padding-md"]  =  data.ptMD + "px " + data.prMD + "px " + data.pbMD + "px " + data.plMD + "px";
        styleClassName += " cm-columns-padding-md";
    }

    if((data.ptSM != 0 || data.prSM != 0 || data.pbSM != 0 || data.plSM != 0))
    {
        styles["--cm-padding-sm"]  =  data.ptSM + "px " + data.prSM + "px " + data.pbSM + "px " + data.plSM + "px";
        styleClassName += " cm-columns-padding-sm";
    }

    if((data.ptXS != 0 || data.prXS != 0 || data.pbXS != 0 || data.plXS != 0))
    {
        styles["--cm-padding-xs"]  =  data.ptXS + "px " + data.prXS + "px " + data.pbXS + "px " + data.plXS + "px";
        styleClassName += " cm-columns-padding-xs";
    }

    if((data.ptXXS != 0 || data.prXXS != 0 || data.pbXXS != 0 || data.plXXS != 0))
    {
        styles["--cm-padding-xxs"]  =  data.ptXXS + "px " + data.prXXS + "px " + data.pbXXS + "px " + data.plXXS + "px";
        styleClassName += " cm-columns-padding-xxs";
    }

    if(data.borderRadius)
        styles["borderRadius"] = data.borderRadius + "px";

    return {styles,styleClassName}

}

export const GetElementMargins = (data,prepend = "") => {

    var styles = {}; 
    var className = ""; 
    var styleClassName = "";

    // XL

    if((data[prepend + "mtXL"] != 0 || data[prepend + "mbXL"] != 0))
    {
        styles["--cm-margin"]  =  data[prepend + "mtXL"] + "px 0 "+ data[prepend + "mbXL"] + "px 0";
        styleClassName += "cm-element-margin";
    }
    
    if(data[prepend + "mtXL"] == 0)
        className += (className ? " " : "") + "cm-no-top-margin";

    if(data[prepend + "mbXL"] == 0)
        className += (className ? " " : "") + "cm-no-bottom-margin";

    // LG

    if(
        (data[prepend + "mtLG"] != 0 || data[prepend + "mbLG"] != 0) && 
        (
            data[prepend + "mtLG"] != data[prepend + "mtXL"] || 
            data[prepend + "mbLG"] != data[prepend + "mbXL"]
        )
    )
    {
        styles["--cm-margin-lg"]  =  data[prepend + "mtLG"] + "px 0 "+ data[prepend + "mbLG"] + "px 0";
        styleClassName += (styleClassName ? " " : "") + "cm-element-margin-lg";
    }

    if(data[prepend + "mtLG"] == 0 && data[prepend + "mtLG"] != data[prepend + "mtXL"])
        className += (className ? " " : "") + "cm-no-top-margin-lg";

    if(data[prepend + "mbLG"] == 0 && data[prepend + "mbLG"] != data[prepend + "mbXL"])
        className += (className ? " " : "") + "cm-no-bottom-margin-lg";

    // MD

    if(
        (data[prepend + "mtMD"] != 0 || data[prepend + "mbMD"] != 0) &&
        (
            data[prepend + "mtMD"] != data[prepend + "mtLG"] || 
            data[prepend + "mbMD"] != data[prepend + "mbLG"]
        )
    )
    {
        styles["--cm-margin-md"] = data[prepend + "mtMD"] + "px 0 "+ data[prepend + "mbMD"] + "px 0";
        styleClassName += (styleClassName ? " " : "") + "cm-element-margin-md";
    }

    if(data[prepend + "mtMD"] == 0 && data[prepend + "mtMD"] != data[prepend + "mtLG"])
        className += (className ? " " : "") + "cm-no-top-margin-md";

    if(data[prepend + "mbMD"] == 0 && data[prepend + "mbMD"] != data[prepend + "mbLG"])
        className += (className ? " " : "") + "cm-no-bottom-margin-md";
    
    // SM

    if(
        (data[prepend + "mtSM"] != 0 || data[prepend + "mbSM"] != 0) &&
        (
            data[prepend + "mtSM"] != data[prepend + "mtMD"] || 
            data[prepend + "mbSM"] != data[prepend + "mbMD"]
        )
    )
    {
        styles["--cm-margin-sm"] = data[prepend + "mtSM"] + "px 0 "+ data[prepend + "mbSM"] + "px 0";
        styleClassName += (styleClassName ? " " : "") + "cm-element-margin-sm";
    }

    if(data[prepend + "mtSM"] == 0 && data[prepend + "mtSM"] != data[prepend + "mtMD"])
        className += (className ? " " : "") + "cm-no-top-margin-sm";

    if(data[prepend + "mbSM"] == 0 && data[prepend + "mbSM"] != data[prepend + "mbMD"])
        className += (className ? " " : "") + "cm-no-bottom-margin-sm";

    // XS

    if(
        (data[prepend + "mtXS"] != 0 || data[prepend + "mbXS"] != 0) &&
        (
            data[prepend + "mtXS"] != data[prepend + "mtSM"] || 
            data[prepend + "mbXS"] != data[prepend + "mbSM"]
        )
    )
    {
        styles["--cm-margin-xs"] = data[prepend + "mtXS"] + "px 0 "+ data[prepend + "mbXS"] + "px 0";
        styleClassName += (styleClassName ? " " : "") + "cm-element-margin-xs";
    }
    if(data[prepend + "mtXS"] == 0 && data[prepend + "mtXS"] != data[prepend + "mtSM"])
        className += (className ? " " : "") + "cm-no-top-margin-xs";

    if(data[prepend + "mbXS"] == 0 && data[prepend + "mbXS"] != data[prepend + "mbSM"])
        className += (className ? " " : "") + "cm-no-bottom-margin-xs";

    // XXS

    if(
        (data[prepend + "mtXXS"] != 0 || data[prepend + "mbXXS"] != 0) &&
        (
            data[prepend + "mtXXS"] != data[prepend + "mtXS"] || 
            data[prepend + "mbXXS"] != data[prepend + "mbXS"]
        )
    )
    {
        styles["--cm-margin-xxs"] = data[prepend + "mtXXS"] + "px 0 "+ data[prepend + "mbXXS"] + "px 0";
        styleClassName += (styleClassName ? " " : "") + "cm-element-margin-xxs";
    }

    if(data[prepend + "mtXXS"] == 0 && data[prepend + "mtXXS"] != data[prepend + "mtXS"])
        className += (className ? " " : "") + "cm-no-top-margin-xxs";

    if(data[prepend + "mbXXS"] == 0 && data[prepend + "mbXXS"] != data[prepend + "mbXS"])
        className += (className ? " " : "") + "cm-no-bottom-margin-xxs";
    

    return {styles,className,styleClassName}
}

export const GetElementFontSize = (data,prepend = "") => {

    var className = "";

    if(data[prepend + "fsXL"])
        className  += "cm-fs-" + data[prepend + "fsXL"];

    if(data[prepend + "fsLG"] && data[prepend + "fsLG"] != data[prepend + "fsXL"])
        className  += " cm-fs-lg-"  + data[prepend + "fsLG"];

    if(data[prepend + "fsMD"] && data[prepend + "fsMD"] != data[prepend + "fsLG"])
        className  += " cm-fs-md-"  + data[prepend + "fsMD"];

    if(data[prepend + "fsSM"] && data[prepend + "fsSM"] != data[prepend + "fsMD"])
        className  += " cm-fs-sm-"  + data[prepend + "fsSM"] ;

    if(data[prepend + "fsXS"] && data[prepend + "fsXS"] != data[prepend + "fsSM"])
        className  += " cm-fs-xs-"  + data[prepend + "fsXS"];

    if(data[prepend + "fsXXS"] && data[prepend + "fsXXS"] != data[prepend + "fsXS"])
        className += " cm-fs-xxs-" + data[prepend + "fsXXS"];

    return className;

}

export const GetMediaDimensionsStyles = (data) => {

    var styles = {};  
    var styleClassName = "";

    styles["--cm-media-width"]  =  data.widthXL + "px ";
    styles["--cm-media-height"] =  data.heightXL + "px ";
    styleClassName += "cm-has-dimensions";
    

    if((data.widthLG != data.widthXL || data.heightLG != data.heightXL))
    {
        styles["--cm-media-width-lg"]  =  data.widthLG + "px ";
        styles["--cm-media-height-lg"] =  data.heightLG + "px ";
        styleClassName += " cm-has-dimensions-lg";
    }

    if((data.widthMD != data.widthLG || data.heightMD != data.heightLG))
    {
        styles["--cm-media-width-md"]  =  data.widthMD + "px ";
        styles["--cm-media-height-md"] =  data.heightMD + "px ";
        styleClassName += " cm-has-dimensions-md";
    }

    if((data.widthSM != data.widthMD || data.heightSM != data.heightMD))
    {
        styles["--cm-media-width-sm"]  =  data.widthSM + "px ";
        styles["--cm-media-height-sm"] =  data.heightSM + "px ";
        styleClassName += " cm-has-dimensions-sm";
    }

    if((data.widthXS != data.widthSM || data.heightXS != data.heightSM))
    {
        styles["--cm-media-width-xs"]  =  data.widthXS + "px ";
        styles["--cm-media-height-xs"] =  data.heightXS + "px ";
        styleClassName += " cm-has-dimensions-xs";
    }

    if((data.widthXXS != data.widthXS || data.heightXXS != data.heightXS))
    {
        styles["--cm-media-width-xxs"]  =  data.widthXXS + "px ";
        styles["--cm-media-height-xxs"] =  data.heightXXS + "px ";
        styleClassName += " cm-has-dimensions-xxs";
    }

    return {styles,styleClassName}

}

export const GetOpacityClass = (value) => {

    var className = "";
    switch(value)
    {
        case "10": className = ""; break;
        case "9": className = "cm-opacity-9"; break;
        case "8": className = "cm-opacity-8"; break;
        case "7": className = "cm-opacity-7"; break;
        case "6": className = "cm-opacity-6"; break;
        case "5": className = "cm-opacity-5"; break;
        case "4": className = "cm-opacity-4"; break;
        case "3": className = "cm-opacity-3"; break;
        case "2": className = "cm-opacity-2"; break;
        case "1": className = "cm-opacity-1"; break;
        case "0": className = "cm-opacity-0"; break;
    }

    return className;
}

export const GetColClasses = (data) => {

    var colClasses = "";

    if(data.colsXL)
        colClasses  += "cm-col-" + data.colsXL;

    if(data.colsLG && data.colsLG != data.colsXL)
        colClasses  += " cm-col-lg-"  + data.colsLG;

    if(data.colsMD && data.colsMD != data.colsLG)
        colClasses  += " cm-col-md-"  + data.colsMD;

    if(data.colsSM && data.colsSM != data.colsMD)
        colClasses  += " cm-col-sm-"  + data.colsSM ;

    if(data.colsXS && data.colsXS != data.colsSM)
        colClasses  += " cm-col-xs-"  + data.colsXS;

    if(data.colsXXS && data.colsXXS != data.colsXS)
        colClasses += " cm-col-xxs-" + data.colsXXS;

    if(data.elements && data.elements.length === 0)
        colClasses += " empty";

    return colClasses;

}

export const GetEventData = (e,val) => {

    var n,v;

    if(!e.target)
    {
        v = val;
        n = e;
    }
    else
    {
        const t = e.target;
        if(t.type === 'checkbox')
            v = t.checked
        else if(t.type === 'radio')
            v = parseInt(t.value);
        else
            v = t.value;

        n = t.name;
    }

    return {n,v}
}

export const GetTabsForResponsiveDesign = (content) => {

    const responziveTabs = [
        {
            tabContent:<div className="cm-responzive-tab" title = {"1400px a více"} ><img alt={""} src={monitorIcon} />monitor</div>,
            content:content.XL
        },{
            tabContent:<div className="cm-responzive-tab" title = {"1200px až 1399px"} ><img alt={""} src={laptopIcon} />notebook</div>,
            content:content.LG
        },{
            tabContent:<div className="cm-responzive-tab" title = {"992px až 1199px"} ><img alt={""} src={tabletWideIcon} />tablet</div>,
            content:content.MD
        },{
            tabContent:<div className="cm-responzive-tab" title = {"768px až 991px"} ><img alt={""} src={ipadIcon} />šir. tablet</div>,
            content:content.SM
        },{
            tabContent:<div className="cm-responzive-tab" title = {"576px až 767px"} ><img alt={""} src={tabletIcon} />tablet</div>,
            content:content.XS
        },{
            tabContent:<div className="cm-responzive-tab" title = {"menší než 576px"}><img alt={""} src={mobileIcon} />mobil</div>,
            content:content.XXS
        },
    ]

    return responziveTabs;

}

export const GetYoutubeVideoID = ( name, url ) => {
       
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
}