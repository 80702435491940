import { useMutation } from "@apollo/client";
import { ADD_EDIT_BANNER } from "../Queries/banner";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditBanner = (selectedBannerID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage
    } = UseFormHandle({
        selectedLang:lang,
        bannerTypeID:0,
        seconds:0,
        active:1,
        forLang:"",
        langs:[]
    });

    const [AddEditBannerMutation,{error,loading}] = useMutation(ADD_EDIT_BANNER,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.addEditReview);

            const notifyText = (selectedBannerID != 0 ? t("SuccessfullyUpdated") : t("NewBannerIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditBanner = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(formData.forLang != "")
            {
                if(val.lang == formData.forLang && val.image === null)
                    check = false;
            }
            else if(val.image === null)
                check = false;
        }

        if(check || selectedBannerID != 0)
        {
            if(formData.bannerTypeID != 0)
            {
                let langs = [];
                for(const lang of formData.langs)
                {
                    langs.push({
                        name:        lang.name,  
                        discount:    lang.discount,             
                        buttonName:  lang.buttonName,       
                        price:       lang.price,                 
                        description: lang.description,  
                        url:         lang.url,
                        image:       lang.image, 
                        lang:        lang.lang
                    });
                }

                AddEditBannerMutation({
                    variables:{
                        bannerID:           selectedBannerID,
                        bannerTypeID:       formData.bannerTypeID,
                        forLang:            formData.forLang,
                        seconds:            parseInt(formData.seconds),
                        active:             parseInt(formData.active),
                        langs:              langs
                    }
                })
            }
            else
                AddNotification(t("BannerTypeIsNecesary"),false); 
        }
        else
            AddNotification(t("NoImageNoGo"),false); 

    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditBanner,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        OpenLangImage
    }

}

export default UseAddEditBanner;