import ModalHeader from "../../../../GlobalComponents/Modal/ModalHeader"
import { useTranslation } from "react-i18next";
import { UseContentManager } from "../../Library/UseContentManager";
import Button from "../../../../GlobalComponents/Button";
import { GET_ALL_VIDEOS } from "../../Queries/fileManager";
import Loading from "../../../../GlobalComponents/Loading";
import Error from "../../../../GlobalComponents/Error";
import { useQuery,useApolloClient } from "@apollo/client";
import NoItems from "../../../../GlobalComponents/NoItems";
import { CONTENT_MANAGER_VIDEO_URL, SERVER_URL } from "../../../../Config";
import Checkbox from "../../../../GlobalComponents/Checkbox";
import UseDeleteVideos from "../../Library/FileManager/UseDeleteVideos";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { useState } from "react";
import AddVideo from "./AddVideo";

const VideosList = () => {

    const client = useApolloClient();
    const {t}    = useTranslation();
    const {openFileManagerData,CloseFileManager,ChooseFileManagerVideo} = UseContentManager();

    const [showAddVideo, SetShowAddVideo] = useState(false);

    const { 
        selectedItems, 
        showDeleteNotification, 
        DeleteVideos,
        SelectVideo,
        ShowDeleteNotification 
    } = UseDeleteVideos(client);

    const {data,loading,error} = useQuery(GET_ALL_VIDEOS,{
        fetchPolicy:"network-only"
    });

    var err = "";
    if(error)
        err = GetApolloErrorText(error);

    return(
        <>
            <ModalHeader 
                title = {t("VideosList")}
                OnClose ={() => CloseFileManager()}
            >
                <Button
                    className = "btn-primary"
                    OnClick = {() => SetShowAddVideo(true)}
                >
                    {t("UploadVideo")}
                </Button>

                <Button
                    className = "btn-brown"
                    OnClick = {() => ShowDeleteNotification(true)}
                >
                    {t("DeleteSelected")}
                </Button>

            </ModalHeader>

            <div className="modal-body">

                {err ?
                    <Error text = {err} />
                :
                    (loading ?
                        <Loading />
                    :
                        (data.AllContentManagerVideos.length > 0 ?
                            <div className="row">
                                {data.AllContentManagerVideos.map((item,index) => {
                                    
                                    var check = false;
                                    for(let val of selectedItems)
                                    {
                                        if(val == item.contentManagerVideoID)
                                            check = true;   
                                    }

                                    return(
                                        <div key={item.contentManagerVideoID} className="col-20">
                                            <div className="cm-image-container form-group">
                                                <div className="select">
                                                    <Checkbox 
                                                        text = {""}
                                                        name = "selectedImage"
                                                        checked = {check}
                                                        OnChange = {() => SelectVideo(item.contentManagerVideoID)}
                                                    />
                                                </div>
                                                <video 
                                                    src = {SERVER_URL + "/" + CONTENT_MANAGER_VIDEO_URL + "/" + item.name} 
                                                    onClick = {() => ChooseFileManagerVideo(openFileManagerData,item.name,item.poster)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <NoItems
                                className = {"justify-content-center no-margin"}
                                text={t("NoVideosHereYet")} 
                            />
                        )
                    )
                }
                
            </div>
            <div className="modal-footer"></div>

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteVideos")} 
                    CallBack={DeleteVideos} 
                />
            :null}

            <AddVideo 
                showAddVideo = {showAddVideo}
                SetShowAddVideo = {SetShowAddVideo}
            />
        </>
    )
}

export default VideosList