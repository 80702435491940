import { useState } from "react";
const UseFormHandle = (formData) => {

    const [newFormData, SetNewFormdata] = useState(formData);

    const SetFormData = (e,val) => {

        var {n,v} = GetEventData(e,val);
        
        SetNewFormdata((prevValue) => {
            return {...prevValue,[n]: v}
        });
    }

    const SetCompleteFormData = (data) => {
        SetNewFormdata((prevValue) => {
            return {...prevValue,...data}
        });
    }

    const SetFormLangData = (e,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            let langs = [...prevValue.langs];
            for(let i in langs)
            {
                if(langs[i].lang == prevValue.selectedLang)
                {
                    langs[i] = {...langs[i],[n] : v};
                }
            }

            return {...prevValue,langs: langs}
        });
    }

    const OpenLangImage = (e) => {
        
        var param = e.target.name;
        var photo = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {

            var dataURL = reader.result;
            let langs = [...newFormData.langs];

            for(let i in langs)
            {
                if(langs[i].lang == newFormData.selectedLang)
                    langs[i] = {...langs[i],[param] : photo,[param + "Show"] : dataURL};
            }

            SetNewFormdata({...newFormData,langs: langs});

        };

        reader.readAsDataURL(photo);
    }

    const OpenImage = (e) => {
        
        var photo = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {

            var dataURL = reader.result;
            SetNewFormdata({...newFormData,photo: photo,mainPhoto:dataURL});

        };

        reader.readAsDataURL(photo);
    }

    const OpenMedia = (e,param) => {
        
        var media = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {
            SetNewFormdata({...newFormData,[param]: media});
        };

        reader.readAsDataURL(media);
    }

    const GetFormLangValue = (param) => {

        for(const lang of newFormData.langs){
            if(lang.lang == newFormData.selectedLang){
                if(lang[param] === null){
                    return "";
                }else{
                    return lang[param];
                }
            }
        }
        return "";
    }

    const FillFormSelectedItems = (param,value) => {

        var arr = [...newFormData[param]];

        const index = arr.indexOf(value);
        if(index != -1)
            arr.splice(index,1);
        else
            arr.push(value);

        SetNewFormdata({...newFormData,[param]: arr});
    }

    const FillFormSelectedItemsWithObj = (param,paramID,obj,useDelete) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[param]];
            var checked = false;

            for(let i in arr)
            {
                if(arr[i][paramID] == obj[paramID])
                {
                    checked = true;
                    if(arr[i].deleted == undefined || arr[i].deleted == false)
                    {
                        if(useDelete && !arr[i].add)
                            arr[i] = {...arr[i],deleted:true}
                        else
                            arr.splice(i,1);
                        break;
                    }
                    else if(arr[i].deleted)
                        arr[i] = {...arr[i],deleted:false}
                }
            }

            if(!checked)
                arr.push(obj);

            return {...prevValue,[param]: arr};
        })
        
    }

    const SetFormDataSelectedItemsWithObj = (e,objParam,objParamID,paramID,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            var check = false;

            for(let i in arr)
            {
                if(arr[i][objParamID] == paramID)
                {
                    check = true;
                    arr[i] = {...arr[i],[n]:v}
                }
            }

            if(!check)
            {
                arr.push({
                    [objParamID]:paramID,
                    [n]:v
                })
            }

            return {...prevValue,[objParam]: arr}
        })
    }

    const SetBooleanFormDataSelectedItemsWithObj = (param,objParam,objParamID,paramID) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];

            for(let i in arr)
            {
                if(arr[i][objParamID] == paramID)
                    arr[i] = {...arr[i],[param]:true}
            else
                    arr[i] = {...arr[i],[param]:false}
            }

            return {...prevValue,[objParam]: arr}

        });
    }

    const SetArrayValue = (objParam,index,val) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            arr[index] = val;
            
            return {...prevValue,[objParam]: arr}
        })
    }

    const SetArrayObjValue = (e,objParam,index,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            arr[index] = {...arr[index],[n]:v}
            
            return {...prevValue,[objParam]: arr}
        })
    }

    const RemoveFormDataSelectedItems = (param,paramID,IDs) => {

        SetNewFormdata((prevValue) => {

            var newArray = [...prevValue[param]];
            
            for(let id of IDs)
            {
                for(let i in newArray)
                {
                    if(newArray[i][paramID] == id)
                        newArray.splice(i,1);
                } 
            } 

            return {...prevValue,[param]: newArray}

        });
    }

    const SetFormDataSelectedItemsPriority = (param,oldIndex,newIndex) => {

        SetNewFormdata((prevValue) => {

            var newArray = [...prevValue[param]];
            
            const elm = newArray[oldIndex];

            newArray = [...newArray.slice(0,oldIndex),...newArray.slice(oldIndex + 1)];
            newArray = [...newArray.slice(0,newIndex),elm,...newArray.slice(newIndex)];

            for(let i in newArray)
            {
                newArray[i] = {...newArray[i],priority:parseInt(i) + 1} 
            }

            return {...prevValue,[param]: newArray}

        });
    }

    const GetEventData = (e,val) => {

        var n,v;
    
        if(!e.target)
        {
            v = val;
            n = e;
        }
        else
        {
            const t = e.target;
            if(t.type === 'checkbox')
                v = t.checked
            else if(t.type === 'radio')
                v = parseInt(t.value);
            else
                v = t.value;
    
            n = t.name;
        }
    
        return {n,v}
    }
   
    return{
        formData:newFormData,
        SetFormData,
        SetNewFormdata,
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage,
        OpenImage,
        OpenMedia,
        FillFormSelectedItems,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        SetArrayValue,
        SetArrayObjValue,
        SetFormDataSelectedItemsPriority
    }
}

export default UseFormHandle;