import gql from 'graphql-tag';

export const ADD_EDIT_PARAMETER = gql`
    mutation AddEditParameter(
        $parameterID:ID,
        $filterOperationTypeID:ID,
        $isColor:Int,
        $langs:[ParameterLangsInput]
        $values:[ParameterValueInput]
    ){
        AddEditParameter(
            parameterID:$parameterID,
            filterOperationTypeID:$filterOperationTypeID,
            isColor:$isColor,
            langs:$langs,
            values:$values
        ){
            parameterID
            name
        }
    }
`;

export const GET_PARAMETER = gql`
    query ParameterWithLangs($parameterID: ID){
        ParameterWithLangs(parameterID:$parameterID){
            parameterID
            isColor
            filterOperationTypeID
            langs{
                parameterLangID
                lang
                name
                unit
            }    
            values{
                parameterValueID
                colorCode
                langs{
                    parameterValueLangID
                    lang
                    value
                } 
            }
        }
        AllFilterOperationTypes{
            filterOperationTypeID
            name
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_PARAMETERS = gql`
    query AllParameters($lang: String,$limit:Int,$offset:Int,$searchText:String){
        AllParameters(lang:$lang,limit:$limit,offset:$offset,searchText:$searchText){
            parameterID
            name
            allowEdit
            allowDelete
        }
        AllParametersCount(lang:$lang,searchText:$searchText)
    }
`;

export const DELETE_PARAMETERS = gql`
    mutation DeleteParameters($parameterIDs:ID) {
        DeleteParameters(parameterIDs:$parameterIDs)
    }
`;

export const GET_PARAMETERS_WITH_VALUES = gql`
    query AllParameters($lang: String,$limit:Int,$offset:Int){
        AllParameters(lang:$lang,limit:$limit,offset:$offset){
            parameterID
            name
            isColor
            filterOperationTypeID
            values{
                parameterValueID
                value
                colorCode
            }
        }
    }
`;