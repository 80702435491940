import { useMutation } from "@apollo/client";
import { UPDATE_LINK_PRIORITY,GET_LINKS } from "../Queries/link";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseSortableList = (client,lang,isEshopCategory) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [UpdateLinkPriority] = useMutation(UPDATE_LINK_PRIORITY,{
        onCompleted:(d) => {

            const notifyText = t("NewPositionSaved");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const Reorder = (list, startIndex, endIndex) => {
        
        var newSublinks = [...list];
    
        const [removed] = newSublinks.splice(startIndex, 1);
        newSublinks.splice(endIndex, 0, removed);
      
        return newSublinks;
    };

    const GoThroughList = (listData,parentID,startIndex, endIndex) => {

        var arr = [...listData];

        if(parentID == 0)
            arr = Reorder(arr,startIndex, endIndex);
        else
        {
            for(let i = 0; i < arr.length; i++)
            {
                if(parentID == arr[i].linkID)
                {
                    var newSublinks = Reorder(arr[i].subLink,startIndex, endIndex); 
                    arr[i] = {...arr[i],subLink:newSublinks}
                }
                else if(arr[i].subLink)
                {
                    arr[i] = {...arr[i],subLink:GoThroughList(arr[i].subLink,parentID,startIndex, endIndex)}
                }
            }
        }
        
        return arr;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {
            const variables = {
                lang,
                isEshopCategory:(isEshopCategory ? 1 : 0)
            }

            const IDs = result.draggableId.split("-");

            const { AllLinks } = client.readQuery({ query: GET_LINKS, variables:variables });
            const newArray = GoThroughList(AllLinks,IDs[1],result.source.index,result.destination.index);

            client.writeQuery({ 
                query:GET_LINKS,
                variables:variables,
                data:{
                    AllLinks: newArray
                } 
            });

            UpdateLinkPriority({
                variables:{
                    parentID: IDs[1],
                    linkID: IDs[0],
                    fromIndex:result.source.index,
                    toIndex:  result.destination.index
                }
            })
        }
    } 

    return {
        OnDragEnd
    }

}

export default UseSortableList;