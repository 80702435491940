import { useTranslation } from "react-i18next";
import Input from "../../../../../GlobalComponents/Input";
import WindowWidthResponziveInfo from "../WindowWidthResponziveInfo";
import Select from "../../../../../GlobalComponents/Select";

const HeadlineResponziveSettings = ({size,formData,OnChange}) => {

    const {t} = useTranslation();

    return (
        <>
            <WindowWidthResponziveInfo size={size} />
            
            <div className="row">
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("HeadlineFontSize")}</label>
                        <Select 
                            type = "number"
                            name = {formData.selectedTag + "fs" + size}
                            value = {formData[formData.selectedTag + "fs" + size]}
                            OnChange = {(e) => OnChange(e)}
                        >
                            <option value="16">16px</option>
                            <option value="18">18px</option>
                            <option value="20">20px</option>
                            <option value="22">22px</option>
                            <option value="24">24px</option>
                            <option value="26">26px</option>
                            <option value="28">28px</option>
                            <option value="30">30px</option>
                            <option value="35">35px</option>
                            <option value="40">40px</option>
                            <option value="45">45px</option>
                            <option value="50">50px</option>
                            <option value="55">55px</option>
                            <option value="60">60px</option>
                            <option value="65">65px</option>
                            <option value="70">70px</option>
                            <option value="75">75px</option>
                            <option value="80">80px</option>
                            <option value="85">85px</option>
                            <option value="90">90px</option>
                            <option value="95">95px</option>
                            <option value="100">100px</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("SubHeadlineFontSize")}</label>
                        <Select 
                            type = "number"
                            name = {"subHfs" + size}
                            value = {formData["subHfs" + size]}
                            OnChange = {(e) => OnChange(e)}
                        >
                            <option value="12">12px</option>
                            <option value="14">14px</option>
                            <option value="16">16px</option>
                            <option value="18">18px</option>
                            <option value="20">20px</option>
                            <option value="22">22px</option>
                            <option value="24">24px</option>
                            <option value="26">26px</option>
                            <option value="28">28px</option>
                            <option value="30">30px</option>
                            <option value="35">35px</option>
                            <option value="40">40px</option>
                            <option value="45">45px</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingTop")}</label>
                        <Input 
                            type = "number"
                            name = {formData.selectedTag + "mt" + size}
                            value = {formData[formData.selectedTag + "mt" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingBottom")}</label>
                        <Input 
                            type = "number"
                            name = {formData.selectedTag + "mb" + size}
                            value = {formData[formData.selectedTag + "mb" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeadlineResponziveSettings;