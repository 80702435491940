import {useQuery} from '@apollo/client';
import { GET_ORDERS } from '../Queries/order';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllOrders = (lang) => {

    const [variables,SetVariables] = useState({
        searchText:"",
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedOrderIDs,SetSelectedOrderIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ORDERS,{
        variables,
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllOrders);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("OrderNumber"),
            className:"flex-1"
        },
        {
            value: t("OrderDate"),
            className:"static text-center"
        },
        {
            value: t("NameAndSurname"),
            className:"static text-center"
        },
        {
            value: t("Email"),
            className:"flex-1 text-center"
        },
        {
            value: t("Tel"),
            className:"static text-center"
        },
        {
            value: t("ComgateStatus"),
            className:"static text-center"
        },
        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.orderNumber,
                        className:"flex-1"
                    },
                    {
                        value:val.orderDate,
                        className:"static text-center"
                    },
                    {
                        value: val.name + " " + val.surname,
                        className:"static text-center"
                    },
                    {
                        value: val.email,
                        className:"flex-1 text-center"
                    },
                    {
                        value: val.tel,
                        className:"static text-center"
                    },
                    {
                        value: val.onlinePaymentStatus,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.orderID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,orderID) => {

        var checked = e.target.checked;
        var newSelectedOrderIDs = [...selectedOrderIDs];
        const newList = SelectOrderID(content,orderID,checked,newSelectedOrderIDs)

        SetContent(newList);
        SetSelectedOrderIDs(newSelectedOrderIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedOrderIDs = [];
        const newList = SelectOrderID(content,"all",checked,newSelectedOrderIDs)

        SetSelectedOrderIDs(newSelectedOrderIDs);
        SetContent(newList);
    }

    const SelectOrderID = (list,orderID,checked,selectedOrderIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(orderID == newList[i].rowID || orderID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedOrderIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedOrderIDs.length; j++)
                    {
                        if(selectedOrderIDs[j] == newList[i].rowID)
                            selectedOrderIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectOrderID(newList[i].subData,orderID,checked,selectedOrderIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedOrderIDs,
        totalContentLength: data && data.AllOrdersCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllOrders;