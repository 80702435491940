import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import UseGetAllProductCovers from "../../Library/Products/UseGetAllProductCovers";
import excludedIcon from '../../../../Media/Images/Icons/excluded.webp';
import InputCard from "../../../../GlobalComponents/InputCard";
import NoItems from '../../../../GlobalComponents/NoItems';

const ExcludedCovers = ({
    formData,
    selectedProductID,
    lang,
    SetFormData,
    SetFormDataSelectedItemsWithObj
}) => {

    const {t} = useTranslation();

    const {
        content,
        loading,
        error,
        headerData,
        SelectRow,
        SelectAll
    } = UseGetAllProductCovers(formData.selectedExcludedCovers,formData.selectedCoversPercentage,lang,selectedProductID,SetFormData,SetFormDataSelectedItemsWithObj);

    return(
        <div>
            {selectedProductID && selectedProductID != 0 ?
                <>
                    {error ?
                        <Error text={GetApolloErrorText(error)} />
                    :
                        <>
                            <InputCard
                                title = {t("ExcludedCoversAndIncrease")}
                                icon = {excludedIcon}
                                noBodyPadding = {true}
                                headerInfoContent = {[
                                    t("ExcludedCoversInfo")  
                                ]}
                            > 
                                <List 
                                    headerData = {headerData} 
                                    contentData = {content}
                                    headerClassName = {"no-top-border"}
                                    isSortable = {false}
                                    loading = {loading}
                                    OnSelectAll = {(e) => SelectAll(e)}
                                    hideOptions = {true}
                                    options = {{
                                        OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                    }}
                                />
                            </InputCard>
                        </>
                    }

                </>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddChooseExcludedCoversSaveBasicInformationsFirst")} 
                />   
            }

        </div>
    )
}

export default ExcludedCovers;