import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins,GetElementFontSize, GetMediaDimensionsStyles } from "../../Library/functions";
import { SERVER_URL,CONTENT_MANAGER_IMAGE_URL } from "../../../../Config";
import TinyMCEEditor from "../TinyMCEEditor";

const Image = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement,SetSettings} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);
    var className = GetElementFontSize(data,"overlay");

    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};

    var imgConent = null;
    if(data.useDimensions == "1")
        imgConent = GetMediaDimensionsStyles(data);

    const OnEditorChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"overlayText",content,"image")
    }

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "image"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div 
                className={data.className + " " + margins.styleClassName + (data.fileName ? " cm-d-flex cm-justify-content-" + data.imageAlign : " d-flex")} 
                style={margins.styles}
            >
                {data.fileName ?
                    <div className={"cm-image-content " + (imgConent && imgConent.styleClassName)} style={imgConent && imgConent.styles}>
                       
                        <img style={borderRadiusStyle} src = {SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/" + data.imageSize + "_" + data.fileName} alt="" />
                        
                        {data.useOverlayByText == 1 ?
                            <div className={"cm-no-bottom-margin cm-no-top-margin cm-image-overlay cm-justify-content-" + data.overlayHorizontalAlign + " cm-align-items-" + data.overlayVerticalAlign + " " + data.overlayColor}>
                                <div className = {"cm-overlay-headline " + className}>
                                    <TinyMCEEditor 
                                        value = {data.overlayText}
                                        OnEditorChange={(content) => OnEditorChange(content)}
                                        toolbar = 'italic'
                                        forcedRootBlock = "h3"
                                    />
                                </div>
                                <div style={borderRadiusStyle} className={"bg cm-opacity-" + data.overlayOpacity}></div>
                            </div>
                        :null}
                    </div>
                :
                    <div className="cm-start-info">Pro vložení obrázku použijte ikonku ozubeného kolečka "Nastavení"</div>
                }
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Image;