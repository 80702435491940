import {useQuery} from '@apollo/client';
import { GET_LABELS } from '../../Queries/labels';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import YesNo from '../../../../GlobalComponents/YesNo';

const UseGetAllLabels = (lang) => {

    const [variables,SetVariables] = useState({
        lang,
        limit:40,
        offset:0
    });

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedLabelIDs,SetSelectedLabelIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_LABELS,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllLabels: [...prev.AllLabels, ...fetchMoreResult.AllLabels]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllLabels);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("LabelName"),
            className:"flex-1"
        },
        {
            value: t("Active"),
            className:"static text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value: <YesNo isYes = {!!val.active} />,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.labelID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,labelID) => {

        var checked = e.target.checked;
        var newSelectedLabelIDs = [...selectedLabelIDs];
        const newList = SelectLabelID(content,labelID,checked,newSelectedLabelIDs)

        SetContent(newList);
        SetSelectedLabelIDs(newSelectedLabelIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedLabelIDs = [];
        const newList = SelectLabelID(content,"all",checked,newSelectedLabelIDs)

        SetSelectedLabelIDs(newSelectedLabelIDs);
        SetContent(newList);
    }

    const SelectLabelID = (list,labelID,checked,selectedLabelIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(labelID == newList[i].rowID || labelID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedLabelIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedLabelIDs.length; j++)
                    {
                        if(selectedLabelIDs[j] == newList[i].rowID)
                            selectedLabelIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectLabelID(newList[i].subData,labelID,checked,selectedLabelIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = (selectedLabelID,data) => {
        
        const { AllLabels, AllLabelsCount } = client.readQuery({ query: GET_LABELS, variables });
        var newArray = [...AllLabels];
        var newAllLabelsCount = AllLabelsCount;

        if(selectedLabelID && selectedLabelID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].labelID == data.labelID)
                {
                    newArray[i] = {...newArray[i],
                        name: data.name,
                        active: data.active
                    };
                }
            } 
        }
        else
        {
            newAllLabelsCount++
            newArray.unshift({
                labelID: data.labelID,
                name: data.name,
                active: data.active
            })
        }
        
        client.writeQuery({ 
            query:GET_LABELS,
            variables,
            data:{
                AllLabels: newArray,
                AllLabelsCount: newAllLabelsCount
            } 
        });
    }

    const UpdateListAfterDelete = (IDs) => {

        var response = IDs.split(",");
        
        const { AllLabels,AllLabelsCount } = client.readQuery({ query: GET_LABELS, variables });
        
        var newArray = [...AllLabels];
        var newAllLabelsCount = AllLabelsCount;
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].labelID == id)
                {
                    newArray.splice(i,1);
                    newAllLabelsCount--
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_LABELS,
            variables,
            data:{
                AllLabels: newArray,
                AllLabelsCount: newAllLabelsCount
            } 
        });
    }

    return{
        content,
        totalContentLength: data && data.AllLabelsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedLabelIDs,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllLabels;