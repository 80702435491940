import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditCoverTitle from "./AddEditCoverTitle";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import UseDeleteCoverTitles from "../../Library/CoverTitles/UseDeleteCoverTitles";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import UseGetAllCoverTitles from "../../Library/CoverTitles/UseGetAllCoverTitles";
import { useApolloClient } from "@apollo/client";
import UseSortCoverTitle from "../../Library/CoverTitles/UseSortCoverTitle";

const CoverTitles = () => {

    const {user} = UseAuth();
    const {t}    = useTranslation();
    const client = useApolloClient();

    const [selectedCoverTitleID, SetSelectedCoverTitleID] = useState(null);
    
    const {
        content,
        loading,
        error,
        headerData,
        selectedCoverTitleIDs,
        variables,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllCoverTitles(user.lang);

    const {OnDragEnd} = UseSortCoverTitle(client,variables);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteCoverTitles
    } = UseDeleteCoverTitles(selectedCoverTitleIDs,(d) => UpdateListAfterDelete(d));

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("CoverTitleList")}
                        OnAddButtonClick={() => SetSelectedCoverTitleID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {true}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        OnDragListRowEnd = {(result) => OnDragEnd(result)}
                        options = {{
                            OnEditClick: (coverTitleID) => SetSelectedCoverTitleID(coverTitleID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedCoverTitleID || selectedCoverTitleID == 0 ?
                <AddEditCoverTitle
                    selectedCoverTitleID = {selectedCoverTitleID}
                    SetSelectedCoverTitleID = {SetSelectedCoverTitleID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedCoverTitleID,d);
                        SetSelectedCoverTitleID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteCoverTitles")} 
                    CallBack={DeleteCoverTitles} 
                />
            :null}

        </>
    )
}

export default CoverTitles;