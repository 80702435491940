import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_TICKLABEL } from "../../Queries/tickLabels";

const UseGetTickLabel = (selectedTickLabelID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const {loading,error} = useQuery(GET_TICKLABEL,{
        variables:{
            tickLabelID:selectedTickLabelID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",                    
            }

            if(selectedTickLabelID != 0)
            {
                let langs = CompleteLangsArray(d.TickLabelWithLangs.langs,d.AllLanguageMutations,emptyLang);

                CallBack({
                    selectedLang: lang,
                    active:       d.TickLabelWithLangs.active,
                    langs:        langs,
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    return{
        loading: (loading),
        error: (error),
        allLanguageMutations
    }

}

export default UseGetTickLabel;