import user from '../Media/Images/Icons/user.webp';
import logout from '../Media/Images/Icons/logout.webp';
import orders from '../Media/Images/Icons/orders.webp';
import eshop from '../Media/Images/Icons/eshop.webp';
import content from '../Media/Images/Icons/content.webp';
import newsletterIcon from '../Media/Images/Icons/newsletter.webp';
import settings from '../Media/Images/Icons/settings.webp';

import { useTranslation } from 'react-i18next';

export const GetHeaderLinks = () => {

    const {t} = useTranslation();

    const links = [
        {
            id:1,
            name:t("Orders"),
            icon:orders,
            link:"/orders",
            sublinks:[]
        },
        /*
        {
            id:2,
            name:t("Customers"),
            icon:user,
            link:"/customers",
        },*/
        {
            id:3,
            name:t("Eshop"),
            icon:eshop,
            isEshop:true,
            sublinks:[
                {
                    name:t("Products"),
                    link:"/eshop/products",
                },
                {
                    name:t("Categories"),
                    link:"/eshop/categories",
                },
                {
                    name:t("Parameters"),
                    link:"/eshop/parameters",
                },
                {
                    name:t("ParameterTemplates"),
                    link:"/eshop/parameter-templates",
                },
                {
                    name:t("Manufacturers"),
                    link:"/eshop/manufacturers",
                },
                {
                    name:t("Covers"),
                    link:"/eshop/covers",
                },
                {
                    name:t("CoverTitles"),
                    link:"/eshop/cover-titles",
                },
                {
                    name:t("Labels"),
                    link:"/eshop/labels",
                },
                {
                    name:t("TickLabels"),
                    link:"/eshop/ticklabels",
                },
                {
                    name:t("Coupons"),
                    link:"/eshop/coupons",
                },
                {
                    name:t("ProductReviews"),
                    link:"/eshop/product-reviews",
                    isProductReview:true
                }
            ]
        },
        {
            id:4,
            name:t("Content"),
            icon:content,
            sublinks:[
                {
                    name:t("Links"),
                    link:"/content/links",
                },
                {
                    name:t("Banners"),
                    link:"/content/banners",
                },
                /*
                {
                    name:t("Articles"),
                    link:"/content/customers",
                },
                {
                    name:t("Campaigns"),
                    link:"/content/customers",
                },
                {
                    name:t("Forms"),
                    link:"/content/forms",
                },*/
                {
                    name:t("SocialNetworks"),
                    link:"/content/social-networks",
                },
                {
                    name:t("FbPhotos"),
                    link:"/content/fb-photos",
                },
            ]
        },
        {
            id:5,
            name:t("Newsletter"),
            icon:newsletterIcon,
            link:"/newsletter",
        },
        {
            id:6,
            name:t("Settings"),
            icon:settings,
            sublinks:[
                {
                    name:t("Web"),
                    link:"/settings/web",
                },
                {
                    name:t("ChangePassword"),
                    link:"/settings/change-password",
                }
            ]
        },
        {
            id:7,
            name:t("LogOut"),
            icon:logout,
            link:"/",
            sublinks:[],
            className:"logout"
        }
    ]

    return links;
}

