import { useTranslation } from "react-i18next";
import SearchInput from "../../../../GlobalComponents/SearchInput";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import SelectBox from "../../../../GlobalComponents/SelectBox";
import { useEffect, useState } from "react";
import Button from "../../../../GlobalComponents/Button";

const ProductFilter = ({OnFilter,allLinks}) => {

    const {t} = useTranslation();

    const [subLinks, SetSubLinks] = useState([]);
    const [subSubLinks, SetSubSubLinks] = useState([]);

    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle({
        searchText:"",
        linkID:0,
        subLinkID:0,
        subSubLinkID:0,
        init:true
    })

    useEffect(() => {

        if(!formData.init)
            OnFilter(formData);

    },[formData])
    
    const SetMainCategory = (linkID) => {

        var checked = false;
        for(let val of allLinks)
        {
            if(val.linkID == linkID && val.subLink)
            {
                const subLinks = [{linkID:"0",name:t("---choose---")},...val.subLink]
                SetSubLinks(subLinks);
                checked = true;
            }
        }
        if(!checked && subLinks.length > 0)
        {
            SetSubLinks([]);
            SetSubSubLinks([]);
            SetFormData("subLinkID",0);
            SetFormData("subSubLinkID",0);
        }

        SetFormData("linkID",linkID);
        SetFormData("init",false);
    }

    const SetSubCategory = (linkID) => {

        var checked = false;
        for(let val of subLinks)
        {
            if(val.linkID == linkID && val.subLink)
            {
                const subLinks = [{linkID:"0",name:t("---choose---")},...val.subLink]
                SetSubSubLinks(subLinks);
                checked = true;
            }
        }

        if(!checked && subLinks.length > 0)
        {
            SetSubSubLinks([]);
            SetFormData("subSubLinkID",0);
        }
        
        SetFormData("subLinkID",linkID);
        SetFormData("init",false);
    }

    return(
        <div className="row">

            <div className="col-100">
                <label>{t("SearchProductByName")}</label>
                <div className="form-group">
                    <SearchInput
                        initValue = {formData.searchText}
                        placeholder = {t("Search...")}
                        OnChange = {(value) => {
                            SetFormData("searchText",value);
                            SetFormData("init",false);
                        }}
                    />
                </div>
            </div>
            {allLinks && allLinks.length > 0 ?
                <div className="col-50">
                    <label>{t("ShowProductFromCategory")}</label>
                    <div className="form-group">
                        <SelectBox 
                            value={formData.linkID}
                            OnChange={(value) => SetMainCategory(value)}
                            nameParam = "name"
                            valueParam = "linkID"
                            items={allLinks}
                        />
                    </div>
                </div>
            :null}
            {subLinks && subLinks.length > 0 ?
                <div className="col-50">
                    <label>{t("AndFromSubCategory")}</label>
                    <div className="form-group">
                        <SelectBox 
                            value={formData.subLinkID}
                            OnChange={(value) => SetSubCategory(value)}
                            nameParam = "name"
                            valueParam = "linkID"
                            items={subLinks}
                        />
                    </div>
                </div>
            :null}

            {subSubLinks && subSubLinks.length > 0 ?
                <div className="col-50">
                    <label>{t("AndFromSubCategory")}</label>
                    <div className="form-group">
                        <SelectBox 
                            value={formData.subSubLinkID}
                            OnChange={(value) => SetFormData("subSubLinkID",value)}
                            nameParam = "name"
                            valueParam = "linkID"
                            items={subSubLinks}
                        />
                    </div>
                </div>
            :null}

            <div className="col-100">
                
                <Button 
                    className = "btn-brown form-group"
                    OnClick={() => SetCompleteFormData({
                        searchText:"",
                        linkID:0,
                        subLinkID:0,
                        subSubLinkID:0,
                        init:false
                    })}
                >{t("ClearFilter")}</Button>
                
            </div>

        </div>
    )
}

export default ProductFilter;