import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import UseAddEditProductVariant from '../../Library/Products/UseAddEditProductVariant';
import UseGetProductVariant from '../../Library/Products/UseGetProductVariant';
import { PRODUCT_IMAGE_URL, SERVER_URL } from '../../../../Config';
import VariantBasicInformations from './VariantBasicInformations';
import VariantExcludedCovers from './VariantExcludedCovers';

Modal.setAppElement('#root');

const AddEditVariant = ({
    selectedProductVariantID,
    SetSelectedProductVariantID,
    productID,
    excludedProductCovers
}) => {

    const [selectedTab, SetSelectedTab] = useState(1);
    
    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();
    const {
        loading,
        error,
        formData,
        AddEditProductVariant,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenImage,
        SetFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        GeneratePriceAfterDiscount,
        FillFormSelectedItemsWithObj,
    } = UseAddEditProductVariant(selectedProductVariantID,productID,user.lang,(d,continueToCovers,stayHere) => OnAddEditSuccess(d,continueToCovers,stayHere));

    const {
        allLanguageMutations,
        productSides, 
        coverTitles,
        allProductCovers,
        coverPhotos,
        parameterColors,
        SelectCover,
        loading:getLoading,
        error:getError
    } = UseGetProductVariant(selectedProductVariantID,productID,user.lang,excludedProductCovers,(d) => {

        var initData = {...d};
        initData.mainPhoto =  d.mainPhoto ? SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + productID + "/Variants/stredni_" + d.mainPhoto : "";
        InitFormData(initData);

    });

    const OnAddEditSuccess = async (d,continueToCovers,stayHere) => {
        
        if(continueToCovers)
        {
            SetSelectedProductVariantID(d.productVariantID);
            SetSelectedTab(2);
        }
        else if(!stayHere)
            SetSelectedProductVariantID(null);

        await client.refetchQueries({
            include: ["AllProductVariants"],
        });
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedProductVariantID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedProductVariantID === 0 ? t("AddVariant") : t("EditVariant"))}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedProductVariantID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                <ul className="tabs">
                    <li 
                        className={(selectedTab == 1 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(1)}
                    >{t("BasicInformations")}</li>

                    <li 
                        className={(selectedTab == 2 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(2)}
                    >{t("Covers")}</li>
                    
                </ul>

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                {selectedTab == 1 ?
                                    <VariantBasicInformations
                                        formData = {formData}
                                        SetFormData = {SetFormData}
                                        productSides = {productSides} 
                                        coverTitles = {coverTitles}
                                        allProductCovers = {allProductCovers}
                                        coverPhotos = {coverPhotos}
                                        parameterColors = {parameterColors}
                                        SelectCover = {SelectCover}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                        SetFormLangData = {SetFormLangData}
                                        GetFormLangValue = {GetFormLangValue}
                                        OpenImage = {OpenImage}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                        GeneratePriceAfterDiscount = {GeneratePriceAfterDiscount}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                    />
                                :null}

                                {selectedTab == 2 ?
                                    <VariantExcludedCovers
                                        formData = {formData}
                                        selectedProductID = {productID}
                                        selectedProductVariantID = {selectedProductVariantID}
                                        lang = {user.lang}
                                        SetFormData = {SetFormData}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                    />
                                :null}
                                                                
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
            
                {selectedProductVariantID === 0 ?
                    <div className="row">

                        <div className="col-50">

                            <Button
                                className={"btn-primary w-100"}
                                OnClick={() => AddEditProductVariant(false,false)}
                            >
                                {t("Add")}
                            </Button>

                        </div>
                        <div className="col-50">

                            <Button
                                className={"btn-primary w-100"}
                                OnClick={() => AddEditProductVariant(true,false)}
                            >
                                {t("AddAndGoToCovers")}
                            </Button>

                        </div>

                    </div>
                :
                    <div className="row">

                        <div className="col-50">

                            <Button
                                className={"btn-primary w-100"}
                                OnClick={() => AddEditProductVariant(false,false)}
                            >
                                {t("Edit")}
                            </Button>

                        </div>
                        <div className="col-50">

                            <Button
                                className={"btn-primary w-100"}
                                OnClick={() => AddEditProductVariant(false,true)}
                            >
                                {t("EditAndStay")}
                            </Button>

                        </div>

                    </div>
                }
                                
            </div>

        </Modal>
    )
}

export default AddEditVariant;