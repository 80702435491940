import arrows from '../Media/Images/Icons/arrows.webp';
import user from '../Media/Images/Icons/user.webp';
import logout from '../Media/Images/Icons/logout.webp';
import arrow from '../Media/Images/Icons/arrow.svg';
import logo from '../Media/Images/logo.png';
import logoSquare from '../Media/Images/logo_square.png';
import { NavLink } from 'react-router-dom';
import { UseAuth } from '../Modules/Auth/Library/UseAuth';
import UseHeader from '../GlobalHooks/UseHeader';

const Header = ({isShrink,SetIsShrink}) => {

    const {Logout} = UseAuth();
    const {
        headerData,
        links,
        selectedMenu,
        selectedParentID,
        SetSelectedParentID,
        ShowSubmenu,
        Shrink
    } = UseHeader(SetIsShrink);

    return(
        <>
            <header className = {(isShrink ? "shrink" : "")}>
                <img 
                    onClick={() => Shrink()}
                    className="arrows" 
                    src={arrows} 
                    alt="" 
                />
                <nav>
                    <ul>
                        <li>
                            <NavLink to={"/settings/change-password"}>
                                <img src={user} alt="" />
                            </NavLink>
                        </li>
                        <li onClick = {() => Logout()}>
                            <img src={logout} alt="" />
                        </li>
                    </ul>
                </nav>

            </header>
            <div className={"sidebar" + (isShrink ? " shrink" : "")}>
                <div className="logo">
                    {isShrink ?
                        <img src={logoSquare} />
                    :
                        <img src={logo} />
                    }
                </div>
                <nav>
                    <ul>
                        {links.map((item) => {

                            var className= "";
                            if(item.className) className = item.className;
                            if(selectedMenu === item.id)className += " selected";
                            if(selectedParentID === item.id)className += " active";

                            return(
                                <li key={item.id} className={className}>
                                    <NavLink className="main" to={(item.link ? item.link : "")} onClick={(e) => ShowSubmenu(e,item.id,item.link)}>
                                        {headerData && headerData.NotApprovedProductReviewsCount && item.isEshop ?
                                            <span className="badge">{headerData.NotApprovedProductReviewsCount}</span>
                                        :null}
                                        <img src={item.icon} />
                                        <span>{item.name}</span>
                                        {item.sublinks && item.sublinks.length > 0 ?
                                            <img className="arrow" src={arrow} />
                                        :null}
                                    </NavLink>
                                    {item.sublinks && item.sublinks.length > 0 ?
                                        <ul>
                                            {item.sublinks.map((it,ind) => (
                                                <li key={ind}>
                                                    {headerData && headerData.NotApprovedProductReviewsCount && it.isProductReview ?
                                                        <span className="badge">{headerData.NotApprovedProductReviewsCount}</span>
                                                    :null}
                                                    <NavLink to={it.link} onClick={() => SetSelectedParentID(null)}>
                                                        {it.name}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    :null}
                                </li>
                            )
                        })}
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Header;