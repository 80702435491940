import { useState, useContext, createContext, useEffect } from 'react';
import { NOTIFI_TIME_VISIBLE } from '../../../Config';

const notificationContext = createContext();

export const NotificationProvider = ({children}) => {

    const auth = UseProviderNotification();

    return(
        <notificationContext.Provider value={auth}>
            {children}
        </notificationContext.Provider>
    )
}

export const UseNotification = () => {
    return useContext(notificationContext);
}

const UseProviderNotification = () => {

    const [notifications,SetNotifications] = useState([]);

    const AddNotification = (text,isSuccess) => {

        var newNotifications = [...notifications];
        newNotifications.push({
            text,
            isSuccess
        });
        SetNotifications(newNotifications);

        setTimeout(() => {

            SetNotifications((prevValue) => {
                var newNotifications = [...prevValue];
                newNotifications.shift();

                return newNotifications;
            })

        },NOTIFI_TIME_VISIBLE)
    }

    return {
        notifications,
        AddNotification
    }

}