import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import Input from '../../../../GlobalComponents/Input';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditProductSearchTag from '../../Library/Products/UseAddEditProductSearchTag';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { useApolloClient } from '@apollo/client';

Modal.setAppElement('#root');

const AddEditProductSearchTag = ({lang,productID,selectedProductSearchTagID,SetSelectedProductSearchTagID}) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const {
        loading,
        error,
        formData,
        AddEditProductSearchTag,
        SetFormData,
    } = UseAddEditProductSearchTag(0,lang,productID,async (d) => {

        await client.refetchQueries({
            include: ["AllProductSearchTags"],
        });

        SetSelectedProductSearchTagID(null);
    });

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedProductSearchTagID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedProductSearchTagID === 0 ? t("AddProductSearchTag") : t("EditProductSearchTag"))}
                OnClose ={() => SetSelectedProductSearchTagID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading ?
                            <Loading />
                        : 
                            <>
                                <div className="row">
                                    <div className={"col-100"}>      
                                        <div className="form-group">
                                            <label>*{t("ProductSearchTagName")}</label>
                                            <Input
                                                name="name"
                                                value={formData.name}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                </div> 
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditProductSearchTag()}
                >
                    {(selectedProductSearchTagID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditProductSearchTag;