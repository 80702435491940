import { useMutation } from "@apollo/client";
import { COPY_LINK } from "../Queries/link";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseCopyLink = (OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [CoLink] = useMutation(COPY_LINK,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d); 

            const notifyText = t("NowIHaveTwin");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    
    const CopyLink = (linkID) => {

        CoLink({
            variables:{
                linkID
            }
        })
        
    } 

    return {
        CopyLink
    }

}

export default UseCopyLink;