import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import Input from '../../../../GlobalComponents/Input';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditManufacturer from '../../Library/Manufacturers/UseAddEditManufacturer';
import UseGetManufacturer from '../../Library/Manufacturers/UseGetManufacturer';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';

Modal.setAppElement('#root');

const AddEditManufacturer = ({selectedManufacturerID,SetSelectedManufacturerID,OnAddEditSuccess}) => {

    const {user} = UseAuth();
    const {t} = useTranslation();
    const {
        loading,
        error,
        formData,
        AddEditManufacturer,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
    } = UseAddEditManufacturer(selectedManufacturerID,user.lang,(d) => OnAddEditSuccess(d));

    const {
        allLanguageMutations, 
        loading:getLoading,
        error:getError
    } = UseGetManufacturer(selectedManufacturerID,user.lang,(d) => {
        InitFormData(d)
    });

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedManufacturerID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedManufacturerID === 0 ? t("AddManufacturer") : t("EditManufacturer"))}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedManufacturerID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                
                                <div className="row">
                                    <div className={"col-50"}>
                                                
                                        <div className="form-group">
                                            <label>*{t("ManufacturerName")}</label>
                                            <Input
                                                name="name"
                                                value={formData.name}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                                                        
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>*{t("Shortcut")}</label>
                                            <Input
                                                name="shortcut"
                                                value={formData.shortcut}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-100">
                                        <div className="form-group">
                                            <label>{t("DeliveryTimeToCustomer")} ({formData.selectedLang})</label>
                                            <Input
                                                name="deliveryTimeToCustomer"
                                                value={GetFormLangValue("deliveryTimeToCustomer")}
                                                OnChange={(e) => SetFormLangData(e)}
                                            />
                                        </div>
                                    </div>
                                </div> 
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditManufacturer()}
                >
                    {(selectedManufacturerID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditManufacturer;