import { useState } from 'react';
import Input from '../../../../GlobalComponents/Input';
import Select from '../../../../GlobalComponents/Select';
import InputCard from '../../../../GlobalComponents/InputCard';
import Button from '../../../../GlobalComponents/Button';
import { TINY_MCE_TOOLBAR_BASIC } from '../../../../Config';
import TinyMCEEditor from '../../../TinyMCEEditor/Components/TinyMCEEditor';
import { useTranslation } from 'react-i18next';
import manufacturersIcon from '../../../../Media/Images/Icons/factory.webp';
import List from '../../../../GlobalComponents/List/List';
import UseEditManufacturers from '../../Library/Covers/UseEditManufacturers';
import ChooseManufacturers from './ChooseManufacturers';
import cancelIcon from '../../../../Media/Images/Icons/cancel.webp';

const BasicInformations = ({
    formData,
    allCoverMaterialTypes,
    SetFormLangData,
    GetFormLangValue,
    SetFormData,
    FillFormSelectedItemsWithObj,
    SetFormDataSelectedItemsWithObj,
    RemoveFormDataSelectedItems
}) => {

    const {t} = useTranslation();
    const [showAddManufacturer, SetShowAddManufacturer] = useState(false);
    const {headerData,content} = UseEditManufacturers(formData.selectedManufacturers,SetFormDataSelectedItemsWithObj);

    return(
        <>
            <div className="row">
                <div className={"col-50"}>
                            
                    <div className="form-group">
                        <label>*{t("CoverName")} ({formData.selectedLang})</label>
                        <Input
                            name="name"
                            value={GetFormLangValue("name")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                </div> 

                <div className={"col-50"}>
                            
                    <div className="form-group">
                        <label>{t("CoverPrivateName")}</label>
                        <Input
                            name="privateName"
                            value={formData.privateName}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </div> 
                <div className={"col-50"}>
                            
                    <div className="form-group">
                        <label>*{t("Shortcut")}</label>
                        <Input
                            name="shortcut"
                            value={formData.shortcut}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </div>

                <div className="col-50">
                    <div className="form-group">
                        <label>{t("CoverMaterialType")}</label>
                        <Select
                            name = {"coverMaterialTypeID"}
                            OnChange = {(e) => SetFormData(e)}
                            value = {formData.coverMaterialTypeID}
                        >
                            <option value={0}>{t("None")}</option>
                            {allCoverMaterialTypes && allCoverMaterialTypes.map((item) => (
                                <option 
                                    key={item.coverMaterialTypeID} 
                                    value={item.coverMaterialTypeID}
                                >{item.name}</option>
                            ))}
                            
                        </Select>
                    </div>
                </div>

                <div className="col-100">

                    <div className="form-group">
                        <InputCard
                            title = {"*" + t("Manufacturers")}
                            icon = {manufacturersIcon}
                            noBodyPadding = {true}
                            headerChildren = {
                                <Button 
                                    OnClick = {() => SetShowAddManufacturer(true)}
                                    className="btn-primary narrow"
                                >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                            }
                        >   
                            <List 
                                headerData = {headerData} 
                                headerClassName = {"no-top-border"}
                                contentData = {content}
                                isSortable = {false}
                                loading = {false}
                                replaceDotsImg = {cancelIcon}
                                ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedManufacturers","manufacturerID",[data.rowID])}
                            />
                        
                        </InputCard>
                    </div>
                </div>

                <div className="col-100">
                    
                    <label>{t("Description")} ({formData.selectedLang})</label>
                    <TinyMCEEditor 
                        value = {GetFormLangValue("description")}
                        OnEditorChange={(content) => SetFormLangData("description",content)}
                        toolbar = {TINY_MCE_TOOLBAR_BASIC}
                    />
                    
                </div>
            
            </div>
            {showAddManufacturer ? 
                <ChooseManufacturers 
                    formData = {formData}
                    SetShowAddManufacturer = {SetShowAddManufacturer}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>

    )
}

export default BasicInformations;