import { useState } from "react";
import { DELETE_COVERS } from "../../Queries/covers";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";

const UseDeleteCovers = (selectedItems,OnCompleted) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [DelCovers] = useMutation(DELETE_COVERS,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            if(OnCompleted)
                OnCompleted(d.DeleteCovers);
        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedRows"),false);
        }
    }

    const DeleteCovers = (action) => {

        if(action)
        {              
            DelCovers({
                variables:{
                    coverIDs:selectedItems.join(",")
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    return{
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteCovers
    }
}

export default UseDeleteCovers;