import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import UseGetAllBanners from "../Library/UseGetAllBanners";
import { useState } from "react";
import AddEditBanner from "./AddEditBanner";
import { UseAuth } from '../../Auth/Library/UseAuth';
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { useApolloClient } from "@apollo/client";
import UseDeleteBanners from "../Library/UseDeleteBanners";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import ContentManager from "../../ContentManager/Components/ContentManager";

const Banners = () => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedBannerID, SetSelectedBannerID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedBannerIDs,
        SelectRow,
        SelectAll
    } = UseGetAllBanners(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteBanners
    } = UseDeleteBanners(selectedBannerIDs,(d) => ReFetchBanners(d));

    const ReFetchBanners = async(d) => {
        await client.refetchQueries({
            include: ["AllBanners"],
        });
    }
    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("BannerList")}
                        OnAddButtonClick={() => SetSelectedBannerID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (bannerID) => SetSelectedBannerID(bannerID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedBannerID || selectedBannerID == 0 ?
                <AddEditBanner
                    selectedBannerID = {selectedBannerID}
                    SetSelectedBannerID = {SetSelectedBannerID}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteBanners")} 
                    CallBack={DeleteBanners} 
                />
            :null}

            <ContentManager />
        </>
    )

}

export default Banners;