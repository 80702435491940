import Button from "../Button";
import Select from "../Select";

const ModalHeader = ({children,title,allLanguages,OnClose,selectedLang,OnLangSelect}) => {

    return(
        <div className="modal-header">

            <h3>{title}</h3>

            <div className="modal-header-controls">
                {children}
                {allLanguages && allLanguages.length > 0 ? 
                    <Select 
                        name="selectedLang"
                        value={selectedLang}
                        OnChange={(e) => OnLangSelect(e)}
                    >
                        {allLanguages.map((item) => (
                            <option key={item.languageID} value = {item.suffix}>{item.suffix}</option>
                        ))}
                    </Select>
                :null}
                <Button 
                    className={"btn-brown"}
                    OnClick={() => OnClose()}
                >X</Button>
            </div>
        </div>
    )

}

export default ModalHeader;