import gql from 'graphql-tag';

export const ADD_EDIT_PRODUCT = gql`
    mutation AddEditProduct(
        $productID:ID,
        $showOnEshop:Int,
        $availableForCart:Int,
        $hideCovers:Int,
        $hideCorpus:Int,
        $dontOverwritePriceWithVariant:Int
        $manufacturerID:ID,
        $vatID: ID,
        $hDeliveryTime:String,
        $hManufacturer:String,
        $langs:[ProductLangsInput],
        $productPhotos:[ProductPhotoInput]
        $selectedLinks:[ProductLinkInput],
        $selectedTickLabels:[ProductTickLabelInput],
        $selectedParameters:[ProductParametersInput],
        $productDimensionType:ProductDimensionTypeInput,
        $selectedLabels:[ProductLabelsInput]
        $selectedExcludedCovers:[ID]
        $selectedCoversPercentage:[ProductCoverPercentageInput]
        
    ){
        AddEditProduct(
            productID:$productID,
            showOnEshop:$showOnEshop,
            availableForCart:$availableForCart,
            hideCovers:$hideCovers,
            hideCorpus:$hideCorpus,
            dontOverwritePriceWithVariant:$dontOverwritePriceWithVariant,
            manufacturerID: $manufacturerID,
            vatID: $vatID,
            hDeliveryTime: $hDeliveryTime, 
            hManufacturer: $hManufacturer, 
            langs:$langs,
            productPhotos:$productPhotos,
            selectedLinks:$selectedLinks,
            selectedTickLabels:$selectedTickLabels,
            selectedParameters:$selectedParameters,
            productDimensionType:$productDimensionType,
            selectedLabels:$selectedLabels,
            selectedExcludedCovers:$selectedExcludedCovers,
            selectedCoversPercentage:$selectedCoversPercentage
        ){
            productID
            name
        }
    }
`;

export const GET_PRODUCT = gql`
    query ProductWithLangs($productID: ID,$lang: String){
        ProductWithLangs(productID:$productID){
            productID
            manufacturerID
            productDimensionTypeID
            vatID
            hDeliveryTime
            hManufacturer
            showOnEshop
            availableForCart
            hideCovers
            hideCorpus
            dontOverwritePriceWithVariant
            productPhotos{
                productPhotoID
                name
                isMain
                priority
            }
            links{
                linkID
                isMain
                link(lang:$lang){
                    name
                }
            }
            tickLabels{
                tickLabelID
                tickLabel(lang:$lang){
                    name
                }
            }
            labels{
                labelID
                useDates
                dateFrom
                dateTo
                label(lang:$lang){
                    name
                }
            }
            langs{
                lang
                name
                price
                commonPrice
                discount
                isDiscountPercentage
                description
                seoUrl
                metaTitle
                metaDescription 
                hName
                hNameWithParameters 
                hCategory
                hDescription
            }   
            productDimensionType{
                productDimensionTypeID
                photo   
                name 
            } 
            productDimensionTypeSizes{
                productDimensionTypeID
                productDimensionTypeSizePositionID
                value
                productDimensionTypeSizePosition{
                    x
                    y
                }
            }
            parameters{
                productID
                parameterID
                parameterValueID
                hideInProductDetail
                forFeeds
                value
                parameter(lang:$lang){
                    isColor
                    name
                }
            }
            excludedCovers{
                coverID
            }
            coverPercentages{
                coverID
                percentage
            }
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllManufacturers(lang:$lang,limit:1000000,offset:0){
            manufacturerID
            name
            shortcut
        }
        WholeParameterLangValues(lang:$lang){
            parameterValueID
            parameterID
            value
            colorCode
        }
        AllVats{
            vatID
            value
        }
    }
`;

export const GET_PRODUCTS = gql`
    query AllProducts($lang: String,$limit:Int,$offset:Int,$searchText:String,$linkID:ID){
        AllProducts(lang:$lang,limit:$limit,offset:$offset,searchText:$searchText,linkID:$linkID){
            productID
            name
            mainPhoto
            inLinkPriority
            links{
                linkID
                link(lang:$lang){
                    name
                }
            }
        }
        AllProductsCount(lang:$lang,searchText:$searchText,linkID:$linkID)
        AllLinks(lang:$lang,isEshopCategory:1){
            linkID
            name
            subLink{
                linkID
                name
                subLink{
                    linkID
                    name
                    subLink{
                        linkID
                        name
                        subLink{
                            linkID
                            name
                            subLink{
                                linkID
                                name
                                subLink{
                                    linkID
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DELETE_PRODUCTS = gql`
    mutation DeleteProducts($productIDs:ID) {
        DeleteProducts(productIDs:$productIDs)
    }
`;

export const UPLOAD_PRODUCT_PHOTOS = gql`
    mutation UploadProductPhotos(
        $files:[Upload],
        $productID: ID
    ){
        UploadProductPhotos(
            files:$files,
            productID:$productID
        ){
            productPhotoID
            name
            priority
        }
    }
`;

export const DELETE_PRODUCT_PHOTOS = gql`
    mutation DeleteProductPhotos($productPhotoIDs:[ID]){
        DeleteProductPhotos(productPhotoIDs:$productPhotoIDs)
    }
`;

export const GET_DIMENSION_TYPES = gql`
    query AllProductDimensionTypes{
        AllProductDimensionTypes{
            productDimensionTypeID
            name
            shorcut
            photo
            sizePositions{
                productDimensionTypeSizePositionID
                x
                y
            }
        }
    }
`;

export const GET_PRODUCT_SEARCH_TAGS = gql`
    query AllProductSearchTags($productID:ID,$lang:String){
        AllProductSearchTags(productID:$productID,lang:$lang){
            productSearchTagID
            name
        }
    }
`;


export const ADD_EDIT_PRODUCT_SEACRH_TAG = gql`
    mutation AddEditProductSearchTag(
        $productID:ID,
        $lang:String,
        $name:String  
    ){
        AddEditProductSearchTag(
            productID:$productID,
            lang:$lang,
            name:$name,
        ){
            productSearchTagID
            name
        }
    }
`;

export const DELETE_PRODUCT_SEARCH_TAGS = gql`
    mutation DeleteProductSearchTags(
        $productSearchTagIDs:ID,
        $productID:ID,
        $lang:String
    ) {
        DeleteProductSearchTags(
            productSearchTagIDs:$productSearchTagIDs,
            productID:$productID,
            lang:$lang
        )
    }
`;

export const GET_PRODUCT_COVERS = gql`
    query AllProductCovers($productID:ID,$lang:String){
        AllProductCovers(productID:$productID,lang:$lang){
            coverID
            manufacturerID
            name
            shortcut
        }
    }
`;

export const GET_COVER_PRODUCTS = gql`
    query AllCoverProducts($coverID:ID,$lang:String){
        AllCoverProducts(coverID:$coverID,lang:$lang){
            productID
            manufacturerID
            name
            manufacturer{
                name
            }
        }
    }
`;

export const GET_PRODUCT_VARIANTS = gql`
    query AllProductVariants($productID:ID,$lang:String){
        AllProductVariants(productID:$productID,lang:$lang){
            productVariantID
            productCode
            name
            internalNote
            photo
        }
    }
`;

export const ADD_EDIT_PRODUCT_REVIEW = gql`
    mutation AddEditProductReview(
        $productReviewID:ID,
        $approved:Int,    
        $pinned:Int,    
        $rating:Int, 
        $name:String,
        $city:String,
        $email:String,
        $description:String
    ){
        AddEditProductReview(
            productReviewID:$productReviewID,
            approved:$approved,
            pinned:$pinned,
            rating:$rating,
            name:$name,
            city:$city,
            email:$email,
            description:$description,
        ){
            productReviewID
            rating
            pinned
            email
            description
            approved
        }
    }
`;

export const GET_PRODUCT_REVIEWS = gql`
    query AllProductReviews($limit:Int,$offset:Int){
        AllProductReviews(limit:$limit,offset:$offset){
            productReviewID
            rating
            pinned
            email
            description
            approved
        }
        AllProductReviewsCount   
    }
`;

export const GET_PRODUCT_REVIEW = gql`
    query ProductReview($productReviewID:ID){
        ProductReview(productReviewID:$productReviewID){
            productReviewID
            productID
            rating
            pinned
            email
            name
            city
            description
            approved
            cons{
                value
            }
            pros{
                value
            }
            photos{
                productReviewPhotoID
                name
            }
            product(lang:"cz"){
                productID
                name
                niceName
                seoUrl
                lang
                firstVariant{
                    productVariantID
                    productCode
                    photo
                }
            }
        }  
    }
`;

export const DELETE_PRODUCT_VARIANTS = gql`
    mutation DeleteProductVariants(
        $productVariantIDs:ID,
        $productID:ID
    ) {
        DeleteProductVariants(
            productVariantIDs:$productVariantIDs,
            productID:$productID
        )
    }
`;

export const ADD_EDIT_PRODUCT_VARIANT = gql`
    mutation AddEditProductVariant(
        $productVariantID:ID,
        $productID:ID,
        $showOnEshop:Int,
        $productCode:String,
        $productSideID:ID,
        $photoForSide:Int,
        $photo:Upload
        $quantityInStock:Int,
        $quantityAtManufacturer:Int,
        $quantityInStockRightSide:Int,
        $quantityAtManufacturerRightSide:Int,
        $presetCovers:Int,
        $showFirstCover:Int,
        $showSecondCover:Int,
        $firstCoverTitleID:ID,
        $secondCoverTitleID:ID,
        $firstCoverID:ID,
        $secondCoverID:ID,
        $firstCoverPhotoID:ID,
        $secondCoverPhotoID:ID,
        $firstParameterValueID:ID,
        $secondParameterValueID:ID,
        $selectedLabels:[ProductVariantLabelsInput]
        $langs:[ProductVariantLangsInput] 
        $selectedExcludedCovers:[ID]
    ){
        AddEditProductVariant(
            productVariantID:$productVariantID,
            productID:$productID,
            showOnEshop:$showOnEshop,
            productCode:$productCode,
            productSideID:$productSideID,
            photoForSide:$photoForSide,
            photo:$photo,
            quantityInStock:$quantityInStock,
            quantityAtManufacturer:$quantityAtManufacturer,
            quantityInStockRightSide:$quantityInStockRightSide,
            quantityAtManufacturerRightSide:$quantityAtManufacturerRightSide,
            presetCovers:$presetCovers,
            showFirstCover:$showFirstCover,
            showSecondCover:$showSecondCover,
            firstCoverTitleID:$firstCoverTitleID,
            secondCoverTitleID:$secondCoverTitleID,
            firstCoverID:$firstCoverID,
            secondCoverID:$secondCoverID,
            firstCoverPhotoID:$firstCoverPhotoID,
            secondCoverPhotoID:$secondCoverPhotoID,
            firstParameterValueID:$firstParameterValueID,
            secondParameterValueID:$secondParameterValueID,
            selectedLabels:$selectedLabels,
            langs:$langs
            selectedExcludedCovers:$selectedExcludedCovers
        ){
            productVariantID
            name
            internalNote
            productCode
        }
    }
`;

export const GET_PRODUCT_VARIANT = gql`
query ProductVariantWithLangs($productVariantID: ID,$lang:String,$productID:ID){
    ProductVariantWithLangs(productVariantID:$productVariantID){
        productVariantID
        productSideID
        showOnEshop
        productCode
        photo
        photoForSide
        quantityInStock
        quantityAtManufacturer
        quantityInStockRightSide
        quantityAtManufacturerRightSide
        presetCovers
        showFirstCover
        showSecondCover
        firstCoverTitleID
        secondCoverTitleID
        firstCoverID
        secondCoverID
        firstCoverPhotoID
        secondCoverPhotoID
        firstParameterValueID
        secondParameterValueID
        labels{
            labelID
            useDates
            dateFrom
            dateTo
            label(lang:$lang){
                name
            }
        }
        langs{
            lang
            name
            internalNote
            price
            commonPrice
            discount
            isDiscountPercentage
        }
        excludedCovers{
            coverID
        }
    }
    AllLanguageMutations(onlyEnabled:true){
        languageID
        title
        suffix
        generateNiceTitle
        langTitle
        decimal
        priority
        main
        currencyTitle
    }
    AllProductSides{
        productSideID
        name
    }
    AllCoverTitles(lang:$lang){
        coverTitleID
        name
    }
    AllProductCovers(productID:$productID,lang:$lang){
        coverID
        name
    }
    ProductParametersForWeb(productID:$productID){
        parameterID
        parameterValueID
        parameter(lang:$lang){
            isColor
        }
        parameterValue(lang:$lang){
            value
            colorCode
        }
        
    }
}
`;

export const UPDATE_PRODUCT_VARIANT_PRIORITY = gql`
    mutation UpdateProductVariantPriority($productVariantID:ID,$productID:ID,$fromIndex:Int,$toIndex:Int) {
        UpdateProductVariantPriority(productVariantID:$productVariantID,productID:$productID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

export const SET_PRODUCT_NEW_LINK_PRIORITY = gql`
    mutation SetProductNewLinkPriority($productID:ID,$linkID:ID,$newPriority:Int) {
        SetProductNewLinkPriority(productID:$productID,linkID:$linkID,newPriority:$newPriority)
    }
`;

export const ADD_MULTIPLE_LINK_PRODUCTS = gql`
    mutation AddMultipleLinkProducts($productIDs:[ID],$linkID:ID) {
        AddMultipleLinkProducts(productIDs:$productIDs,linkID:$linkID)
    }
`;