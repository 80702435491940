import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import cz from './Dictionary/cz.json';
import en from './Dictionary/en.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      cz,en
    },
    lng: "cz", // if you're using a language detector, do not define the lng option
    fallbackLng: "cz",
    interpolation: {
      escapeValue: false
    }
});