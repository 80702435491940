import { Fragment } from "react";
import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import Columns from "./Columns";
import Text from "./Text";
import Headline from "./Headline";
import Image from "./Image";
import ImageGallery from "./ImageGallery";
import Video from "./Video";
import RecommendedProducts from "./RecommendedProducts";
import TopCategories from './TopCategories';
import Banner from "./Banner";
import ButtonElement from './Button';
import NewestArticles from "./NewestArticles";
import Alert from "./Alert";
import Html from "./Html";
import Retino from "./Retino";
import Form from "./Form";
import { GetColumnStyles } from "../../Library/functions";

const Column = ({data,lastIndexes,index,columnsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();

    var className = "cm-column-elements";
    if(data.className) className += " " + data.className;

    if(data.contentAlign != "start")
        className += " cm-column-elements cm-d-flex cm-flex-column cm-justify-content-" + data.contentAlign;
    
    if(data.hasBorder == "1")
        className += " cm-border";

    if(data.backgroundColorClass)
        className += " cm-bg-color-" + data.backgroundColorClass;

    var stylesData = GetColumnStyles(data);
    className += " " + stylesData.styleClassName;

    var showMenu = false;
    if(indexToShowMenu === lastIndexes + "-" + index)
        showMenu = true;

    return(
        <>
            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "column"
                    index = {lastIndexes + "-" + index} 
                    showAddContent = {true} 
                    position = {index + 1}
                    elementsCount = {columnsCount}
                />
            :null}
            <div className={className} style={stylesData.styles}>

                {data.elements && data.elements.map((elmItem,elmIndex) => {
                    return(
                        <Fragment key = {elmIndex}>
                            {elmItem.columns &&
                                <Columns 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.columns} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.text &&
                                <Text 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.text} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.headline &&
                                <Headline 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.headline} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.image &&
                                <Image 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.image} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.imageGallery &&
                                <ImageGallery 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.imageGallery} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.video &&
                                <Video 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.video} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.recommendedProducts &&
                                <RecommendedProducts 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.recommendedProducts} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.topCategories &&
                                <TopCategories 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.topCategories} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.banner &&
                                <Banner 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.banner} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.button &&
                                <ButtonElement 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.button} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.newestArticles &&
                                <NewestArticles 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.newestArticles} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.alert &&
                                <Alert 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.alert} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.html &&
                                <Html 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.html} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.retino &&
                                <Retino 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.retino} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }
                            {elmItem.form &&
                                <Form 
                                    elmIndex = {elmIndex} 
                                    elementsCount = {data.elements.length}
                                    data = {elmItem.form} 
                                    lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} 
                                />
                            }

                        </Fragment>
                    )
                })}
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border col-border" + (showMenu ? " cm-selected" : "")} 
                    onClick={(e) => SelectElement(e,lastIndexes + "-" + index)} 
                ></div>
            :null}
        </>       
    )
}

export default Column;