const RadioButton = ({text,id,name,value,checked,OnChange}) => {

    return(
        <div className="radio">
            <input id={id} type="radio" checked={checked} value={value} name={name} onChange = {(e) => OnChange(e)} />
            <label htmlFor={id}>{text}</label>
        </div>
    )
}

export default RadioButton;