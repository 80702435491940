import { useMutation } from "@apollo/client";
import { ADD_EDIT_PARAMETER } from "../../Queries/parameters";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditParameter = (selectedParameterID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue,OpenLangImage} = UseFormHandle({
        selectedLang:lang,
        isColor:0,
        filterOperationTypeID:1,
        langs:[],
        values:[]
    });

    const [AddEditParameterMutation,{error,loading}] = useMutation(ADD_EDIT_PARAMETER,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditParameter);

            const notifyText = (selectedParameterID != 0 ? t("SuccessfullyUpdated") : t("NewParameterIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditParameter = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(val.name === "" && val.lang === lang)
                check = false;
        }

        if(check)
        {
            let langs = [];
            for(const lang of formData.langs)
            {
                langs.push({
                    name:               lang.name,
                    unit:               lang.unit,               
                    lang:               lang.lang
                });
            }
            AddEditParameterMutation({
                variables:{
                    parameterID:            selectedParameterID,
                    isColor:                formData.isColor,
                    filterOperationTypeID:  formData.filterOperationTypeID,
                    langs:                  langs,
                    values:                 formData.values
                }
            })
        }
        else
        {
            AddNotification(t("NoNameNoGo"),false); 
        }
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const SetValue = (value,colorCode,index) => {

        var newArr = [...formData.values];
        newArr[index] = {...newArr[index],colorCode};

        for(let l = 0; l < newArr[index].langs.length; l++)
        {
            if(newArr[index].langs[l].lang == formData.selectedLang)
            {
                const newLangs = [...newArr[index].langs];
                newLangs[l] = {...newLangs[l],value};

                newArr[index] = {...newArr[index],langs:newLangs};

                break;
            }
        }

        SetFormData("values",newArr);
    }

    const AddValue = (allLanguageMutations) => {

        var newArr = [...formData.values];
        var langs  = [];

        for(let val of allLanguageMutations)
        {
            langs.push({
                lang:val.suffix,
                value:""
            })
        }

        const post = {
            parameterValueID:0,
            isDeleted:false,
            colorCode:"",
            langs:langs
        }

        newArr.push(post);

        SetFormData("values",newArr);
    }

    const RemoveValue = (index) => {

        var newArr = [...formData.values];
        
        if(newArr[index].parameterValueID == 0)
            newArr.splice(index,1);
        else
            newArr[index] = {...newArr[index],isDeleted:true}          

        SetFormData("values",newArr);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditParameter,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        OpenLangImage,
        AddValue,
        SetValue,
        RemoveValue
    }

}

export default UseAddEditParameter;