import gql from 'graphql-tag';

export const ADD_EDIT_PARAMETER_TEMPLATE = gql`
    mutation AddEditParameterTemplate(
        $parameterTemplateID:ID,
        $name: String
        $parameters:[ID]
    ){
        AddEditParameterTemplate(
            parameterTemplateID:$parameterTemplateID,
            name:$name,
            parameters:$parameters
        ){
            parameterTemplateID
            name
        }
    }
`;

export const GET_PARAMETER_TEMPLATE = gql`
    query ParameterTemplate($parameterTemplateID: ID,$lang:String){
        ParameterTemplate(parameterTemplateID:$parameterTemplateID){
            parameterTemplateID
            name
            parameters{
                parameterID
                parameter(lang:$lang){
                    name
                }
            }
        }
    }
`;

export const GET_PARAMETER_TEMPLATES = gql`
    query AllParameterTemplates($limit:Int,$offset:Int,$lang:String){
        AllParameterTemplates(limit:$limit,offset:$offset){
            parameterTemplateID
            name
            parameters{
                parameterID
                parameter(lang:$lang){
                    name
                    isColor
                    parameterID
                    lang
                    values{
                        colorCode
                        parameterValueID
                        value
                    }
                }
            }
        }
        AllParameterTemplatesCount
    }
`;

export const DELETE_PARAMETER_TEMPLATES = gql`
    mutation DeleteParameterTemplates($parameterTemplateIDs:ID) {
        DeleteParameterTemplates(parameterTemplateIDs:$parameterTemplateIDs)
    }
`;