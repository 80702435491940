
const Input = ({
    type,
    name,
    preppend,
    append,
    placeholder,
    value,
    ariaLabel,
    autocomplete,
    disabled,
    style,
    OnChange
}) => {

    const Change = (e) => {
        if(OnChange)
            OnChange(e);
    }
    
    var className = ""
    if(preppend) className += " with-preppend";
    if(append) className += " with-append";

    return(
        <div className="input-group" style={style}>
            {preppend ?
                <div className="preppend">
                    {preppend}
                </div>
            :null}

            <input 
                name = {name} 
                type = {type} 
                value = {value} 
                placeholder = {placeholder}
                onChange = {(e) => Change(e)} 
                aria-label = {ariaLabel}
                className={className}
                autoComplete = {autocomplete}
                disabled = {disabled}
            />
            
            {append ?
                <div className="append">
                    {append}
                </div>
            :null}
        </div>
    )

}

export default Input;