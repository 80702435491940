import Input from "../../../GlobalComponents/Input";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import UseChangePassword from "../Library/UseChangePassword";

const ChangePassword = () => {

    const {t} = useTranslation();
    const {formData,ChangePassword,SetFormData} = UseChangePassword();

    return(
        <>
            <ContentHeader
                title = {t("PasswordChange")}
                OnSaveButtonClick = {() => ChangePassword()}
            />
            <ContentBody>
                <form>
                    <div className="row">
                                                                                            
                        <div className="col-100">
                            <label className="input-label">{t("OldPassword")}</label>
                            <div className="form-group">
                                <Input 
                                    className="form-control" 
                                    type="password" 
                                    name="oldPassword"
                                    autocomplete="current-password"
                                    OnChange={(e) => SetFormData(e)}
                                    value={formData.oldPassword}
                                />
                                
                            </div>
                        </div>
                        <div className="col-50">
                            <label className="input-label">{t("NewPassword")}</label>
                            <div className="form-group">
                                <Input 
                                    className="form-control" 
                                    type="password" 
                                    name="newPassword"
                                    autocomplete="new-password"
                                    OnChange={(e) => SetFormData(e)}
                                    value={formData.newPassword}
                                />
                            </div>
                        </div>
                        <div className="col-50">
                            <label className="input-label">{t("RepeatNewPassword")}</label>
                            <div className="form-group">
                                <Input 
                                    className="form-control" 
                                    type="password" 
                                    name="checkNewPassword"
                                    autocomplete="new-password"
                                    OnChange={(e) => SetFormData(e)}
                                    value={formData.checkNewPassword}
                                />
                            </div>
                        </div>   
                    </div>
                </form>
            </ContentBody>
        </>
    )
}

export default ChangePassword;