import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import { GET_COVER } from "../../Queries/covers";

const UseGetCover = (selectedCoverID,lang,CallBack) => {

    const {t} = useTranslation();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allCoverMaterialTypes, SetAllCoverMaterialTypes] = useState([]);

    const {loading,error} = useQuery(GET_COVER,{
        variables:{
            coverID:selectedCoverID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            console.log(d);

            const emptyLang = {
                name:"",                    
                description:""
            }

            if(selectedCoverID != 0)
            { 
                let langs = CompleteLangsArray(d.CoverWithLangs.langs,d.AllLanguageMutations,emptyLang);
                CallBack({
                    selectedLang:               lang, 
                    coverMaterialTypeID:        d.CoverWithLangs.coverMaterialTypeID,
                    privateName:                d.CoverWithLangs.privateName,
                    shortcut:                   d.CoverWithLangs.shortcut,
                    langs:                      langs,
                    selectedManufacturers:      GetManufacturers(d.CoverWithLangs.manufacturers),
                    coverPhotos:                GetPhotos(d.CoverWithLangs.coverPhotos),
                    selectedExcludedProducts:   GetExcludedProducts(d.CoverWithLangs.excludedProducts)
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllCoverMaterialTypes(d.AllCoverMaterialTypes);
            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    const GetPhotos = (coverPhotos) => {
        var newCoverPhotos = [];
        for(let val of coverPhotos)
        {
            newCoverPhotos.push({
                coverPhotoID: val.coverPhotoID,
                name: val.name,
                description: val.description,
                number: val.number,
                isMain: val.isMain,
                isUpdated:false
            });
        }

        return newCoverPhotos;
    }

    const GetExcludedProducts = (products) => {

        var newProducts = [];
        for(let val of products)
        {
            newProducts.push(val.productID);
        }

        return newProducts;
    }

    const GetManufacturers = (manufacturers) => {
        var selectedManufacturers = [];
        for(let val of manufacturers)
        {
            selectedManufacturers.push({
                manufacturerID: val.manufacturerID,
                percentage: val.percentage,
                name: val.manufacturer.name
            });
        }

        return selectedManufacturers;
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allCoverMaterialTypes
    }

}

export default UseGetCover;